<template>
  <div class="signin-view">
    <el-card v-if="!isLoggedIn" class="signin-form">
      <h1>Sign In</h1>
      <el-form ref="signinForm" label-position="top" :model="signinForm">
        <el-form-item
          prop="email"
          label="Email"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="signinForm.email" @keyup.enter.native="submit('signinForm')" :disabled="((vesselId != '' && vesselId != null )&& (inviteId != '' && inviteId != null )&& (inviteEmail != '' && inviteEmail != null))" />
        </el-form-item>
        <el-form-item
          prop="password"
          label="Password"
          :rules="[
            { required: true, message: 'Please input password', trigger: 'blur' },
            { min: 8, max: 32, message: 'Lenght should be 8 to 32', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="signinForm.password" type="password" @keyup.enter.native="submit('signinForm')" />
        </el-form-item>
        <el-divider />
        <el-form-item>
          <el-button type="primary" @click="submit('signinForm')">
            Sign In
          </el-button>
        </el-form-item>
        <div :style="{ textAlign: 'center' }">Don't have an account? <el-button type="text" @click="gotoSignUpPage">Sign Up</el-button></div>
        <div :style="{ textAlign: 'center' }"><el-button type="text" @click="$router.push('/reset-password')">Forgot Password</el-button></div>
      </el-form>
    </el-card>
    
    <div v-else>
      <el-button @click="goToAppPage">Go to Application</el-button>
      <el-button @click="logout">Sign out</el-button>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {auth} from 'firebase'
import firebaseApp from '../firebase/app'

export default {
  data() {
    return {
      signinForm: {
        email: '',
        password: ''
      },
      isLoggedIn: false,
      user: null ,
      vesselId: '',
      inviteId: '',
      inviteEmail: '',
      errorMessage: ''
      // processingInvite: false     
    }
  },
  beforeMount() {
    // this.processingInvite = false
    if(this.$route.query != null){
      console.log("Trying to read query")
      this.vesselId = this.$route.query.vesselId
      this.inviteId = this.$route.query.inviteId
      this.inviteEmail = this.$route.query.email
    }
  },
  mounted(){
    if (this.vesselId && this.inviteId && this.inviteEmail) {
      // this.processingInvite = true
      this.signinForm = {
        email: this.inviteEmail,
        password: ''
      }
    }
    firebaseApp.auth().onAuthStateChanged(user => {
      console.log("User: ", user)
      if (user) {
        this.isLoggedIn = true
        this.user = user;
        this.goToAppPage();
      } else {
        this.isLoggedIn = false
        this.user = null
        
      }
    });
  },
  methods: {
    isProcessingInvite:() => (this.vesselId && this.inviteId && this.inviteEmail),
    submit (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { email, password } =  this.signinForm

          await firebaseApp.auth().signInWithEmailAndPassword(email, password)
            .then(async result => {
              
              const userId = result.user.uid
              console.log('logged in successfully >>>>', result)
              const userRef = firebaseApp.database().ref(`/users/${userId}`)
              
              await userRef.once('value').then(async res => {
                const data = res.val()
                console.log('naviate to app >>>>', data)
                this.$store.commit('setUser', result.user)
                if (this.vesselId && this.inviteId && this.inviteEmail) {
                  console.log("Processing invite...")
                  await this.$router.push({
                    path: '/acceptInvite',
                    query: {
                      vesselId: this.vesselId,
                      inviteId: this.inviteId,
                      email: this.inviteEmail
                    }
                  }).catch(error => {
                    console.log(error)
                  })
                }
                else {
                  console.log("Also not processing invite...")
                  if (data.vessels && Object.keys(data.vessels).length > 0) {
                    this.$router.push(`/app/${Object.keys(data.vessels)[0]}`)
                  } else {
                    this.$notify.error({
                      message: 'You have no vessels associated with your account. If you think this message is an error, contact customer support.'
                    })
                    this.$router.push('/')
                  }
                }
              })
            })
            .catch((error) => {
              this.$notify.error({
                title: 'SignIn Error',
                message: error.message
              })
            })
        }
      })
    },
    gotoSignUpPage() {
      if (this.vesselId && this.inviteId && this.inviteEmail) {
        this.$router.push({
          path: '/signup',
          query: {
            vesselId: this.vesselId,
            inviteId: this.inviteId,
            email: this.inviteEmail
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$router.push('/signup')
      }
    },
    async goToAppPage() {
      const userId = this.user.uid
      const userRef = firebaseApp.database().ref(`/users/${userId}`)
      if (this.vesselId && this.inviteId && this.inviteEmail) {
        console.log("Processing invite...")
        await this.$router.push({
          path: '/acceptInvite',
          query: {
            vesselId: this.vesselId,
            inviteId: this.inviteId,
            email: this.inviteEmail
          }
        }).catch(error => {
          console.log(error)
        })
      }else {
        console.log("Going to main app")
        console.log("User–", this.user)
        await userRef.once('value').then(res => {
        const data = res.val();
        console.log(data)
        this.$store.commit('setUser', this.user)
        if(data.vessels && Object.keys(data.vessels).length > 0){
          console.log("User vessels: ", data.vessels)
          this.$router.push(`/app/${Object.keys(data.vessels)[0]}`)
        } else {
          console.log("No vessels retrieved.")
          this.$notify.error({
            message: 'You have no vessels associated with your account. If you think this message is an error, contact customer support.'
          })
        }
      })
      }
      
    },
    logout() {
      firebaseApp.auth().signOut().then(() => {
        this.$router.push('/')
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.signin-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: $clr-primary;
  .signin-form {
    width: 375px;
    background-color: white;
    border-radius: 10px;
    h1 {
      margin: 0;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.signin-view {
  .signin-form {
    .el-form {
      &-item {
        margin-bottom: 20px;
        &__label {
          padding: 0;
          line-height: 20px;
        }
        &__content {
          color: #606266;
          line-height: 20px;
        }
        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>