<template>
  <div class="welcome-view">
    <bgi-header />
    <div class="welcome-content">
      <h1>Welcome to the Smart Skipper™ Web portal</h1>
    </div>
    <bgi-footer />
  </div>
</template>

<script>
import firebaseApp from '@/firebase/app'
import { mapState } from 'vuex'
import BgiHeader from '@/components/layout/header'
import BgiFooter from '@/components/layout/footer'

export default {
  components: {
    BgiHeader,
    BgiFooter
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    firebaseApp.database().ref(`/users/${this.user.uid}`).once('value').then(res => {
      const data = res.val()
      if (data.vessels) {
        this.$router.push(`/app/${Object.keys(data.vessels)[0]}`)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.welcome-view {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;

  .welcome-content {
    width: 100%;
    min-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    > h1  {
      color: $clr-primary;
    }
  }
}
</style>
