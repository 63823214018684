<template class="geofence-mainview">
<el-container>
  <el-aside class="geofence-aside">
    <bgi-aside />
  </el-aside>

  <el-main class="geofence-viewer">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : 'XXX' }}
    </el-header>
    <div class="geofence-panel" style="display:flex;flex-direction:column; overflow:scroll;">
      <div style="padding:10px">
        {{viewTitle}}
        &nbsp;
        <!-- <a v-bind:href="'https://bg-link-v2.firebaseio.com/' + this.tileLoggingPath" target="_blank" style="display:none;">Open Data</a> -->
        &nbsp;
        {{selectedAlarm.title}}
        <button @click="handleProcessLog" style="float:right;display:none;">{{processButtonText}}</button>
        <hr>

      </div>
      <hr>
      <br>
      <div style="white-space:nowrap">
        <label>Alarms: </label>
        <select v-if="ownerID != null" v-model="alarmFilter" v-on:change="updateLogDisplayData">
          <option :value="-1">Select an alarm, or view device logs.</option>
          <option v-for="(item, index) in alarmOptions" :key="index" v-bind:value="index">
            {{item.name + " " + item.title}}</option>
        </select>
      </div>
      <div class="buttonRow" style="display:flex; width: 50%;">
        <button @click="retrievePreviousPage" v-if="page > 0" class="el-button el-button--primary" style="flex:.33" :disabled="this.page == 0">Previous Page</button>
        <button v-else class="el-button" style="flex:.33" :disabled="this.page == 0">Previous Page</button>
        <button v-if="logRows.length >= 100" @click="retrieveNextPage" class="el-button el-button--primary" style="flex:.33">Next Page</button>
        <button v-else class="el-button" style="flex:.33">Next Page</button>
        <span style="flex:1; display: inline-flex;
        align-items: center; text-align:right; margin-left:5%" v-if="alarmFilter != -1">Current Page: {{this.page + 1}}</span>
      </div>
      <!-- <div style="padding:10px; display:flex;flex-direction:column;"> -->
        <table style="width:100%">
          <tr>
            <th>Date</th>
            <th>Alarms</th>
          </tr>
          <tr v-for="(item, index) in logRows" class="logging_row_div" style="border-bottom: solid silver 2px" :key="index">
            <td class="date_column">
              <div class="date_column_data_wrapper">
                {{dateString(item.logDate)}}
              </div>
            </td>
            <td class="alarm_detail_column">
              <div class="date_column_data_wrapper">
                {{item.title}}
              </div>
            </td>
          </tr>
        </table>
      <!-- </div> -->
    </div>
  </el-main>
</el-container>
</template>

<script>

import {
  mapState
} from 'vuex';
import BgiAside from '@/components/alarms/aside.vue';
import axios from '@/plugins/axios';
import firebaseApp from '../firebase/app'


export default {
  components: {
    BgiAside
  },
  data() {
    return {
      dataLoading: true,
      meta: null,
      inited: false,
      fireData: {},
      fireDataIds: {},
      ownerID: null,
      alarmUUID: null,
      page: 0,
      logRows: [],
      alarmFilter: -1,
      alarmOptions: [],
      viewTitle: 'title',
      aggregations: {},
      activations: {},
      tile: {},
      alarms: [],
      processedData: {},
      dataPath: '',
      selectedAlarm: {},
      filteredAlarms: {},
      detailsDisplayMessage: "Details Display Area",
      processButtonText: "Process Log"
    }
  },
  computed: {
    ...mapState(['collapse']),
    newRadiusDisplay() {
      if (this.newRadius >= 0)
        return this.newRadius.toString() + 'ft';

      return 'Disabled';
    }
  },
  async mounted() {
    this.logRows = [];
    // this.updateDataBinding();
    window.vueAside.hardCodeAsidePath = 'Sensors';
    const alarmUUIDSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/alarms`).orderByChild("parentDevice").equalTo(this.$route.params.sensorId).once('value')
    console.log("Snap val:", alarmUUIDSnap.val())
    let alarmUUID;
    console.log(alarmUUIDSnap.val())
    alarmUUIDSnap.forEach((alarm)=>{
      const alarmData = alarm.val();
      alarmData["id"] = alarm.key;
      this.alarmOptions.push(alarmData);
    })
    if(alarmUUIDSnap.exists()){

      alarmUUID = alarmUUIDSnap.val();
    } else { 
      console.log("Alarm UUID does not exist.")
      return;
    }
    this.alarmUUID = alarmUUID;
    const dataSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/meta`).once('value')
    const data = dataSnap.val()
    // console.log("Vessel Meta: ", data)
    this.ownerID = data.ownerID;
    // if(data.ownerID != null){
    //   const logData = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/alarms/${this.$route.params.sensorId}`).once("value")
    //   this.logRows = [];
    //   logData.forEach((logSnap) =>{
    //     const logData = logSnap.val();
    //     logData['logDate'] = logSnap.key;
    //     this.logRows.push(logData)
    //   })
    //   console.log("Logging Data: ", this.logRows)
    // }
    
  },
  methods: {
    async updateLogDisplayData(evt){
      if(evt.target.value > -1){
        const selectedAlarm = this.alarmOptions[evt.target.value];
        console.log(selectedAlarm)
        this.selectedAlarm = selectedAlarm;
        const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/alarms/${selectedAlarm.id}`).orderByKey().limitToLast(100).once('value')
        console.log(deviceLogsSnap.val())
        this.logRows = [];
        deviceLogsSnap.forEach((logSnap)=>{
          const logData = logSnap.val();
          const testDate = new Date(logSnap.key)
          if(this.isValidDate(testDate)){
            logData["logDate"] = logSnap.key;
            this.logRows.push(logData)
          }
        })
        this.logRows = this.logRows.reverse()
      }
    },
    isValidDate(dateObject){
      return new Date(dateObject).toString() !== 'Invalid Date';
    },
    async retrieveNextPage(){
      var key = this.logRows[this.logRows.length-1]['logDate'];

      const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/alarms/${this.selectedAlarm.id}`).orderByKey().limitToLast(100).endAt(key).once('value')
      console.log("Alarm Logs Key: ", deviceLogsSnap.ref.key)
      // console.log("Parent Path: ", deviceLogsSnap.ref.parent.parent.key.toString())
      const deviceLogs = deviceLogsSnap.val()
      console.log(deviceLogs)
      // console.log("Device logs: ", deviceLogs)

      this.logRows = [];
      // this.loggingData = [];
      deviceLogsSnap.forEach(logSnap => {
        const logData = logSnap.val();
        // this.selectedDeviceType = log.type;
        const testDate = new Date(logSnap.key)
          if(this.isValidDate(testDate)){
            logData["logDate"] = logSnap.key;
            this.logRows.push(logData)
          }
      })

      this.logRows = this.logRows.reverse();

      this.page += 1;
    },

    async retrievePreviousPage(){
      var key = this.logRows[0]['logDate'];
      console.log("Previous key: ", key)
      const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/alarms/${this.selectedAlarm.id}`).orderByKey().limitToFirst(100).startAt(key).once('value');
      
      console.log("Device Logs Key: ", deviceLogsSnap.ref.key)
      // console.log("Parent Path: ", deviceLogsSnap.ref.parent.parent.key.toString())
      const deviceLogs = deviceLogsSnap.val()
      console.log("Device Logs: ", deviceLogs)
      // console.log("Device logs: ", deviceLogs)

      this.logRows = [];

      deviceLogsSnap.forEach(logSnap => {
        const logData = logSnap.val();
        const testDate = new Date(logSnap.key)
          if(this.isValidDate(testDate)){
            logData["logDate"] = logSnap.key;
            this.logRows.push(logData)
          }
      })
      this.logRows = this.logRows.reverse();
      this.page -= 1;
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.ownerID = null;
      this.watchPaths['device'] = `/vessels/${this.$route.params.vesselId}/devices/${this.$route.params.sensorId}`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;
      this.watchPaths['aggregations'] = `/vessels_aggregation/${this.$route.params.vesselId}/devices/${this.$route.params.sensorId}/logTotals`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    vesselIdWatcher(newVal, oldVal) {
      newVal;
      oldVal;
      this.updateDataBinding();
    },
    handleLogInputSelect(event) {
      let option = event.target.selectedOptions[0];
      //this.detailsDisplayMessage = option.dataset.alarmindex + ' ' + option.dataset.logindex;
      this.detailsDisplayMessage = this.logRows[option.dataset.logindex].inputs[option.dataset.inputindex];
    },
    async handleProcessLog() {
      let vesselId = this.$route.params.vesselId;
      let tileId = this.$route.params.tileId;
      this.processButtonText = 'Processing...'
      let res = null;
      let error = null;
      try {
        res = await axios.post('/processtilelog', {
          vesselId,
          tileId,
          ownerID: this.meta.ownerID,
          device: this.parentName
        });
      } catch (err) {
        error = err;
      }

      if (error) {
        console.log('process tile log error', error);
        //this.status = 'Accept Invite Error';
        return null;
      }

      this.processButtonText = 'Process Log'

      let data = res.data;
      if (!data.success) {
        alert('failed error, check log');
        console.log('process error', res);
      }
      return null;
    },
    // bindLoggingQuery() {
    //   if (this.queryRef) {
    //     this.queryRef.off('value', this.queryHandler);
    //   }

    //   this.queryRef = window.firebase.database().ref(this.tileLoggingPath);
    //   this.queryHandler = this.queryRef
    //     .orderByKey()
    //     .limitToLast(100)
    //     .on('value', snapshot => {
    //       this.logRows = snapshot.val();
    //       this._processlogRows();
    //     });

    // },
    dateString(date) {
      let d = new Date(date);
      let dstr = d.toLocaleDateString(undefined);
      let tstr = d.toLocaleTimeString();
      return dstr + ' ' + tstr;
    },
    selectedAlarms(item) {
      let map = item.alarms;
      let r = {};
      for (let i in map)
        if (map[i].parentDevice === this.device.name
        && map[i].tile === this.device.tileName) {
          if (map[i].title.indexOf('Running On Backup Battery') !== -1) {
            r[i] = map[i]
          } else if (map[i].title.indexOf('Disconnected') !== -1) {
            r[i] = map[i]
          }
        }
      return r;
    },
    str(stringToBe) {
      if (stringToBe === undefined || stringToBe === null)
        return "";
      return stringToBe.toString();
    },
    getData() {
      this.meta = this.fireData['meta'];
      this.device = this.fireData['device'];
      this.aggregations = this.fireData['aggregations'];
      if (!this.meta)
        return;
      if (!this.device)
        return;
      if (!this.aggregations)
        return;

console.log('d', this.$route.params.sensorId, this.device);

      if (this.aggregations.activations)
        this.activations = this.aggregations.activations;
      else
        this.activations = {};

      this.viewTitle = this.device.name;

      if (!this.ownerID) {
        this.ownerID = this.meta.ownerID;
        this.tileLoggingPath = `/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/devices/${this.device.name}`;
        // this.bindLoggingQuery();
      }
    },
    _processlogRows() {
      this.processedData = {};

    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
    '$route.params.sensorId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
select {
  width: 250px;
  display: inline-block;
}
.geofence-panel {
  margin-left: 95px;
}
.geofence-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;

}
.geofence-viewer {
    height: calc(100vh - 120px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}
.geofence-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}
.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}

.log_value_display {
    padding: 0.25em;
    text-align: center;
}

.alarm_display_list {
    }

.date_column {
    text-align: center;
}
.alarm_detail_column div {
  text-align: center;
    /* border: solid 2px silver;
    padding: 15px 5px;
    border-radius: 10px;
    display: inline-block;
    width: 100%; */
}
.alarm_detail_column {
    }

span.raw_value {
    font-size: 0.75em;
}

/* td div.sensor_alarm_item {
  display: inline-block;
  padding: 4px 8px;
  margin: 4px;
  width: auto;
}

.date_column_data_wrapper {
  border: solid 1px rgb(100,100,255);
  border-radius: 10px;
} */
</style>
