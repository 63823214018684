<template class="charts-mainview">
<el-container class="charts-container">
  <el-aside class="charts-aside">
    <bgi-aside />
  </el-aside>
  <el-main class="charts-viewer">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : '' }}
    </el-header>
    <div style="padding:10px">
      {{viewTitle}}
    </div>
    <div class="create_wrapper">
      <label>Chart Name<br><input id="chart_name" v-model="chart.name"></label>
      <br>
      <br>
      <label style="display:inline-block;">Dates<br>Start <input id="start_date" v-model="chart.startDate"></label>
      <label style="display:inline=block;">&nbsp;<br>End <input id="end_date" v-model="chart.endDate"></label>
      <br>
      <br>
      <label>Sensors</label>
      <br>
      <label><input type="checkbox" v-model="chart.BGWS_00">BGWS-00</label>
      <br>
      <label><input type="checkbox" v-model="chart.BGWS_01">BGWS-01</label>
      <br>
      <label><input type="checkbox" v-model="chart.BGWS_02">BGWS-02</label>
      <br>
      <label><input type="checkbox" v-model="chart.BGWS_03">BGWS-03</label>
      <br>
      <label><input type="checkbox" v-model="chart.BGWS_04">BGWS-04</label>
      <br>
      <br>
      <el-button @click="handleUpdateChartData">Save</el-button>
      &nbsp;
      <el-button @click="handleGenerateChartData">Generate and View</el-button>
      <br>
    </div>
  </el-main>
</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import BgiAside from '@/components/alarms/aside.vue'
import axios from '@/plugins/axios';

export default {
  components: {
    BgiAside
  },
  data() {
    return {
      dataLoading: true,
      meta: null,
      viewTitle: 'Edit Chart: [Chart Name]',
      fireData: {},
      fireDataIds: {},
      chart: {}
    }
  },
  computed: {
    ...mapState(['collapse'])
  },
  mounted() {
    window.vueAside.commandCallback = (item) => this.headerCommand(item);
    this.updateDataBinding();
  },
  methods: {
    vesselIdWatcher(newVal, oldVal) {
      newVal;
      oldVal;
      this.updateDataBinding();
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.watchPaths['chart'] = `/vessels/${this.$route.params.vesselId}/charts/${this.$route.params.chartId}`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    async handleGenerateChartData() {
      await this.handleUpdateChartData(false);
      let token = await window.firebase.auth().currentUser.getIdToken(true);
      await axios.post('/processchart', {
          token,
          vesselId: this.$route.params.vesselId,
          chartId: this.$route.params.chartId
        });

      this.$router.push(`/app/${this.$route.params.vesselId}/charts/${this.$route.params.chartId}/view`);
    },
    async handleUpdateChartData(route = true) {
      let res = null;
      let error = null;

      let sensorsList = '';
      for (let c = 0; c < 6; c++) {
        let vname = 'BGWS_0' + c.toString();
        let sname = 'BGWS-0' + c.toString();

        if (this.chart[vname])
          sensorsList += sname + ",";
      }
      sensorsList = sensorsList.slice(0,-1);
      this.chart.sensorsList = sensorsList;

      let token = await window.firebase.auth().currentUser.getIdToken(true);
      try {
        let updatePacket = {
          token,
          vesselId: this.$route.params.vesselId,
          chartId: this.$route.params.chartId,
          name: this.chart.name,
          startDate: this.chart.startDate,
          endDate: this.chart.endDate,
          sensorsList
        };
        console.log(updatePacket);
        res = await axios.post('/chart', updatePacket);
      } catch (err) {
        error = err;
      }

      console.log(res);

      if (error) {
        console.log('post chart error', error);
        //this.status = 'Accept Invite Error';
        return null;
      }

      if (route)
        this.$router.push(`/app/${this.$route.params.vesselId}/charts/`);
    },
    headerCommand(item) {
      if (item.name === 'My Charts') {
        this.$router.push(`/app/${this.$route.params.vesselId}/charts/`);
      }
    },
    formatDateTime(inDate) {
      let d = new Date(inDate);
      if (new Date() - d > 60 * 60 * 23 * 1000) {
        return new Date(d).toLocaleDateString(undefined);
      } else {
        return new Date(d).toLocaleTimeString();
      }
    },
    getData() {
      this.chart = this.fireData['chart'];
      this.meta = this.fireData['meta'];

      if (!this.meta)
        return;

      if (!this.chart)
        return;

      if (!this.chart.name)
        this.chart.name = '';
      if (!this.chart.endDate)
        this.chart.endDate = '';
      if (!this.chart.startDate)
        this.chart.startDate = '';

      if (!this.chart.sensorsList)
        this.chart.sensorsList = '';
      let sensors = this.chart.sensorsList.split(',');
      sensors.forEach((sensor) => {
        let n = sensor.replace('-', '_');
        this.chart[n] = true;
      });

      this.viewTitle = this.chart.name;
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
    '$route.params.chartId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.charts-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;

}
.charts-viewer {
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 0;
    margin: 0;
}
.charts-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}

.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}

.chart_list_table {
    width: 90%;
}

.chart_list_wrapper,
.create_wrapper {
    padding: 10px;
    text-align: center;
}
</style>
