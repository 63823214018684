<template>
  <div class="reset-password-view">
    <el-card class="reset-form">
      <h1>Reset Password</h1>
      <el-form ref="resetForm" label-position="top" :model="resetForm">
        <el-form-item
          v-if="isGetResetLink"
          prop="email"
          label="Email"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="resetForm.email" @keyup.enter.native="submit('resetForm')" />
        </el-form-item>
        <el-form-item
          v-else
          prop="password"
          label="New Password"
          :rules="[
            { required: true, message: 'Please input password', trigger: 'blur' },
            { min: 8, max: 32, message: 'Lenght should be 8 to 32', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="resetForm.password" type="password" @keyup.enter.native="submit('resetForm')" />
        </el-form-item>
        <el-divider />
        <el-form-item>
          <el-button v-if="isGetResetLink" type="primary" @click="submit('resetForm')">
            Get Reset Link
          </el-button>
          <el-button v-else type="primary" @click="submit('resetForm')">
            Reset Password
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/app'

export default {
  data() {
    return {
      resetForm: {
        email: '',
        password: ''
      },
      isLoggedIn: false,
      isGetResetLink: false
    }
  },
  created() {
    this.isGetResetLink = (!this.$route.query.mode || !this.$route.query.oobCode)
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isGetResetLink) {
            firebaseApp.auth().sendPasswordResetEmail(this.resetForm.email).then(() => {
              this.$router.push('/signin')
            }).catch(error => {
              this.$notify.error({
                message: error.message
              })
              this.$router.push('/signin')
            })
          } else {
            firebaseApp.auth().confirmPasswordReset(this.$route.query.oobCode, this.resetForm.password).then(() => {
              this.$router.push('/signin')
            }).catch(error => {
              this.$notify.error({
                message: error.message
              })
              this.$router.push('/signin')
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.reset-password-view {
  .reset-form {
    .el-form {
      &-item {
        margin-bottom: 20px;
        &__label {
          padding: 0;
          line-height: 20px;
        }
        &__content {
          color: #606266;
          line-height: 20px;
        }
        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.reset-password-view {
  width: 100%;
  min-height: 100vh;
  background-color: $clr-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  .reset-form {
    width: 375px;
    background-color: white;
    border-radius: 10px;
    h1 {
      margin: 0;
      text-align: center;
    }
  }
}
</style>