<template>
<div class="big-app">
  <router-view />
</div>
</template>

<script>
window.BGHeaderItems = {
  alarms: {
    title: 'Dashboard'
  },
  sensors: {
    title: 'Sensors'
  },
  users: {
    title: 'Users'
  },
  info: {
    title: "Info"
  }
};

window.fetch('/json/alarmdesc.json', {
    method: "get"
  }).then(r => r.json())
  .then(g => window.BGAlarmDescriptions = g);

window.BGHeaderList = [];
for (let name in window.BGHeaderItems)
  window.BGHeaderList.push({
    name,
    title: window.BGHeaderItems[name].title
  });

window.BGNavigateRoute = (route, router, newVesselId = null, newMenuItem = null) => {
  let topNavItem = window.BGHeaderItems[route.name];

  if (window.vueAside.hardCodeAsidePath === 'Sensors')
    return router.push(`/app/${newVesselId}/sensors`);

  if (route.params.vesselId !== newVesselId) {
    if (topNavItem && route.name !== 'alarms') {
      router.push(`/app/${newVesselId}/${route.name}`)
    } else
      router.push(`/app/${newVesselId}`)
  } else {
    if (!topNavItem)
      router.push(`/app/${newVesselId}`)
  }
  newMenuItem;
};

window.watchFirebaseRoutes = (watchList, callback) => {
  if (!window.firebaseWatchRefs)
    window.firebaseWatchRefs = {};
  if (!window.firebaseWatchHandles)
    window.firebaseWatchHandles = {};

  if (window.firebaseWatchList) {
    for (let i in window.firebaseWatchList) {
      let path = window.firebaseWatchList[i];
      window.firebaseWatchRefs[path].off('value', window.firebaseWatchHandles[path]);
    }
    window.firebaseWatchHandles = {};
    window.firebaseWatchRefs = {};
  }

  window.firebaseWatchList = watchList;
  for (let i in window.firebaseWatchList) {
    let path = window.firebaseWatchList[i];
    window.firebaseWatchRefs[path] = window.firebase.database().ref(path);
    window.firebaseWatchHandles[path] = window.firebaseWatchRefs[path].on('value', res => {
      callback(i, path, res);
    });
  }
};

export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

.bgi-app {
    width: 100%;
    min-height: 100vh;
    background-color: $clr-primary;
}
</style>
