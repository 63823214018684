<template>
  <div class="bgi-footer">
    <a class="privacy-policy" href="/privacy.html" target="_blank">
      Privacy Policy
    </a>
    <div class="company-address">
      <p>Blue Guard Innovations</p>
    </div>
    <a class="contact-us" href="mailto:info@bluebgi.com?subject=contact from app" target="_blank">
      Contact Us
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.bgi-footer {
  width: 100%;
  height: 60px;
  background-color: $clr-bg-secondary;
  padding: 0 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  color: white;
  .privacy-policy, .contact-us {
    color: white;
    cursor: pointer;
  }
  .company-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
