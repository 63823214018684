import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
let vm;
export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options', "activationRef", "nameKey", "recalcMethod"],
  mounted () {
    vm = this;
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.options.onClick = (evt) =>{
      console.log(evt);
      console.log(this.$data._chart.getElementsAtEvent(evt));
      const chartPoint = this.$data._chart.getElementsAtEvent(evt);
      this.recalcMethod(chartPoint, this.nameKey)
    }
    console.log("Mounting with options: ", this.options)
    this.renderChart(this.chartData, this.options)
    this.$emit('update:activationRef', this.$data._chart)
  },
  watch: {
    options: {
      deep: true,
      handler: (newOptions)=> {
        console.log("Options updated: ", newOptions.scales.xAxes[0])
        vm.$data._chart.destroy()
        // vm.$data._chart.update()
        vm.renderChart(vm.chartData, vm.options)
      }
    },
  }
}