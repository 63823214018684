<template>
<div class="vessel">
  <el-dialog :modal="false" :close-on-click-modal="false" :title="'Alert Details'" :visible.sync="showVesselAlertDetails" width="40%" class="dialog">
    <p v-for="(line, index) in dashboardAlarms" :key="index" :style="{ color : `${line.color}` }">
      {{line.name}} - {{line.title}}
    </p>
    <div slot="footer" class="dlg-footer">
      <button class="cancelButton el-button el-button--primary" type="text" @click="showVesselAlertDetails = false">Close</button>
    </div>
  </el-dialog>
  <div class="vessel-img" :class="isCollapse ? 'hide' : null">
    <img v-if="true" :src="(imageSRC) ? imageSRC : '/images/bgi-boat.svg'" alt="vessel" class="vessel-img-item" :class="vesselSelected ? 'selected' : null" />
    <!-- <img v-else src="/images/boat.png" alt="vessel" class="vessel-img-item" :class="vesselSelected ? 'selected' : null" /> -->
    <el-tooltip v-if="isAlert" placement="right-end">
      <div slot='content'>
        <h4>Alert Details</h4>
        <p v-for="(line, index) in dashboardAlarms" :key="index" :style="{ color : `${line.color}` }">
        {{line.name}} - {{line.title}}
        </p>
      </div>
    <img v-if="isAlert" class="vessel-alert" src="/images/bgi-alert-red.svg" v-on:click="handleAlarmClick" alt="alert" height="30">
    </el-tooltip>

  </div>
  <div :title="meta && meta.name ? meta.name : ''" class="vessel-info" :class="isCollapse ? 'hide' : null">
    <h2 v-if="!vesselSelected">{{ !!meta ? meta.name : 'XXX' }}</h2>
    <h2 v-if="vesselSelected" class="selected-vessel">{{ !!meta ? meta.name : 'XXX' }}</h2>
    <p>{{ `Marina: ${meta != null && meta.marina ? meta.marina : ''}` }}</p>
    <p>{{ `Dock/Slip#: ${meta != null && meta.dock? meta.dock : ''}` }}</p>
    <p>{{ `Last Alarm: ${meta != null && meta.lastAlarm ? new Date(meta.lastAlarm).toLocaleString() : 'None'}` }}</p>
    <p>{{ `Last Updated: ${meta != null && meta.keepAlive ? new Date(meta.keepAlive).toLocaleString() : 'None'}` }}</p>
    <p>{{ `Status: ${ meta != null && meta.isConnected  ? 'Online' : 'Offline'}` }}</p>
    
  </div>

  <div class="vessel-img" :class="!isCollapse ? 'hide' : null">
    <el-tooltip placement="right">
      <div slot="content">
        <h2 style="margin: 0; font-weight: 300">{{ !!meta ? meta.name : 'XXX' }}</h2>
        <p>{{ `Marina: ${meta != null && meta.marina ? meta.marina : ''}` }}</p>
        <p>{{ `Dock/Slip#: ${meta != null && meta.dock? meta.dock : ''}` }}</p>
        <p>{{ `Last Alarm: ${meta != null && new Date(meta.lastAlarm) ? new Date(meta.lastAlarm).toLocaleString() : 'None'}` }}</p>
        <p>{{ `Last Updated: ${meta != null && meta.keepAlive ? new Date(meta.keepAlive).toLocaleString() : 'None'}` }}</p>
        <p>{{ `Status: ${meta != null && meta.isConnected ? 'Online' : 'Offline'}` }}</p>
      </div>
      <img v-if="true" :src="(imageSRC) ? imageSRC : '/images/bgi-boat.svg'" alt="vessel" class="vessel-img-item" :class="vesselSelected ? 'selected' : null" />
      <img v-else src="/images/bgi-boat.svg" alt="vessel" class="vessel-img-item" :class="vesselSelected ? 'selected' : null" />
    </el-tooltip>
    <el-tooltip v-if="isAlert" placement="right-end">
      <div slot='content'>
        <h4>Alert Details</h4>
        <p v-for="(line, index) in dashboardAlarms" :key="index" :style="{ color : `${line.color}` }">
        {{line.name}} - {{line.title}}
        </p>
      </div>
      <img v-if="isAlert" class="vessel-alert" src="/images/bgi-alert-red.svg" v-on:click="handleAlarmClick" alt="alert" height="30">
    </el-tooltip>
  </div>
</div>
</template>

<script>
import firebaseApp from '../../firebase/app'
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    vesselId: {
      type: String,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      connected: false,
      isAlert: false,
      lastAlarm: '',
      imageUrl: 'None',
      imageLoading: true,
      imageSRC: '',
      // meta: null,
      listener: null,
      summaryRef: null,
      showVesselAlertDetails: false,
      dashboardAlarms: null
    }
  },
  computed: {
    vesselSelected() {
      return (this.vesselId === this.$route.params.vesselId)
    }
  },
  mounted() {
    // console.log('aside mounted')
    console.log("VesselID: ", this.vesselId)
    this.summaryRef = firebaseApp.database().ref(`/vessels/${this.$props.vesselId}/alarmSummary`)
    
    // firebaseApp.database().ref(`/vessels/${this.vesselId}/meta`).once('value').then(res => {
    //   this.meta = res.val()
    //   this.getBoatImage();
    // })
    this.getBoatImage();

    this.listener = this.summaryRef.on('value', res => {
      const summary = res.val();
      
      if (summary) {
        this.connected = summary.connected;
        this.lastAlarm = summary.lastAlarm ? this.$moment(summary.lastAlarm).format('hh:mm A MM/DD/YYYY') : 'None';
        this.isAlert = Number(summary.dashboardAlarmsCount) > 0;
        this.dashboardAlarms = summary.dashboardAlarms;
      } else {
        this.connected = false
        this.lastAlarm = 'None'
        this.isAlert = false
      }

      if (!this.dashboardAlarms)
        this.dashboardAlarms = {};
    });

    if (!window.asideVessels)
      window.asideVessels = {};
    window.asideVessels[this.vesselId] = this;
  },
  methods: {
    routeNameWatcher(val) {
      if (val !== 'alarms') {
        if (this.listener) {
          console.log('watcher summaryRef')
          this.summaryRef.off('value', this.listener)
          this.getBoatImage();
        }
      }
    },
    handleAlarmClick() {
      this.showVesselAlertDetails = true;
    },
    async getBoatImage() {
      this.imageSRC = "";
      if (!this.meta)
        return;
        
      if (!this.meta.cache)
        this.meta.cache = {};
      console.log(this.meta.imageUrl)
      
      if(this.meta.imageUrl){
          let url = await firebaseApp.storage().ref(`userPhotos/${this.meta.imageUrl}`).getDownloadURL();
          console.log(this.meta.name, url)
          this.imageSRC = url;
      }
    
      return;
      // let downloadUrl = null;
      // if (this.meta.imageUrl === this.meta.cache.imageUrl)
      //   downloadUrl = this.meta.cache.fullImageUrl;

      // if (!downloadUrl && this.meta.imageUrl)
      //  {
      //   try {
      //     let url = await firebaseApp.storage().ref().child(`userPhotos/${this.meta.imageUrl}`).getDownloadURL();

      //     this.meta.cache.imageUrl = this.meta.imageUrl;
      //     this.meta.cache.fullImageUrl = url;
      //     let updates = {
      //       [`vessels/${this.vesselId}/meta/cache/imageUrl`]: this.meta.imageUrl,
      //       [`vessels/${this.vesselId}/meta/cache/fullImageUrl`]: this.meta.cache.fullImageUrl,
      //       [`vessels/${this.vesselId}/meta/cache/fullImageUrlDate`]: new Date()
      //     };

      //     this.imageLoading = false;
      //     await firebaseApp.database().ref().update(updates);
      //   } catch (err) {
      //     console.log('Image loading Error !!!', err)
      //     this.imageLoading = true
      //   }
      // }

      // this.imageSRC = this.meta.cache.fullImageUrl;
      // if (this.meta.cache.fullImageUrl){

      //   console.log("Image loaded")
      //   console.log(this.meta)
      //   this.imageLoading = false;
      // }
      // else {
      //   console.log("No image loaded")
      //   console.log(this.meta)
      //   this.imageLoading = true;
      // }
      // this.$forceUpdate();
    }
  },
  beforeDestroy() {
    if (this.listener) {
      this.summaryRef.off('value', this.listener)
    }
  },
  watch: {
    '$route.name': {
      handler: 'routeNameWatcher',
      immediate: true
    },
    '$props.meta.imageUrl': {
      // deep: true,
      handler: function(oldval, newval) {
        console.log("NEW META: ", newval, "OLD META: ", oldval)
        // this.imageSRC = newval.cache.fullImageUrl;
        this.imageSRC = "";
        this.getBoatImage();
      }
    },
    '$props.vesselId': {
      handler: function (oldval, newval){
        this.summaryRef.off('value', this.listener)
        console.log("VesselID Watcher: ",oldval, newval)
        console.log("VesselID: ", this.vesselId)
        this.summaryRef = firebaseApp.database().ref(`/vessels/${this.$props.vesselId}/alarmSummary`)
    
        this.getBoatImage();

        this.listener = this.summaryRef.on('value', res => {
          const summary = res.val();
          
          if (summary) {
            this.connected = summary.connected;
            this.lastAlarm = summary.lastAlarm ? this.$moment(summary.lastAlarm).format('hh:mm A MM/DD/YYYY') : 'None';
            this.isAlert = Number(summary.dashboardAlarmsCount) > 0;
            this.dashboardAlarms = summary.dashboardAlarms;
          } else {
            this.connected = false
            this.lastAlarm = 'None'
            this.isAlert = false
          }

          if (!this.dashboardAlarms)
            this.dashboardAlarms = {};
          });
          }
        }
  }
  }

</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.cancelButton > span {
    height: auto !important;
    width: auto !important;
    overflow:auto !important;
    visibility: auto !important; 
    display: block !important;
}

.dialog {
  z-index: 2010;
}

.vessel-info {
  background-color: rgba(249, 248, 253, 0.8);
  padding-bottom: 20px;

}
.vessel {
    border-bottom: solid lightgray 1px;
    width: 100%;
    min-height: 90px;
    /* margin-top: 10px;*/
    
    height: 90px;
    display: flex;

    padding: 5px 0;
    background-color: transparent;
    transition: background-color 0.3s;
    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 100px);
        height: 100%;
        min-height: 80px;
        margin-right: 10px;
        padding-left: 20px;
        color: black;
        h2,
        p {
            margin: 0;
        }
        h2 {
            font-weight: 300;
            margin-bottom: 5px;
        }
        p {
            font-size: 12px;
        }
    }
    &-img {
        position: relative;
        height: 100%;
        min-height: 80px;
        width: 90px;
        padding: 0 5px;
        h2,
        p {
            margin: 0;
        }
        h2 {
            font-weight: 300;
            margin-bottom: 15px;
        }
        p {
            font-size: 12px;
        }
        .vessel-img-item {
            height: 80px;
            width: 80px;
            border: 2px solid rgba(white, 0.5);
            transition: border 0.3s;
        }
        .vessel-alert {
            position: absolute;
            left: 5px;
            bottom: 0;
            z-index: 9;
        }
        .selected {
            border: 4px solid #105ba7;
        }
    }
    &:hover {
        background-color: rgba(white, 0.1);
    }
    .selected-vessel {
        font-weight: bold;
    }
}

.vessel-alert {
  cursor: pointer;
}

.vessel-alert:hover {
  background: rgba(255,255,255,.5);
  border: solid 2px rgb(255,255,255);
}
</style>
