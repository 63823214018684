import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';


const firebaseConfig = {
  apiKey: "AIzaSyDV21sK_lHuQLsWK-wGdpjJj-1HLmg10hs",
  authDomain: "bg-link-v2.firebaseapp.com",
  databaseURL: "https://bg-link-v2.firebaseio.com",
  projectId: "bg-link-v2",
  storageBucket: "bg-link-v2.appspot.com",
  messagingSenderId: "385308412918",
  appId: "1:385308412918:web:742ab8ffdb98b596b755d7",
  measurementId: "G-FWLBDHRBYF"
}

window.firebase = firebase;

// if (location.hostname === "localhost") {
//   // Point to the RTDB emulator running on localhost.
//   firebase.database().useEmulator("localhost", 9000);
// } 
export const serverValue = firebase.database.ServerValue
export default firebase.apps.length ? firebase.app() : firebase.initializeApp(firebaseConfig)
