import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element-ui'
import './assets/styles/index.scss'
import * as VueGoogleMaps from 'gmap-vue'
import { auth, database } from 'firebase'

Vue.config.productionTip = false

Vue.use(require('vue-moment'))

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAhpgaUURsq7JMSeQ2axy-mWxQ2AfrI5qg',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

auth().onAuthStateChanged(function(user) {

    if (user) {
      console.log("User logged in, main application.")
      store.commit('setUser', user)
      database().ref(`/users/${user.uid}/meta`).once('value').then(meta => {
        const { firstName, lastName } = meta.val()
        store.commit('setUserName', `${firstName} ${lastName}`)
        return
      })
    } else {
      console.log('no user loggedin >>>>')
      store.commit('setUser', null)
    }
  });
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
