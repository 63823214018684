<template class="charts-mainview">
<el-container class="charts-container">
  <el-aside class="charts-aside">
    <bgi-aside />
  </el-aside>
  <el-main class="charts-viewer" style="flex-direction:column;display:flex;overflow:hidden;padding:0;">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : '' }}
    </el-header>
    <div style="padding:10px">
      {{viewTitle}}
    </div>
    <div style="flex:1;overflow: hidden auto;">
      <canvas id="view_chart_component"></canvas>
    </div>
  </el-main>
</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import BgiAside from '@/components/alarms/aside.vue'

export default {
  components: {
    BgiAside
  },
  data() {
    return {
      dataLoading: true,
      meta: null,
      viewTitle: 'View Chart: [Chart Name]',
      fireData: {},
      fireDataIds: {},
      chart: {},
      chartsInited: false
    }
  },
  computed: {
    ...mapState(['collapse'])
  },
  mounted() {
    window.vueAside.commandCallback = (item) => this.headerCommand(item);
    this.updateDataBinding();

    if (!this.chartsInited) {
      window.chartsLoaded = document.createElement("script");
      window.chartsLoaded.setAttribute("src", "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js");
      document.head.appendChild(window.chartsLoaded);
      this.chartsInited = true;
    }
  },
  methods: {
    vesselIdWatcher(newVal, oldVal) {
      newVal;
      oldVal;
      this.updateDataBinding();
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.watchPaths['chart'] = `/vessels/${this.$route.params.vesselId}/charts/${this.$route.params.chartId}`;
      this.watchPaths['chartData'] = `/vessels_aggregation/${this.$route.params.vesselId}/charts/${this.$route.params.chartId}/data`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    formatDateTime(inDate) {
      let d = new Date(inDate);
      if (new Date() - d > 60 * 60 * 23 * 1000) {
        return new Date(d).toLocaleDateString(undefined);
      } else {
        return new Date(d).toLocaleTimeString();
      }
    },
    getData() {
      this.chart = this.fireData['chart'];
      this.chartData = this.fireData['chartData'];
      this.meta = this.fireData['meta'];

      if (!window.Chart)
        return setTimeout(() => this.getData(), 50);

      if (!this.chartData)
        return;
      if (!this.chart)
        return;
      if (!this.meta)
        return;

      var ctx = document.getElementById('view_chart_component').getContext('2d');

      console.log('data pulse coming thru');
      let chartCache = JSON.stringify(this.chartData);
      if (this.chartCache !== chartCache) {
        this.chartCache = chartCache;
        this.activeChartData = JSON.parse(this.chartCache);
        this.chartComponent = new window.Chart(ctx, this.activeChartData);
      }
    },
    headerCommand(item) {
      if (item.name === 'My Charts') {
        this.$router.push(`/app/${this.$route.params.vesselId}/charts/`);
      }
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.charts-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;

}
.charts-viewer {
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 0;
    margin: 0;
}
.charts-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}

.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}

.chart_list_table {
    width: 90%;
}

.chart_list_wrapper,
.create_wrapper {
    padding: 10px;
    text-align: center;
}
</style>
