<template class="geofence-mainview">
<el-container>
  <el-aside class="geofence-aside">
    <bgi-aside />
  </el-aside>

  <el-main class="geofence-viewer">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : 'XXX' }}
    </el-header>
    <div class="geofence-panel">
      <div style="padding:10px;">Geofence</div>
      <div class="googlemapgeofence"></div>
      <div style="text-align:center;padding:10px">
        <el-button type="secondary" @click="setCurrentPosition" style="color:red;float:left;"><i class="material-icons" style="position:relative;top:-.2em;font-size:.85em">gps_fixed</i>
          &nbsp;<span style="position:relative;top:-.25em;">Set Current Position as Home</span></el-button>
        {{formatedGPSPosition}}<span style="color:red;display:none;"> &nbsp;{{formatedNewGPSPosition}}</span>
        <div style="float:right">
          <el-button v-if="!savingPosition || !saveNeeded" :disabled="saveNeeded === false" style="float:right;" type="primary" @click="saveGeoFence">Save</el-button>
          <el-button v-if="savingPosition && saveNeeded" :disabled="true" style="float:right;" type="primary">Saving</el-button>
        </div>
      </div>
      <div style="display:flex;padding: 10px;">
        <el-slider ref="radiusSlider" v-model="newRadius" :max="maxRadius" :min="minRadius" @input="updateRadius" style="flex:1;margin:.25em;" />
        <div style="padding:.75em">&nbsp; {{newRadiusDisplay}}</div>
      </div>
      <div style="text-align:center;padding:.25em;">

      </div>
    </div>
  </el-main>
</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import firebaseApp from '../firebase/app';
import BgiAside from '@/components/alarms/aside.vue';

export default {
  components: {
    BgiAside
  },
  data() {
    return {
      dataLoading: true,
      meta: null,
      inited: false,
      maxRadius: 3000,
      minRadius: 99,
      newRadius: -1,
      fireData: {},
      fireDataIds: {},
      latitude: 0,
      longitude: 0,
      radius: -1,
      cache: {},
      formatedGPSPosition: '',
      formatedNewGPSPosition: '',
      homeLatitude: -25.344, //39.92753128386435
      homeLongitude: 131.036, //-105.13267850814513
      newHomeLatitude: null,
      newHomeLongitude: null,
      saveNeeded: false,
      savingPosition: false
    }
  },
  computed: {
    ...mapState(['collapse']),
    newRadiusDisplay() {
      if (this.newRadius >= 100)
        return this.newRadius.toString() + 'ft';

      return 'Disabled';
    }
  },
  async mounted() {
    if (!window.mapsLoaded) {
      window.mapsLoaded = document.createElement("script");
      window.mapsLoaded.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=AIzaSyDV21sK_lHuQLsWK-wGdpjJj-1HLmg10hs&callback=mapsLoadedCallback&libraries=&v=weekly");
      document.head.appendChild(window.mapsLoaded);
      window.mapsLoadedCallback = () => {
        this.inited = true;
        this.updateDataBinding();

      };
    } else {
      this.freshLoad = true;
      this.inited = true;
      this.updateDataBinding();
    }

      const geofenceSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/tiles/Geofence`).once("value");
      const vesselMetaSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/meta`).once("value")

      this.geoFenceData = geofenceSnap.val();
      this.meta = vesselMetaSnap.val();

      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;
      if (!this.meta)
        return;

      let geoFenceData = this.geoFenceData;
      if (!this.geoFenceData)
        geoFenceData = {
          homeLatitude: 0,
          homeLongitude: 0,
          latitude: 0,
          longitude: 0
        };

      let hLat = geoFenceData.homeLatitude;
      let hLng = geoFenceData.homeLongitude;
      if (!hLat)
        hLat = 0;
      if (!hLng)
        hLng = 0;
      if (this.homeLatitude !== hLat || this.homeLongitude !== hLng) {
        this.homeLatitude = hLat;
        this.homeLongitude = hLng;
        this.newHomeLatitude = null;
        this.newHomeLongitude = null;
      }
      this.latitude = geoFenceData.latitude;
      this.longitude = geoFenceData.longitude;
      if (!this.latitude)
        this.latitude = 0;
      if (!this.longitude)
        this.longitude = 0;

      if (this.radius !== geoFenceData.radius && geoFenceData.radius != null) {
        console.log("Loaded radius: ", geoFenceData.radius)
        this.radius = Math.round(geoFenceData.radius * 3.28084);
        if (this.radius === undefined)
          this.radius = 0;
        this.newRadius = this.radius;
      }
      this.updateMapDisplay();
  },
  beforeDestroy() {
  },
  methods: {
    updateDataBinding() {
      this.watchPaths = {};
      this.watchPaths['Geofence'] = `/vessels/${this.$route.params.vesselId}/tiles/Geofence`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    vesselIdWatcher(newVal, oldVal) {
      newVal;
      oldVal;
      if (this.inited) {
        this.freshLoad = true;
        this.updateDataBinding();
      }
    },
    setCurrentPosition() {
      this.newHomeLatitude = this.latitude;
      this.newHomeLongitude = this.longitude;
      this.updateMapDisplay();
    },
    saveGeoFence() {
      this.sendUpdateFenceCommand();
    },
    async getLocalDeviceGPSPosition_NOT_USED() {
      return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(position => {
          res(position);
        }, err => {
          console.log('gps error', err);
          rej(err);
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });

      });
    },
    toDegreesMinutesAndSeconds(coordinate) {
      var absolute = Math.abs(coordinate);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

      return degrees + "º" + minutes + "'" + seconds + '"';
    },
    convertDMS(lat, lng) {
      var latitude = this.toDegreesMinutesAndSeconds(lat);
      var latitudeCardinal = lat >= 0 ? "N" : "S";

      var longitude = this.toDegreesMinutesAndSeconds(lng);
      var longitudeCardinal = lng >= 0 ? "E" : "W";

      return latitude + "" + latitudeCardinal + " " + longitude + "" + longitudeCardinal;
    },
    async sendUpdateFenceCommand() {
      let radius;
      if(this.newRadius < 100){
        radius = -1;
      } else {
        
        radius = this.newRadius / 3.28084;
      }
      let gps = {
        homeLatitude: this.newHomeLatitude,
        homeLongitude: this.newHomeLongitude,
        radius
      };

      if (gps.homeLatitude === null) {
        gps.homeLatitude = this.homeLatitude;
        gps.homeLongitude = this.homeLongitude;
      }
      this.savingPosition = true;
      setTimeout(() => this.savingPosition = false, 1000);
      await this._sendCommand('gps', gps);

      return null;
    },
    async _sendCommand(cmd, packet) {
      if (!cmd) {
        console.log('error sending packet', this);
        alert('No command found, please contact support');
        return null;
      }

      let vId = this.$route.params.vesselId;
      console.log('command', vId, cmd, packet);
      let vesselCmdRef = firebaseApp.database().ref(`/vessels/${vId}/commands`);
      this.cmdPending = true;

      await vesselCmdRef.update({
        [cmd]: packet
      });
      let me = this;
      setTimeout(() => {
        me.cmdPending = false;
      }, 800);

      return null;
    },
    initMap() {
      if (!this.mapInited) {
        this.mapInited = true;

        this.map = new window.google.maps.Map(document.querySelector(".googlemapgeofence"), {
          zoom: 15,
          center: this.vesselPosition,
        });
        window.map = this.map;
        const image =
          "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

        this.vesselMarker = new window.google.maps.Marker({
          position: this.vesselPosition,
          map: this.map
        });

        this.markerNew = new window.google.maps.Marker({
          position: this.vesselPosition,
          map: this.map,
          icon: image
        });

        this.blueStroke = "#0000ff";
        this.blueFill = "#000099";
        this.redStroke = "#ff0000";
        this.redFill = "#990000";

        this.geoFenceCircle = new window.google.maps.Circle({
          strokeColor: this.redStroke,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: this.redFill,
          fillOpacity: 0.25,
          map: this.map,
          center: this.geoFencePosition,
          radius: this.radius,
        });

        this.geoFenceCircleNew = new window.google.maps.Circle({
          strokeColor: this.blueStroke,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: this.blueFill,
          fillOpacity: 0.25,
          map: this.map,
          center: this.geoFencePosition,
          radius: this.newRadius,
        });

        this.updateNewGPSFormattedDisplay();
        this.map.addListener("center_changed", () => this.updateNewGPSFormattedDisplay());
        this.map.addListener("click", mapClick => this.setNewHomePositionClick(mapClick));
        this.geoFenceCircle.addListener("click", circleClick => this.setNewHomePositionClick(circleClick));
        this.geoFenceCircleNew.addListener("click", circleClick => this.setNewHomePositionClick(circleClick));
      } else {
        if (this.freshLoad) {
          this.map.setCenter(this.vesselPosition);
          this.freshLoad = false;
        }
      }
    },
    async updateMapDisplay() {
      if (!this.inited)
        return;

      this.geoFencePosition = {
        lat: Number(this.homeLatitude),
        lng: Number(this.homeLongitude)
      };
      let lat = this.latitude;
      let lng = this.longitude;
      this.vesselPosition = {
        lat,
        lng
      };
      this.geoFenceNewPosition = {
        lat: this.newHomeLatitude,
        lng: this.newHomeLongitude
      };
      if (this.newHomeLatitude === null)
        this.geoFenceNewPosition = {
          lat: this.geoFencePosition.lat,
          lng: this.geoFencePosition.lng
        };

      this.initMap();

      this.vesselMarker.setPosition(this.vesselPosition);
      this.geoFenceCircle.setCenter(this.geoFencePosition);
      this.geoFenceCircle.setRadius(this.radius);

      if (this.newHomeLatitude !== null)
        this.formatedGPSPosition = this.convertDMS(this.geoFenceNewPosition.lat, this.geoFenceNewPosition.lng);
      else
        this.formatedGPSPosition = this.convertDMS(this.geoFencePosition.lat, this.geoFencePosition.lng);

      if (this.newHomeLatitude === null && this.newRadius === this.radius) {
        this.geoFenceCircleNew.setVisible(false);
        this.markerNew.setVisible(false);
        this.saveNeeded = false;
      } else {
        this.geoFenceCircleNew.setCenter(this.geoFenceNewPosition);
        this.geoFenceCircleNew.setRadius(this.newRadius);
        this.geoFenceCircleNew.setVisible(true);
        this.markerNew.setVisible(this.newHomeLatitude !== null);
        this.markerNew.setPosition(this.geoFenceNewPosition);
        this.saveNeeded = true;
      }
    },
    setNewHomePositionClick(mapClick) {
      let lat = mapClick.latLng.lat();
      let lng = mapClick.latLng.lng();
      this.newHomeLatitude = lat;
      this.newHomeLongitude = lng;
      this.updateMapDisplay();
    },
    updateNewGPSFormattedDisplay() {
      let center = this.map.getCenter();
      let newPos = this.convertDMS(center.lat(), center.lng());

      if (newPos !== this.formatedGPSPosition)
        this.formatedNewGPSPosition = newPos;
      else
        this.formatedNewGPSPosition = '';
    },
    getData() {
      if (!this.inited)
        return;

      // this.meta = this.fireData['meta'];
      // this.geoFenceData = this.fireData['Geofence'];

      // if (!this.meta)
      //   return;

      // let geoFenceData = this.geoFenceData;
      // if (!this.geoFenceData)
      //   geoFenceData = {
      //     homeLatitude: 0,
      //     homeLongitude: 0,
      //     latitude: 0,
      //     longitude: 0
      //   };

      // let hLat = geoFenceData.homeLatitude;
      // let hLng = geoFenceData.homeLongitude;
      // if (!hLat)
      //   hLat = 0;
      // if (!hLng)
      //   hLng = 0;
      // if (this.homeLatitude !== hLat || this.homeLongitude !== hLng) {
      //   this.homeLatitude = hLat;
      //   this.homeLongitude = hLng;
      //   this.newHomeLatitude = null;
      //   this.newHomeLongitude = null;
      // }
      // this.latitude = geoFenceData.latitude;
      // this.longitude = geoFenceData.longitude;
      // if (!this.latitude)
      //   this.latitude = 0;
      // if (!this.longitude)
      //   this.longitude = 0;

      // if (this.radius !== geoFenceData.radius && geoFenceData.radius != null) {
      //   console.log("Loaded radius: ", geoFenceData.radius)
      //   this.radius = Math.round(geoFenceData.radius * 3.28084);
      //   if (this.radius === undefined)
      //     this.radius = 0;
      //   this.newRadius = this.radius;
      // }
      // this.updateMapDisplay();
    },
    updateRadius(val) {
      this.newRadius = Number(val);
      this.updateMapDisplay();
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.geofence-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;
}
.geofence-viewer {
    height: calc(100vh - 120px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}
.geofence-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}
.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}
.geofence-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 95px;
}
.googlemapgeofence {
    flex: 1;
    width: 100%;
}
</style>
