var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reset-password-view"},[_c('el-card',{staticClass:"reset-form"},[_c('h1',[_vm._v("Reset Password")]),_c('el-form',{ref:"resetForm",attrs:{"label-position":"top","model":_vm.resetForm}},[(_vm.isGetResetLink)?_c('el-form-item',{attrs:{"prop":"email","label":"Email","rules":[
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ]}},[_c('el-input',{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('resetForm')}},model:{value:(_vm.resetForm.email),callback:function ($$v) {_vm.$set(_vm.resetForm, "email", $$v)},expression:"resetForm.email"}})],1):_c('el-form-item',{attrs:{"prop":"password","label":"New Password","rules":[
          { required: true, message: 'Please input password', trigger: 'blur' },
          { min: 8, max: 32, message: 'Lenght should be 8 to 32', trigger: ['blur', 'change'] }
        ]}},[_c('el-input',{attrs:{"type":"password"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('resetForm')}},model:{value:(_vm.resetForm.password),callback:function ($$v) {_vm.$set(_vm.resetForm, "password", $$v)},expression:"resetForm.password"}})],1),_c('el-divider'),_c('el-form-item',[(_vm.isGetResetLink)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit('resetForm')}}},[_vm._v(" Get Reset Link ")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit('resetForm')}}},[_vm._v(" Reset Password ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }