<template>
<el-card class="sensor" :body-style="getBodyStyle" :style="getCardStyle">
  <div class="sensor-content" @click="handleTileClick">
    <div style="width:130px;text-align:center;min-height:110px">
      <img :src="`/images/${getImage}.png`" style="max-height:90px;max-width:110px;margin:10px;">
    </div>
    <div v-if="isConnected" class="sensor-details" style="flex:1;min-height:110px">
      <p :style="{ whiteSpace: 'pre' }">Sensor: {{sensor.name}}</p>
      <p :style="{ whiteSpace: 'pre' }">Name: {{sensor.tileName}}</p>
      <p :style="{whiteSpace: 'pre' }">Status: <span :style="{ color : `${sensor.status.line1Color}`}">{{sensor.status.line1}}</span></p>
      <p :style="{ whiteSpace: 'pre' }">Main Power: {{sensor.status.line2}}</p>
      <p :style="{ whiteSpace: 'pre' }">Backup Battery: {{sensor.status.line3}}</p>
      <p :style="{ whiteSpace: 'pre' }">Last Message: {{getLastMessageTime}}</p>
    </div>
    <div v-else class="sensor-details" style="flex:1;min-height:110px">
      <p :style="{ whiteSpace: 'pre', color : `#808080` }">Sensor: {{sensor.name}}</p>
      <p :style="{ whiteSpace: 'pre', color : `#808080` }">Name: {{sensor.tileName}}</p>
      <p :style="{whiteSpace: 'pre', color : `#808080` }">Status: <span :style="{ color : `#808080`}">Vessel Offline</span></p>
      <p :style="{ whiteSpace: 'pre', color : `#808080` }">Main Power: {{sensor.status.line2}}</p>
      <p :style="{ whiteSpace: 'pre', color : `#808080` }">Backup Battery: {{sensor.status.line3}}</p>
      <p :style="{ whiteSpace: 'pre', color : `#808080` }">Last Message: {{getLastMessageTime}}</p>
    </div>
  </div>
</el-card>
</template>

<script>
//import firebaseApp from '@/firebase/app';

export default {
  props: {
    sensor: {
      require: true,
      default: () => null
    },
    isConnected: {
      require: true,
      default: ()=> false
    }
  },
  data() {
    return {
      show: false,
      defaultColor: '#ebeef5',
      borderColor: '#34C759',
      name: 'defaultSensorName',
    }
  },
  computed: {
    getBodyStyle() {
      return {
        borderRadius: '8px',
        position: 'relative',
        height: '100%'
      }
    },
    getCardStyle() {
      return {
        border: 'none',
        borderRadius: '8px',
        position: 'relative',
        height: '140px'
      }
    },
    getImage() {
      if (this.sensor.name.indexOf('BGWS-00') !== -1) {
        return 'locationIcon';
      }
      else if (this.sensor.name.indexOf('BGWS-01') !== -1) {
        return 'bilgeIcon';
      }
      else if (this.sensor.name.indexOf('BGWS-02') !== -1) {
        return 'batteryIcon';
      }
      else if (this.sensor.name.indexOf('BGWS-03') !== -1) {
        return 'tempIcon';
      }
      else if (this.sensor.name.indexOf('BGWS-04') !== -1) {
        return 'lightBulbOutlineIcon';
      }
      else if (this.sensor.name.indexOf('BGWS-05') !== -1) {
        return 'satelliteIcon';
      }
      else if (this.sensor.name.indexOf('BGWS-06') !== -1) {
        return 'gas-gauge_050';
      }
      else if (this.sensor.name.indexOf('BGWS-07') !== -1) {
        return 'boat_tender';
      }
      else if (this.sensor.name.indexOf('BGWS-08') !== -1) {
        return 'roving_watch';
      }
      else if (this.sensor.name.indexOf('BGWS-09') !== -1) {
        return 'roving_watch_alarm';
      }
      else if (this.sensor.name.indexOf('BGWS-10') !== -1) {
        return 'door_sensor';
      }
      else {
        return 'bellIcon';
      }
    },
    getLastMessageTime() {
      if (this.sensor.lastUpdate)
        return new Date(this.sensor.lastUpdate).toLocaleDateString() + ' ' + new Date(this.sensor.lastUpdate).toLocaleTimeString();

      return 'none';
    //  return this.$moment(this.lastUpdate).format("MM-DD-YYYY h:mm A");
    }
  },
  methods: {
    handleTileClick() {
      this.$router.push(`/app/${this.$route.params.vesselId}/sensors/${this.sensor.cachedId}/view`);
      return;
    }
  },
  mounted() {

    //console.log('sensor mounted >>>>', this.sensor.name, this.sensor.key);
  },
  watch: {
    sensor: {
      handler(val) {
        if (val !== null) {
          val;
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.sensor {
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
    &-content {
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 5px;
        flex-direction: row;
        align-items: center;
        display: flex;
    }
    &-content:hover {
      background: rgb(235,235,235);
      border-radius: 2%;
      cursor: pointer;
    }

}
</style>
