<template class="sensors-mainview">
<el-container class="sensors-container">
  <el-aside class="sensors-aside">
    <bgi-aside />
  </el-aside>

  <el-main class="sensors-viewer" style="flex-direction:column;display:flex;overflow:hidden;padding:0;">
    <br><br><br>
        <form v-if="!dataLoading">
          <div class="profileBlock bottomBorder">
            <div class="profileItem label">SMS Notifications: </div>
            <div class="profileItem">
              <input @change="notificationSave" v-model="userData.smsNotify" type="checkbox" title="SMS Notificatons"/>
            </div>
          </div>
          <div class="profileBlock bottomBorder">
            <div class="profileItem label">Email Notifications: </div>
            <div class="profileItem">
              <input @change="notificationSave" v-model="userData.emailNotify" type="checkbox" title="SMS Notificatons"/>
            </div>
          </div>


         <h3>User Info 
            <button
            class="el-button"
            v-if="!editing"
            v-bind:class="{'el-button--primary': (editing == false),
            'el-button--success': (editing && changesMade)}"
            @click="startEditing" >{{ editInfoText }}
            </button>

            <button
            class="el-button"
            v-if="editing"
            v-bind:class="{'el-button--primary': (editing == false),
            'el-button--success': (editing && changesMade)}"
            @click="saveData" >
              Save
            </button>
            <button
            class="el-button el-button--danger"
            v-if="editing"
            @click="cancelEditing">
              Cancel
            </button>
          </h3>
         
          <div class="profileBlock bottomBorder">
            <div class="profileItem label">First Name: </div>
            <div class="profileItem">
              <span v-if="!editing">{{userData.firstName}}</span>
              <input 
              @input="changedInfo"
              v-model="userData.firstName" v-else/>
            </div>

            <div class="profileItem label">Last Name: </div>
            <div class="profileItem">
              <span v-if="!editing">{{userData.lastName}}</span>
              <input
              @input="changedInfo"
               v-model="userData.lastName" v-else/>
            </div>

            <div class="profileItem label">Phone: </div>
            <div class="profileItem">
              <span v-if="!editing">{{userData.phone}}</span>
              <input
              @input="changedInfo"
               v-model="userData.phone" v-else/>
            </div>
          
          </div>


          <div class="profileBlock bottomBorder">
            <button
            class="el-button"
            v-if="!changingPassword"
            v-bind:class="{'el-button--primary': (editing == false),
            'el-button--success': (editing && changesMade)}"
            @click="startChangingPassword" >Change Password
            </button>

            <button
            class="el-button"
            v-if="changingPassword"
            :disabled="!passwordValid"
            v-bind:class="{'el-button--success': passwordValid}"
            @click="saveNewPassword" >
              Save New Password
            </button>
            <button
            class="el-button el-button--danger"
            v-if="changingPassword"
            @click="cancelChangingPassword">
              Cancel
            </button>
            <p v-if="changingPassword" class="profileItem" style="color:red;">{{currentPasswordRequirement}} {{actionError}}</p>

            <div style="width: 100%"><p style="color:green;">{{passwordSuccess}}</p></div>
            <div class="profileBlock bottomBorder" v-if="changingPassword">
              <div class="profileItem label">New Password: </div>
              <div class="profileItem">
                <input
                placeholder="Enter password here"
                autocomplete="new-password"
                type="password"
                @input="changedInfo"
                v-model="firstPassword"/>
              </div>

              <div class="profileItem label">Confirm Password: </div>
              <div class="profileItem">
                <input
                type="password"
                placeholder="Enter again to Confirm"
                autocomplete="new-password"
                @input="changedInfo"
                v-model="confirmationPassword"/>
              </div>
              
            </div>
          </div>

          <h3 v-if="userData
          != null && userData != null && userData.subscription != null">Cloud Plan</h3>
          <div class="profileBlock" v-if="userData
          != null && userData != null && userData.subscription != null">
            <div class="profileItem label">{{userData.subscription.planType}}</div>
            <div class="profileItem">
              <span >Expires {{expirationDate}}</span>
            </div>
          </div>
        </form>

        
  <!-- {{userData}} -->
  </el-main>
</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import BgiAside from '@/components/alarms/aside.vue'
import firebaseApp from '../firebase/app'
import dayjs from 'dayjs'

export default {
  components: {
    BgiAside
  },
  data() {
    return {
      dataLoading: true,
      userData: null,
      editing: false,
      changesMade: false,
      preservedClone: {},
      deviceMap: {},

      changingPassword: false,
      firstPassword: "",
      confirmationPassword: "",
      actionError: "",
      passwordSuccess: ""
    }
  },
  
  mounted() {
    // this.updateDataBinding();
    if(firebaseApp.auth().currentUser == null){
      firebaseApp.auth().onAuthStateChanged((user)=>{
        if(user){
          this.getData();
        }
      })
    }
    this.getData()
  },
  computed: {
    ...mapState(['collapse']),
    expirationDate(){
      if(this.userData == null || this.userData == null) { return ""}
      return dayjs(this.userData.subscription.endDate).format("MM/D/YY")
    },
    editInfoText(){
     if(!this.editing){
       return "Edit Info"
     } else if(this.editing == true && this.changesMade == false){
       return "Save";
     }
     else if(this.editing == true && this.changesMade == true){
       return "Save";
     } else{
       return "Edit Info"
     }
    },
    passwordValid(){
      if(this.firstPassword == null){
        return false;
      }
      if(this.firstPassword.length < 6){
        return false;
      }
      if(this.firstPassword !== this.confirmationPassword){
        return false;
      }
      return true;
    },
    currentPasswordRequirement(){
      if(this.firstPassword == null || this.firstPassword == ""){
        return "";
      }
      if(this.firstPassword.length < 6){
        return "Password must be at least six characters";
      }
      if(this.firstPassword !== this.confirmationPassword){
        return "Passwords must match.";
      }
      return "";
    },
  },
  beforeDestroy() {
  },
  methods: {
    startEditing(evt){
      evt.preventDefault()
      const clone = JSON.parse(JSON.stringify(this.userData));
      console.log("Start",clone)
      this.preservedClone = clone;
      this.editing = !this.editing;
    },
    cancelEditing(evt){
      evt.preventDefault();
      // restore object
      console.log("Canceled",this.preservedClone)
      this.userData = JSON.parse(JSON.stringify(this.preservedClone));
      this.editing = false;
    },
    startChangingPassword(evt){
      evt.preventDefault();
      this.passwordSuccess = "";
      this.changingPassword = true;
    },
    cancelChangingPassword(evt){
      evt.preventDefault();
      this.firstPassword = "";
      this.confirmationPassword = "";
      this.changingPassword = false;
    },
    async getData() {
      const userDataSnap = await firebaseApp.database().ref(`users/${firebaseApp
      .auth().currentUser.uid}/meta`).once("value");
      console.log(userDataSnap.val())
      this.userData = userDataSnap.val();
      this.dataLoading = false;
    },
    async notificationSave(){
      await firebaseApp.database().ref(`users/${firebaseApp
        .auth().currentUser.uid}/meta`).set(this.userData)
    },
    async saveData(evt){
      evt.preventDefault()
      await this.$nextTick()
      if(this.editing && this.changesMade){
        await firebaseApp.database().ref(`users/${firebaseApp
        .auth().currentUser.uid}/meta`).set(this.userData)
        this.editing = false;
        this.changesMade = false;
      }
      
    },
    async saveNewPassword(evt){
      evt.preventDefault();
      try{
        await firebaseApp.auth().currentUser.updatePassword(this.firstPassword)
        this.firstPassword = "";
        this.confirmationPassword = "";
        this.changingPassword = false;
        this.passwordSuccess = "Password successfully changed."
      } catch(error){
        this.actionError = `There was an issue updating the password. Re-authenticate and try again: ${error}`
      }

    },
    changedInfo(){
      this.changesMade = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
h3 {
  text-align: center;
}

.label {
  font-weight: bold;
}

form {
  border: black 2px solid;
  border-radius: 25px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.profileBlock{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  align-items: center;
  justify-content: center;
  
}
.bottomBorder {
  border-bottom: 1px lightgray solid;
}

.profileItem {
  text-align: center;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sensors-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;

}

.sensors-viewer {
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 0;
    margin: 0;
}
.sensors-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}
</style>
