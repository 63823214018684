<template>
  <div class="signup-view">
    <el-card v-if="!isLoggedIn" class="signup-form">
      <h1>Sign Up</h1>
      <el-form ref="signupForm" label-position="top" :model="signupForm">
        <el-form-item
          prop="firstName"
          label="First Name"
          :rules="[
            { required: true, message: 'Please input first name', trigger: 'blur' }
          ]"
        >
          <el-input v-model="signupForm.firstName" @keyup.enter.native="submit('signupForm')" />
        </el-form-item>
        <el-form-item
          prop="lastName"
          label="Last Name"
          :rules="[
            { required: true, message: 'Please input last name', trigger: 'blur' }
          ]"
        >
          <el-input v-model="signupForm.lastName" @keyup.enter.native="submit('signupForm')" />
        </el-form-item>
        <el-form-item
          prop="email"
          label="Email"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="signupForm.email" @keyup.enter.native="submit('signupForm')" :disabled="processingInvite" />
        </el-form-item>
        <el-form-item prop="phone" label="Phone">
          <el-input v-model="signupForm.phone" @keyup.enter.native="submit('signupForm')" />
        </el-form-item>
        <el-form-item
          prop="password"
          label="Password"
          :rules="[
            { required: true, message: 'Please input password', trigger: 'blur' },
            { min: 8, max: 32, message: 'Lenght should be 8 to 32', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="signupForm.password" type="password" @keyup.enter.native="submit('signupForm')" />
        </el-form-item>
        <el-divider />
        <el-form-item>
          <el-button type="primary" @click="submit('signupForm')">
            Sign Up
          </el-button>
        </el-form-item>
        <div :style="{ textAlign: 'center' }">Already have an account? <el-button type="text" @click="gotoSignInPage">Sign In</el-button></div>
      </el-form>
    </el-card>
    <div v-else>
      <el-button @click="goToAppPage">Go to Application</el-button>
      <el-button @click="logout">Sign out</el-button>
    </div>
  </div>
</template>

<script>
import firebaseApp from '../firebase/app'

export default {
  data() {
    return {
      signupForm: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        password: ''
      },
      isLoggedIn: false,
      user: null,
      processingInvite: false,
      vesselId: '',
      inviteId: '',
      inviteEmail: '',
      successfulSignup: false,
    }
  },
  mounted() {
    this.processingInvite = false
    this.vesselId = this.$route.query.vesselId
    this.inviteId = this.$route.query.inviteId
    this.inviteEmail = this.$route.query.email
    firebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
        this.isLoggedIn = true;
        this.user = user;
        if(this.successfulSignup){
          const { email, firstName, lastName, phone } =  this.signupForm
          firebaseApp.database().ref(`/users/${user.uid}`).update({
                meta: {
                  firstName,
                  lastName,
                  email,
                  phone,
                  emailNotify: true,
                  smsNotify: false,
                }
              }).then(() => {
                this.$store.commit('setUser', user)
                if (this.processingInvite) {
                  this.$router.push({
                    path: '/acceptInvite',
                    query: {
                      vesselId: this.vesselId,
                      inviteId: this.inviteId,
                      email: this.inviteEmail
                    }
                  }).catch(error => {
                    console.log(error)
                  })
                }
                else
                  this.$router.push(`/`)
              })
        }
        
        this.goToAppPage();
      } else {
        this.isLoggedIn = false
        this.user = null
        if (this.vesselId && this.inviteId && this.inviteEmail) {
          this.processingInvite = true
          this.signupForm = {
            email: this.inviteEmail,
            firstName: '',
            lastName: '',
            phone: '',
            password: ''
          }
        }
      }
    });
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { email, password} =  this.signupForm
          // TODO: MOVE CALLBACK LOGIC HERE TO BACKEND
          firebaseApp.auth().createUserWithEmailAndPassword(email, password)
            .then(result => {
              const userId = result.user.uid
              if(userId != null){
                this.successfulSignup = true;
              }
              console.log('create user successfully >>>>', result)
      
              
            })
            .catch((error) => {
              this.$notify.error({
                title: 'SignUp Error',
                message: error.message
              })
            })
        }
      })
    },
    gotoSignInPage() {
      if (this.processingInvite) {
        this.$router.push({
          path: '/signin',
          query: {
            vesselId: this.vesselId,
            inviteId: this.inviteId,
            email: this.inviteEmail
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$router.push('/signin')
      }
    },
    goToAppPage() {
      const userId = this.user.uid
      const userRef = firebaseApp.database().ref(`/users/${userId}`)
      
      userRef.once('value').then(res => {
        const data = res.val()
        this.$store.commit('setUser', this.user)
        this.$router.push(`/app/${Object.keys(data.vessels)[0]}`)
      })
    },
    logout() {
      firebaseApp.auth().signOut().then(() => {
        this.$router.push('/')
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.signup-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: $clr-primary;
  .signup-form {
    width: 375px;
    background-color: white;
    border-radius: 10px;
    h1 {
      margin: 0;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.signup-view {
  .signup-form {
    .el-form {
      &-item {
        margin-bottom: 20px;
        &__label {
          padding: 0;
          line-height: 20px;
        }
        &__content {
          color: #606266;
          line-height: 20px;
        }
        .el-button {
          width: 100%;
        }
      }
    }
  }
}
</style>