<template class="sensors-mainview">
<el-container class="sensors-container">
  <el-aside class="sensors-aside">
    <bgi-aside />
  </el-aside>

  <el-main v-if="sensors.length > 0" class="sensors-viewer" style="flex-direction:column;display:flex;overflow:hidden;padding:0;">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : 'XXX' }}
    </el-header>
    <div class="sensor-title" style="padding:10px;border-bottom: solid silver 1px;">
      Sensors
    </div>
    <div style="flex:1;overflow:hidden auto;">
      <el-row :gutter="10" class="sensors-row" v-for="(sensor, index) in sensors" :key="index">
        <bgi-sensor :isConnected="meta.isConnected" :sensor="sensor.sensor" />
      </el-row>
    </div>
  </el-main>
  <el-main v-else class="sensors-viewer" style="background:rgba(0, 0, 0, 0.3)">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : 'XXX' }}
    </el-header>

    <br><br><br><br><br><br>
    <div style="text-align:center;font-size:2em">
      No Sensors
    </div>
  </el-main>

</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import BgiSensor from '@/components/sensor';
import BgiAside from '@/components/alarms/aside.vue'

export default {
  components: {
    BgiSensor,
    BgiAside
  },
  data() {
    return {
      isOnBoard: false,
      dataLoading: true,
      meta: null,
      fireData: {},
      fireDataIds: {},
      sensors: [],
      deviceMap: {}
    }
  },
  mounted() {
    this.updateDataBinding();
  },
  computed: {
    ...mapState(['collapse'])
  },
  beforeDestroy() {},
  methods: {
    vesselIdWatcher(newVal, oldVal) {
      newVal;
      oldVal;
      this.updateDataBinding();
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.watchPaths['devices'] = `/vessels/${this.$route.params.vesselId}/devices`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    getData() {
      this.deviceMap = this.fireData['devices'];
      this.meta = this.fireData['meta'];

      if (!this.meta)
        return;

      this.sensors = [];
      for (let key in this.deviceMap) {
        let sensor = this.deviceMap[key];
        const order = sensor.order !== undefined ? sensor.order : this.sensors.length;
        sensor.cachedId = key;
        this.sensors.push({
          order,
          sensor,
          key
        });
      }

      console.log(this.sensors)

      this.sensors.sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
        return 0
      });
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.sensors-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin-left: 0;

}
.sensors-viewer {
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 0;
    margin-left: 95px;
}
.sensors-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}
.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}
.sensors-row {
    padding: 10px;
    margin: 0!important !important;
}
</style>
