<template>
  <div class="bgi-header">
    <div class="bgi-logo">
      <img src="/images/BGI-Smart-Skipper-Logo-White.png" alt="logo" />
    </div>
    <div v-if="$route.params.vesselId" class="bgi-nav-items">
      <router-link
        v-for="item in navItems"
        :key="item.name"
        class="bgi-nav-item"
        :class="item.name === currentRoute ? 'hide' : null"
        :to="{ name: item.name }" >
        <el-button type="primary">{{ item.title }}</el-button>
      </router-link>
    </div>
    <div class="bgi-avatar">
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="el-dropdown-link" style="margin-right: 10px">
          <el-tooltip>
            <div slot="content">{{ `Logged in as ${userName}` }}</div>
            <avatar :username="userName" :size="30" />
          </el-tooltip>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-setting"
            command="goSettings"
          >
            Settings
          </el-dropdown-item>
          <el-dropdown-item
            icon="el-icon-switch-button"
            command="logout"
          >
            Log out
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <i class="el-icon-search" />
        </span>
        <el-dropdown-menu slot="dropdown">
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/app'
import Avatar from 'vue-avatar'
import { mapState } from 'vuex'

export default {
  components: {
    Avatar
  },
  data: () => ({
    navItems: window.BGHeaderList
  }),
  mounted() {
    window.vueHeader = this;
  },
  computed: {
    ...mapState(['user', 'userName']),
    currentRoute() {
      return this.$route.name
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'logout') {
        firebaseApp.auth().signOut().then(() => {
          this.$router.push('/')
        });
      }
      if (command === 'goSettings') {
        this.$router.push(`/app/userprofile`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.bgi-header {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $clr-primary;
  .bgi-logo {
    display: block;
    height: 100%;
    >img {
      height: 100%;
    }
  }
  .bgi-nav-items {
    display: flex;
    .bgi-nav-item {
      > a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .bgi-avatar {
    display: flex;
    align-items: center;
    .el-dropdown-link {
      font-size: 1.5rem;
      color: white;
    }
  }
}
</style>
