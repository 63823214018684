<template class="geofence-mainview">
<el-container>
  <el-aside class="geofence-aside">
    <bgi-aside />
  </el-aside>

  <el-main class="geofence-viewer">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : 'XXX' }}
    </el-header>
    <div class="geofence-panel" style="display:flex;flex-direction:column;">
      <div style="padding:10px">
        {{viewTitle}}
        &nbsp;
        <!-- <a v-bind:href="'https://bg-link-v2.firebaseio.com/' + this.tileLoggingPath" target="_blank" style="display:none;">Open Data</a> -->
        &nbsp;
        {{selectedAlarm.title}}
        <button @click="handleProcessLog" style="float:right;display:none;">{{processButtonText}}</button>
        <hr>
        <table v-if="tile != null && (tile.type === 'bilge' || tile.tileType == 4)" style="width:100%;">
          <tr>
            <td></td>
            <td>This Hour</td>
            <td>Past 24 hours</td>
            <td>Past 7 days</td>
            <td>Past 30 days</td>
            <td>All Time</td>
          </tr>
          <tr v-if="tile != null && (tile.type === 'bilge' || tile.tileType == 4) && summary720 != null && summary720.activationArray != null && summaryLastHour.activations != null">
            <td>Number of Activations</td>
            <td>{{str(summaryLastHour.activations)}}</td>
            <td>{{arraySliceSum(summary720.activationArray, -24)}}</td>
            <td>{{arraySliceSum(summary720.activationArray, -168)}}</td>
            <td>{{arraySliceSum(summary720.activationArray, -720)}}</td>
            <td>{{str(allTimeSummaries.activations + summaryLastHour.activations)}}</td>
          </tr>
          <tr v-else>
            <td>Number of Activations</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr v-if="tile != null && (tile.type === 'bilge' || tile.tileType == 4) && summary720 && summary720.runtimeArray != null">
            <td>Total Run Time</td>
            <td>{{strTimeDelta(summaryLastHour.runtime)}}</td>
            <td>{{strTimeDelta(arraySliceSum(summary720.runtimeArray, -24))}}</td>
            <td>{{strTimeDelta(arraySliceSum(summary720.runtimeArray, -168))}}</td>
            <td>{{strTimeDelta(arraySliceSum(summary720.runtimeArray, -720))}}</td>
            <td>{{strTimeDelta(allTimeSummaries.runtime + summaryLastHour.runtime)}}</td>
          </tr>
          <tr v-else>
            <td>Total Run Time</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
        <div v-if="tile != null && tile.type === 'bilge' && tile.tileType != 4 ">
          <button 
          class="el-button el-button--primary"
          v-if="activationZoomIndex > 0" @click="zoomOutRecalcActivationChart">Zoom Out</button>
          <bar-chart :key="activationChartOptions.scales.xAxes[0].time.unit + 'Activation'" :chart-data="activationChartData" :options="activationChartOptions" 
          :activationRef.sync="activationChartRef"
          nameKey="activationsChart"
          :recalcMethod="zoomInRecalcActivationChart" />
          
          <button
          class="el-button el-button--primary"
           v-if="runtimeZoomIndex > 0" @click="zoomOutRecalcRuntimeChart">Zoom Out</button>
          <bar-chart 
          :key="runtimeChartOptions.scales.xAxes[0].time.unit +'Runtime'"
          :chart-data="runtimeChartData" 
          :options="runtimeChartOptions"
          nameKey="runtimeChart"
          :recalcMethod="zoomInRecalcRuntimeChart"
          />
        </div>

        <!-- <button @click="testLogCalc">Test Logs</button> -->
        <br>
        Alarms 
        <select v-if="ownerID != null" v-model="alarmFilter" v-on:change="updateLogDisplayData">
          <option :value="-1">Select an alarm, or view device logs.</option>
          <option v-for="(item, index) in combinedAlarms" :key="index" v-bind:value="index">{{item.title}}</option>
        </select>
      </div>

      <div style="flex:1;overflow:auto;" v-if="alarmFilter != -1 && loadingLogs == false">
            <!-- Charts here -->
        <div class="" style="" v-if="alarmFilter != -1 && loadingLogs == false && (selectedDeviceType === 'bilge' || selectedDeviceType === 'loadControl' || selectedDeviceType === 'activationAlarm')">
          <line-chart :chart-data="dataCollection" :options="chartOptions" 
          :key="'activations' + page"></line-chart>
        </div>
        <div class="" style="" v-else-if="alarmFilter != -1 && loadingLogs == false && selectedDeviceType === 'battery'">
          <line-chart :chart-data="dataCollection" :options="chartOptions"
          :key="'battery' + page"></line-chart>
        </div>
        <div class="" style="" v-else-if="alarmFilter != -1 && loadingLogs == false && selectedDeviceType === 'temperature'">
          <line-chart ref="tempChartRef" :checkedAxes="checkboxes" :chart-data="dataCollection" :options="chartOptions"
          :key="'temp' + page"></line-chart>
          
          <label><input type="checkbox" :checked="checkboxes[0]" style="margin-left:10px;" @click="hideYAxis(0)">Internal Temperature</label>
          <label><input type="checkbox" :checked="checkboxes[1]" style="margin-left:10px;" @click="hideYAxis(1)">External Temperature</label>
          <label><input type="checkbox" :checked="checkboxes[2]" style="margin-left:10px;" @click="hideYAxis(2)">Humidity</label>
        </div>
        <div class="" style="display:flex;justify-content:center;" v-else-if="alarmFilter != -1 
        && loadingLogs == false 
        && selectedDeviceType === 'gps'
        && gpsMarker != null
        ">
          <GmapMap
          ref="mapRef"
          :center="gpsMarker[0].position"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          }"
          :zoom="12"
          map-type-id="roadmap"
          style="width: 65vw; height: 500px"
        >
          
          <GmapPolyline v-if="pageGpsLines.length > 0" :path="pageGpsLines" :editable="false" 

          ref="polyline">
          </GmapPolyline>

          <GmapMarker
            :key="index"
            v-for="(m, index) in gpsMarker"
            :icon="index === 0 && page === 0 ? '/images/boat-32.png' : '/images/boat-white-32.png'"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :zIndex="99999"

          />
          <GmapMarker
            :key="'lastMarker' + index"
            v-for="(m, index) in lastGpsMarker"
            :icon="'/images/red-circle-24.png'"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :options="{fillColor:'red',fillOpacity:.8}"
          />
          
        </GmapMap>
        </div>

      <hr>
      <div class="buttonRow" style="display:flex; width: 50%;">
        <button @click="retrievePreviousPage" v-if="page > 0" class="el-button el-button--primary" style="flex:.33" :disabled="this.page == 0">Previous Page</button>
        <button v-else class="el-button" style="flex:.33" :disabled="this.page == 0">Previous Page</button>
        <button v-if="logRows.length >= 100" @click="retrieveNextPage" class="el-button el-button--primary" style="flex:.33">Next Page</button>
        <button v-else class="el-button" style="flex:.33">Next Page</button>
        <span style="flex:1; display: inline-flex;
        align-items: center; text-align:right; margin-left:5%" v-if="alarmFilter != -1">Current Page: {{this.page + 1}}</span>
      </div>
        <table v-if="logRows != null && logRows.length > 0" style="width:100%">
          <tr>
            <th>Date</th>
            <th v-if="selectedDeviceType == 'bilge'">Alarms</th>
            <th v-else-if="selectedDeviceType == 'battery'">Voltage</th>
            <th v-else-if="selectedDeviceType == 'gps'">Latitude</th>
            <th v-else-if="selectedDeviceType == 'temperature'">Internal Temp</th>
            <th v-else-if="selectedDeviceType == 'loadControl' || selectedDeviceType == 'activationAlarm'">Status</th>

            <th v-if="selectedDeviceType == 'bilge' || selectedDeviceType == 'activationAlarm'">Duration</th>
            <th v-else-if="selectedDeviceType == 'battery'">Current</th>
            <th v-else-if="selectedDeviceType == 'gps'">Longitude</th>
            <th v-else-if="selectedDeviceType == 'temperature'">External Temp</th>
            
            <th v-if="selectedDeviceType == 'temperature'">Humidity</th>
          </tr>
          <tr v-for="(item, index) in logRows" 
          class="logging_row_div"
          :class="{'clickable': selectedDeviceType === 'gps' }"
          @click="logClicked(item)"
          style="border-bottom: solid silver 2px" 
          :key="index">
            <td class="date_column">
              <div class="date_column_data_wrapper">
                {{dateString(item.logDate)}}
              </div>
            </td>

            <td class="alarm_detail_column date_column" v-if="selectedDeviceType == 'bilge' || selectedDeviceType == 'activationsAlarm'">
              {{item.title}} {{(item.status != null || item.isActive != null ) ? tileStatus(item.status || item.isActive) : item.voltage + "V"}}
            </td>
            <td class="alarm_detail_column date_column" v-else-if="selectedDeviceType == 'battery'">
              {{ item.voltage + "V"}}
            </td>
            <td class="alarm_detail_column date_column" v-else-if="selectedDeviceType == 'gps'">
              {{ !isNaN( item.latitude) ? computedLatitude(item) : ''}}
            </td>
            <td class="alarm_detail_column date_column" v-else-if="selectedDeviceType == 'temperature'">
              {{ convertedTemp(item.intTempRaw) + ` ${tempUnit}`}}
            </td>
            <td class="alarm_detail_column date_column" v-else-if="selectedDeviceType == 'loadControl' || selectedDeviceType == 'activationAlarm'">
              {{(item.status != null || item.isActive != null ) ? tileStatus(item.status || item.isActive) : ""}}
            </td>

            <td class="date_column" v-if="selectedDeviceType == 'bilge' || selectedDeviceType == 'activationAlarm'" >
              <span v-if="!item.isCurrentActivity">
                {{runTime(item.activityStartTime, item.activityEndTime, true)}}
              </span>
              <span class="currentActivity" v-else-if="!item.hasOwnProperty('activityEndTime')">Current Activity</span>
            </td>
            <td class="date_column" v-else-if="selectedDeviceType == 'battery'" >
              {{item.current}}
            </td>
            <td class="alarm_detail_column date_column" v-else-if="selectedDeviceType == 'gps'">
              {{ !isNaN( item.longitude) ? computedLongitude(item) : ''}}
            </td>
            <td class="date_column" v-else-if="selectedDeviceType == 'temperature'" >
              {{ (probeConnected(item.extTempRaw)) ? convertedTemp(item.extTempRaw) + ` ${tempUnit}` : "Not Connected"}}
            </td>
            <td class="date_column" v-if="selectedDeviceType == 'temperature'" >
              {{item.humidityRaw + "%"}}
            </td>
          </tr>
        </table>
        <p v-else>No logs present for selected device.</p>
      </div>
      <div v-else>
        <p v-if="alarmFilter != -1"> Loading...</p>
        <!-- <p v-else>Null filter</p> -->
      </div>
    </div>
  </el-main>
</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import BgiAside from '@/components/alarms/aside.vue';
import axios from '@/plugins/axios';
import firebaseApp, {serverValue} from '../firebase/app'

import LineChart from '../components/charts/activation-line-chart'
import BarChart from '../components/charts/activation-bar-chart'
import { gmapApi } from 'gmap-vue';
/* eslint-disable-next-line */
import DmsCoordinates, { parseDms } from "../../node_modules/dms-conversion";


const dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc') // dependent on utc plugin
var timezone = require('dayjs/plugin/timezone')
var isBetween = require("dayjs/plugin/isBetween")
var duration = require("dayjs/plugin/duration")
var relativeTime = require("dayjs/plugin/relativeTime")
// var numeral = require('numeral');


dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(relativeTime)
export default {
  name: "TileDetail",
  components: {
    BgiAside,
    LineChart,
    BarChart
  },
  render(){
    return console.log("Rerendering")
  },
  data() {
    return {
      selectedDeviceDisplayTitle: "",
      selectedLogType: "",
      selectedDeviceLogID: "",
      activationZoomIndex: 0,
      runtimeZoomIndex: 0,
      zoomLevels: ["day30", "day7", "hour24", "hour"],
      zoomChartUnit: {
        "day30": "day",
        "day7": "day",
        "hour24": "hour",
        "hour": "minute"
      },
      dataLoading: true,
      loadingLogs: false,
      combinedAlarms: [],
      meta: null,
      inited: false,
      fireData: {},
      fireDataIds: {},
      ownerID: null,
      rawLogData: {},
      loggingData: [],
      logRows: [],
      viewTitle: '',
      aggregations: {},
      activations: {},
      tile: {},
      alarms: [],
      dataPath: '',
      selectedDeviceType: {},
      selectedAlarm: {},
      runTimeSums: {},
      activationSums: {},
      detailsDisplayMessage: "Details Display Area",
      processButtonText: "Process Log",
      alarmFilter: -1,
      page: 0,
      selectedGPSLog: null,
      dataCollection: {},
      chartOptions: {},
      lastHourDeviceLogs:[],
      summaryLastHour: {
        activations: 0,
        runtime: 0
      },
      summary720: {},
      allTimeSummaries: {},
      activationChartRef: null,
      activationChartData: {datasets: []},
      activationsZoomMode: "hour",
      activationsZoom: {
        days7Zoom: null, // week-start timestamp to zoom out to....or just use timestamps to filter on
        hours24Zoom: null, // 24 hour frame to start from when zooming out...or day timestamp to filter
        hourZoom: null, // beginning of which hour to return to
      },
      runtimeZoom: {
        days7Zoom: null,
        hours24Zoom: null,
        hourZoom: null,
      },
      activationChartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: true,
          text: "Activations Grouped By Day"
        },
        legend: {
              display: false
        },
        scales: {
            xAxes: [{
                type: 'time',
                title: {text: "Activations over time"},
                time: {
                    unit: 'day'
                }
            }],
            yAxes: [{
                scaleLabel: {labelString: "Activations", display: true},
            }]
        },
      },
      runtimeChartData: {datasets: []},
      runtimeChartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        callbacks: {
          label: (item) => {
            // console.log("Bar Val", item)
            const barVal = this.runtimeChartData[0][item.index]
            let durationString;
            // const totalDuration = dayjs.duration(barVal.y, "seconds")
            durationString = this.runTime(0, barVal.y * 1000)
            // if(barVal.y < 86400) {
            //   durationString = totalDuration.format("HH:mm:ss");
            // } else {
            //   durationString = totalDuration.humanize();
            // }
            return "Duration: " + durationString;//barVal.y + " Seconds"
          }
        },
        title: {
          display: true,
          text: "Runtime Totals Grouped By Day"
        },
        legend: {
              display: false
        },
        scales: {
            xAxes: [{
                type: 'time',
                title: {text: "Runtimes over time"},
                time: {
                    unit: 'day'
                }
            }],
            yAxes: [{
                scaleLabel: {labelString: "Runtime Seconds", display: true},
            }]
        },
        
      },
      checkboxes: [true, true, true]
    }
  },
  computed: {
    ...mapState(['collapse']),
    newRadiusDisplay() {
      if (this.newRadius >= 0)
        return this.newRadius.toString() + 'ft';

      return 'Disabled';
    },
    tempUnit(){
      let unit = "C"
      if(this.meta.isMetric == null || this.meta.isMetric === false){
        unit = "F";
      }
      return unit;
    },
    currentAlarm() {
      if(this.alarmFilter == -1) return {};
      return this.combinedAlarms[this.alarmFilter]
    },
    currentActivationZoom(){
      return this.zoomLevels[this.activationZoomIndex]
    },
    currentRuntimeZoom(){
      return this.zoomLevels[this.runtimeZoomIndex]
    },
    google(){
      return gmapApi()
    },
    gpsMarker(){
      const lastLogArr = this.loggingData.slice(-1)
      if(lastLogArr.length == 0) return null;
      let lastLog;
      if(this.selectedGPSLog != null){
        lastLog = this.selectedGPSLog
      } else {
        lastLog = lastLogArr[0];
      }
      
      const latitude = parseFloat(lastLog.latitude);
      const longitude = parseFloat(lastLog.longitude);
      if(isNaN(latitude)) { return null }
      if(isNaN(longitude)) { return null }
      // console.log("Last log:", lastLog)
      const markers = [
      {
        position: { lat: latitude, lng: longitude },
      }
      ];
      return markers
    },
    lastGpsMarker(){
      if(!this.loggingData){
        return [];
      }
      const lastLogArr = [this.loggingData[0]]
      if(lastLogArr.length == 0) return null;
      let lastLog;
      if(this.selectedGPSLog != null){
        lastLog = this.selectedGPSLog
      } else {
        lastLog = lastLogArr[0];
      }
      
      const latitude = parseFloat(lastLog.latitude);
      const longitude = parseFloat(lastLog.longitude);
      if(isNaN(latitude)) { return null }
      if(isNaN(longitude)) { return null }
      // console.log("Last log:", lastLog)
      const markers = [
      {
        position: { lat: latitude, lng: longitude },
      }
      ];
      return markers
    },

    pageGpsLines(){
      const pageLogArr = this.loggingData.slice()
      if(pageLogArr.length == 0) return null;

      // if(this.selectedGPSLog != null){
      //   lastLog = this.selectedGPSLog
      // } else {
      //   lastLog = pageLogArr[0];
      // }
      const markers = [
      ];
      pageLogArr.forEach((log)=>{
        const latitude = parseFloat(log.latitude);
        const longitude = parseFloat(log.longitude);
        if(isNaN(latitude)) { return }
        if(isNaN(longitude)) { return }
        markers.push(
          { lat: latitude, lng: longitude },
        );
      })
      
      return markers
    },
    pageGpsMarkers(){
      return this.pageGpsLines.map((coordinates)=>{
        return {position: coordinates}
      })
    }

  },


 
  async mounted() {

    this.updateDataBinding();
    const dataSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/meta`).once('value')
    const data = dataSnap.val()
    // console.log("Vessel Meta: ", data)
    this.ownerID = data.ownerID;
    if(data.ownerID != null){
      
      const tileData = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/tiles/${this.$route.params.tileId}`).once("value")
      this.tile = tileData.val();
      this.viewTitle = this.tile.name;
      // startAt one month ago
      //
      if(this.tile.parentName){
        this.alarms.push({
          alarmType: -1,
          title: "Device Logs",
          device: this.tile.parentName,
          id: this.$route.params.tileId
        })
        if(this.tile.tileType != 4){
          this.combinedAlarms.push({
            alarmType: -1,
            title: "Device Logs",
            device: this.tile.parentName,
            id: this.$route.params.tileId
          })
        }
        
        this.combinedAlarms = this.combinedAlarms.concat(this.tile.alarms);

        if(this.tile.tileType != 4){
          const deviceLogIndex = this.combinedAlarms.findIndex((alarm)=>{
            return alarm.alarmType == -1
          })
          this.alarmFilter = deviceLogIndex;
          this.updateLogDisplayData({
              target: {
                value: deviceLogIndex
              }
            });
        } else if(this.tile.alarms && this.tile.tileType === 4 && this.tile.alarms.length > 0){
          this.alarmFilter = 0;
          this.updateLogDisplayData({
              target: {
                value: 0
              }
            });
        }

        const alarms = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/alarms/`).orderByChild("parentDevice").equalTo(this.tile.parentName).once('value')
        // console.log("Device Alarms: ", alarms.val())
        alarms.forEach((alarmSnap) =>{
          if(this.tile.type === 'bilge'){
            if(alarmSnap.val().alarmType === 5 || alarmSnap.val().alarmType === 6){
              const alarmOption = alarmSnap.val();
              alarmOption["id"] = alarmSnap.key;
              this.alarms.push(alarmSnap.val())
            }
          } else if(this.tile.type === 'gps'){
            if(alarmSnap.val().alarmType === 7){
              const alarmOption = alarmSnap.val();
              alarmOption["id"] = alarmSnap.key;
              this.alarms.push(alarmSnap.val())
            }
          } else if(this.tile.type === 'battery'){
            if(alarmSnap.val().alarmType >= 1 && alarmSnap.val().alarmType <= 4){
              alarmSnap.val()
              const alarmOption = alarmSnap.val();
              alarmOption["id"] = alarmSnap.key;
              this.alarms.push(alarmSnap.val())
            }
          } else if(this.tile.type === 'temperature'){
            if(alarmSnap.val().alarmType >= 1 && alarmSnap.val().alarmType <= 4){
              alarmSnap.val()
              const alarmOption = alarmSnap.val();
              alarmOption["id"] = alarmSnap.key;
              this.alarms.push(alarmSnap.val())
            }
          }
        })
        
      }

      let deviceSummaryRoutePath;
      let deviceTypePath;
      if(this.tile.tileType === 4){
        const [mainAlarm] = this.tile.alarms;
        deviceSummaryRoutePath = mainAlarm.id;
        deviceTypePath = 'alarms';
      } else {
        deviceTypePath = 'devices';
        deviceSummaryRoutePath = this.$route.params.tileId;
      }
      if(!deviceSummaryRoutePath || !deviceTypePath){
        return;
      }
      const totals = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/deviceLogSummaries/${deviceSummaryRoutePath}`).once("value")
      this.summary720 = totals.val()["720HourSummaries"];
      this.allTimeSummaries = totals.val()["allTimeSummaries"];
      
      if(this.tile != null && this.tile.type === "bilge"){
        const timeOfAction = dayjs().startOf("hour");
        let timeOfActionString = timeOfAction.toISOString().split('.')[0]+"Z";
        console.log("Time of action: ", timeOfActionString)
        //get device logs
        // this.loadingLogs = true;

        console.log("Checking device path: ", `/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/${deviceTypePath}/${this.$route.params.tileId}`)
        const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/${deviceTypePath}/${deviceSummaryRoutePath}`).orderByKey().startAt(timeOfActionString).once('value')
        console.log("Device Logs Key: ", deviceLogsSnap.ref.key)
        console.log("Parent Path: ", deviceLogsSnap.ref.parent.parent.key.toString())
        const deviceLogs = deviceLogsSnap.val()
        // console.log("Device logs: ", deviceLogs)
        this.rawLogData = deviceLogs;
        this.logRows = [];

        deviceLogsSnap.forEach(logSnap => {
          const log = logSnap.val();
          if(log.status === true || log.isActive === true){
            this.summaryLastHour.activations += 1;
            let runtime = 0;
            if(log.activityStartTime != null && log.activityEndTime != null){
              runtime = log.activityEndTime - log.activityStartTime;
            } else{
              if(log.activityStartTime != null){
                runtime = dayjs().valueOf() - log.activityStartTime;
              }
            }
            this.summaryLastHour.runtime += runtime;
          }
        })
        this.summary720.activationArray.push({
          [timeOfActionString]: this.summaryLastHour.activations
        })
        this.summary720.runtimeArray.push({
          [timeOfActionString]: this.summaryLastHour.runtime
        })
      } else{
        console.log("Not a bilge.")
      }
      // console.log(this.summary720)
      
      // const activationBarData = this.summary720.activationArray.slice(-24).map((val)=>{
      //   const dataPoint = Object.entries(val)[0];
      //   const x = new Date(dataPoint[0]);
      //   const y = dataPoint[1];
      //   return {x, y}
      // })
      // console.log("counts 1:",dayCounts)
      /**
       * @type {{[key: string]: Number}}
       */
      const dayCounts = {};
      this.summary720.activationArray.slice(-721).forEach((summaryObj)=>{
        const datapoint = Object.entries(summaryObj)
        // console.log("datapoint: ", datapoint, datapoint[0][0])
        // console.log("Day string: ", datapoint[0][0])
        const day = dayjs(datapoint[0][0]).startOf("day")
        // console.log("Created day object", day)
        const dayForLog = day.toISOString();
        if(dayCounts[dayForLog] == null){
          dayCounts[dayForLog] = datapoint[0][1];
        } else {
          dayCounts[dayForLog] += datapoint[0][1];
        }
      });
      // console.log("counts 2:", dayCounts)
      const activationBarData = Object.entries(dayCounts).map((val)=>{
        return {x: new Date(val[0]), y: val[1]}
      })

      console.log(activationBarData)
      this.activationChartData = {
        datasets: [
          {data: activationBarData, fill: false, backgroundColor: '#295ca2', borderColor: "#295ca2"}
        ],  
      }    

      const runtimeDayCounts = {};
      this.summary720.runtimeArray.slice(-721).forEach((summaryObj)=>{
        const datapoint = Object.entries(summaryObj)
        // console.log("datapoint: ", datapoint, datapoint[0][0])
        // console.log("Day string: ", datapoint[0][0])
        const day = dayjs(datapoint[0][0]).startOf("day")
        // console.log("Created day object", day)
        const dayForLog = day.toISOString();
        if(runtimeDayCounts[dayForLog] == null){
          runtimeDayCounts[dayForLog] = datapoint[0][1];
        } else {
          runtimeDayCounts[dayForLog] += datapoint[0][1];
        }
      });

      const runtimeBarData = Object.entries(runtimeDayCounts).map((val)=>{
        return {x: new Date(val[0]), y: Math.round(val[1]/1000)}
      })

      // const runtimeBarData = this.summary720.runtimeArray.slice(-24).map((val)=>{
      //   const dataPoint = Object.entries(val)[0];
      //   const x = new Date(dataPoint[0]);
      //   const y = dataPoint[1] / 1000;
      //   const label = this.strTimeDelta(dataPoint[1]);
      //   return {x, y, label}
      // })

      // console.log(runtimeBarData)
      this.runtimeChartData = {
        datasets: [
          {
            data: runtimeBarData, 
            fill: false, 
            backgroundColor: '#295ca2', 
            borderColor: "#295ca2"
          }
        ],  
      }
      await this.$gmapApiPromiseLazy();
    } else {
      console.log("Owner ID null?")
    }
    
    firebaseApp.database()
    .ref(`/vessels/${this.$route.params.vesselId}/tiles/${this.$route.params.tileId}`)
    .once('value').then(res => {
      this.viewTitle = res.val().name;
    })
    // this.updateInterval = window.setInterval(() => this.getData(), 20000);
  },

  methods: {
    hideYAxis(index){
      console.log(this.$refs)
      this.$refs.tempChartRef.hideDataset(index);
      this.$set(this.checkboxes, index, !this.$refs.tempChartRef.getDatasetVisibility(index))
    },
    logClicked(item){
      console.log("Log click",item)
      if(this.selectedDeviceType === 'gps'){
        this.selectedGPSLog = item;
      }
    },
    convertToF(celsius) {
      return (celsius * 9/5 + 32);
    },
    convertedTemp(temp){
      if(isNaN(temp)){
        return 0;
      }
      if(this.meta.isMetric == null || this.meta.isMetric === false){
        temp = this.convertToF(temp);
      }
      return temp.toFixed(2);
    },
    computedLatitude(item){
      // let [d, m, s, nsew] = new DmsCoordinates(item.latitude, item.longitude).latitude.dmsArray
      // s = s.toFixed(2)
      // return d.toString() + m.toString() + s.toString() + nsew.toString();
      let coordString = new DmsCoordinates(item.latitude, item.longitude).latitude.toString();
      if(coordString.includes(".")){
        let ss = coordString.split(".")[1].substr(0,2);
        let direction = coordString.split(' ')[1]
        return coordString.split(".")[0] + '.' + ss + '" ' + direction 
      } else {
        return coordString;
      }
    },
    computedLongitude(item){
      let coordString = new DmsCoordinates(item.latitude, item.longitude).longitude.toString();
      if(coordString.includes(".")){
        let ss = coordString.split(".")[1].substr(0,2);
        let direction = coordString.split(' ')[1]
        return coordString.split(".")[0] + '.' + ss + '" ' + direction 
      } else {
        return coordString;
      }
    },
    probeConnected(extTempVal){
      return extTempVal <= 130;
    },
    async retrieveNextPage(){
      var key = this.logRows[this.logRows.length-1]['logDate'];

      const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/${this.selectedLogType}/${this.selectedDeviceLogID}`).orderByKey().limitToLast(100).endAt(key).once('value')
      console.log("Device Logs Key: ", deviceLogsSnap.ref.key)
      console.log("Parent Path: ", deviceLogsSnap.ref.parent.parent.key.toString())
      const deviceLogs = deviceLogsSnap.val()
      console.log(deviceLogs)
      // console.log("Device logs: ", deviceLogs)
      this.rawLogData = deviceLogs;

      if(this.selectedDeviceType === 'gps'){
        this.selectedGPSLog = null;
      }

      this.logRows = [];
      this.loggingData = [];
      deviceLogsSnap.forEach(logSnap => {
        const log = logSnap.val();
        // this.selectedDeviceType = log.type;
        log["logDate"] = logSnap.key;
        this.logRows.push(log);
        this.loggingData.push(log)
      })

      this.logRows = this.logRows.reverse();
      this.refreshLineCharts();

      this.page += 1;
    },

    async retrievePreviousPage(){
      var key = this.logRows[0]['logDate'];
      console.log("Previous key: ", key)
      const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/${this.selectedLogType}/${this.selectedDeviceLogID}`).orderByKey().limitToFirst(100).startAt(key).once('value');
      
      console.log("Device Logs Key: ", deviceLogsSnap.ref.key)
      console.log("Parent Path: ", deviceLogsSnap.ref.parent.parent.key.toString())
      const deviceLogs = deviceLogsSnap.val()
      console.log("Device Logs: ", deviceLogs)
      // console.log("Device logs: ", deviceLogs)
      this.rawLogData = deviceLogs;

      if(this.selectedDeviceType === 'gps'){
        this.selectedGPSLog = null;
      }

      this.logRows = [];
      this.loggingData = [];

      deviceLogsSnap.forEach(logSnap => {
        const log = logSnap.val();
        // this.selectedDeviceType = log.type;
        log["logDate"] = logSnap.key;
        this.logRows.push(log);
        this.loggingData.push(log)
      })
      this.logRows = this.logRows.reverse();
      this.refreshLineCharts();
      this.page -= 1;
    },
    zoomOutRecalcActivationChart(){
      if(this.activationZoomIndex == 0) return;
      this.activationZoomIndex -= 1;
      if(this.currentActivationZoom == 'day7' /*&& this.activationChartData.datasets[0].data.length <= 7*/){
              this.activationZoomIndex -= 1;
      }
      const zoomUnit = this.zoomChartUnit[this.currentActivationZoom];
      this.activationChartOptions.title.text = "Actvations Grouped By " + zoomUnit.replace(/^./, zoomUnit[0].toUpperCase())
      if(this.currentActivationZoom == "hour24"){
        
        let summariesForSelectedDay;
        if(this.activationsZoom.hours24Zoom != null){
            const startDate = dayjs(this.activationsZoom.hours24Zoom[0]);
            const endDate = dayjs(this.activationsZoom.hours24Zoom[1]);
            summariesForSelectedDay = this.summary720.activationArray.filter((summaryCount)=>{
            const summaryDate = Object.entries(summaryCount)[0][0];
            return dayjs(summaryDate).isBetween(startDate, endDate, null, "[]")
          })
        } else {
          summariesForSelectedDay = this.summary720.activationArray.slice(-24)
        }
        
        console.log("Selected summaries: ", summariesForSelectedDay)

        const data = summariesForSelectedDay.map((val)=>{
          const dataPoint = Object.entries(val)[0];
          const x = new Date(dataPoint[0]);
          const y = dataPoint[1];
          return {x, y}
        })
        
        this.activationChartOptions.scales.xAxes[0].time.unit = zoomUnit
        this.activationChartData.datasets[0].data = data;
        this.activationsZoom.hours24Zoom = null;
      }

      if(this.currentActivationZoom == "day7"){
        let summariesForSelectedDay;
        const dayCounts = {};

        if(this.activationsZoom.days7Zoom != null){
          console.log("DAYS 7 ZOOM: ", this.activationsZoom.days7Zoom)
          const startDate = dayjs(this.activationsZoom.days7Zoom[0]).startOf('day');
          const endDate = dayjs(this.activationsZoom.days7Zoom[1]).endOf("day");
          
          summariesForSelectedDay = this.summary720.activationArray.filter((summaryCount)=>{
            const summaryDate = Object.entries(summaryCount)[0][0];
            return dayjs(summaryDate).isBetween(startDate, endDate, null, "[]")
          })
        } else {
          summariesForSelectedDay = this.summary720.activationArray;
        }
        
        console.log("Selected summaries: ", summariesForSelectedDay)

        summariesForSelectedDay.forEach((summaryObj)=>{
          const datapoint = Object.entries(summaryObj)
          const dayForLog = dayjs(datapoint[0][0]).startOf("day").toISOString();
          if(dayCounts[dayForLog] == null){
            dayCounts[dayForLog] = datapoint[0][1];
          } else {
            dayCounts[dayForLog] += datapoint[0][1];
          }
        })

        const data = Object.entries(dayCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1]}
        })
        
        this.activationChartOptions.scales.xAxes[0].time.unit = zoomUnit
        this.activationChartData.datasets[0].data = data;
        this.activationsZoom.days7Zoom = null;
        if(this.activationChartData.datasets[0].data.length <= 7){
          this.activationZoomIndex = 0;
        }
      }
      if(this.currentActivationZoom == "day30"){
          const dayCounts = {};
          this.summary720.activationArray.slice(-721).forEach((summaryObj)=>{
          const datapoint = Object.entries(summaryObj)
          // console.log("datapoint: ", datapoint, datapoint[0][0])
          // console.log("Day string: ", datapoint[0][0])
          const day = dayjs(datapoint[0][0]).startOf("day")
          // console.log("Created day object", day)
          const dayForLog = day.toISOString();
          if(dayCounts[dayForLog] == null){
            dayCounts[dayForLog] = datapoint[0][1];
          } else {
            dayCounts[dayForLog] += datapoint[0][1];
          }

        });
        console.log("counts 2:", dayCounts)
        const activationBarData = Object.entries(dayCounts).map((val)=>{
          return {x: new Date(val[0]), y: val[1]}
        })
        this.activationChartOptions.scales.xAxes[0].time.unit = zoomUnit
        this.activationChartData.datasets[0].data = activationBarData;
        this.activationsZoom.days7Zoom = null;
      }
    },
    async zoomInRecalcActivationChart (chartPoint, chartKey) {
          
          if(this.activationZoomIndex == this.zoomLevels.length -1) return;
          if(chartPoint[0] == null) return;
          this.activationZoomIndex += 1;

          if(this.currentActivationZoom == 'day7' /*&& this.activationChartData.datasets[0].data.length <= 7*/){
              this.activationZoomIndex += 1;
          }

          const zoomUnit = this.zoomChartUnit[this.currentActivationZoom];
          this.activationChartOptions.title.text = "Actvations Grouped By " + zoomUnit.replace(/^./, zoomUnit[0].toUpperCase())
          console.log("Received: ", chartPoint, chartKey)
          console.log(chartPoint[0], chartPoint[0]._index)
          console.log(this.activationChartData.datasets[0].data[chartPoint[0]._index])
          console.log("Zooming to: ", this.currentActivationZoom)

          const selectedDatapoint = this.activationChartData.datasets[0].data[chartPoint[0]._index];
          const selectedDate = dayjs(selectedDatapoint.x)
          const selectedIndex = chartPoint[0]._index;
          console.log(selectedDate.toISOString())
          
          if(this.currentActivationZoom == "hour"){
            console.log("Zoom Unit: ", zoomUnit)
            const timeStart = this.timestampToKey(selectedDate.startOf("hour").toISOString())
            const timeEnd = this.timestampToKey(selectedDate.endOf("hour").toISOString());
            const logsForHour = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/devices/${this.$route.params.tileId}`).orderByKey().startAt(timeStart).endAt(timeEnd).once('value');
            console.log(logsForHour.val())

            const dataLength = this.activationChartData.datasets[0].data.length -1

            const startDate = this.activationChartData.datasets[0].data[0].x;
            const endDate = this.activationChartData.datasets[0].data[dataLength].x;
            this.activationsZoom.hours24Zoom = [startDate, endDate];

            /**
             * @type {{[key: string]: Number}}
             */
            const minuteCounts = {};
            logsForHour.forEach((logSnap)=>{
              if(logSnap.val().status === true){
                const minuteForLog = dayjs(logSnap.key).startOf("minute").toISOString();
                if(minuteCounts[minuteForLog] == null){
                  minuteCounts[minuteForLog] = 1;
                } else { 
                  minuteCounts[minuteForLog] += 1;
                }
              }
            });
            const data = Object.entries(minuteCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1]}
            })
            console.log(data)
            this.activationChartOptions.scales.xAxes[0].time.unit = "minute"
            this.activationChartData.datasets[0].data = data;
          }

          if(this.currentActivationZoom == "hour24"){
            // for zoom out:
            // get current week index for range of dates to select
            // find first Index in present day7 chart data, search data for
            // first index where day matches
            // then find last index of last day.
            // for zoomin: get day selected and get all hour points that fall within that day
            const dataLength = this.activationChartData.datasets[0].data.length -1;
            let balanceIndex = dataLength - selectedIndex;
            let startIndex = (balanceIndex - 3);
            let endIndex = (balanceIndex + 3);
            console.log(startIndex)
            if(this.activationChartData.datasets[0].data.length <= 7){
              console.log("Length less than 7")
              startIndex = 0;
              endIndex = dataLength;
            }else {
              if(selectedIndex < 3){
                balanceIndex = 3;
              } else if(balanceIndex > dataLength-3){
                balanceIndex -= (3 - balanceIndex)
              }
              startIndex = (balanceIndex - 3);
              endIndex = (balanceIndex + 3);
            }
            if(startIndex < 0) startIndex = 0;
            if(endIndex > dataLength) endIndex = dataLength;
            console.log("Zoom Unit: ", zoomUnit)
            console.log("24hour mode StartIndex: ", startIndex, "End Index: ", endIndex, "Balance Index: ", balanceIndex)

            const startDate = this.activationChartData.datasets[0].data[startIndex].x;
            const endDate = this.activationChartData.datasets[0].data[endIndex].x;
            this.activationsZoom.days7Zoom = [startDate, endDate];
            console.log("Set zoom cache: ", this.activationsZoom.days7Zoom)
            const summariesForSelectedDay = this.summary720.activationArray.filter((summaryCount)=>{
              const summaryDate = Object.entries(summaryCount)[0][0];
              return selectedDate.isSame(summaryDate, "day");
            })
            console.log("Selected summaries: ", summariesForSelectedDay)

            const data = summariesForSelectedDay.map((val)=>{
              const dataPoint = Object.entries(val)[0];
              const x = new Date(dataPoint[0]);
              const y = dataPoint[1];
              return {x, y}
            })

            this.activationChartOptions.scales.xAxes[0].time.unit = zoomUnit
            this.activationChartData.datasets[0].data = data;

          }
          if(this.currentActivationZoom == "day7"){
            // check index of selected bar
            // see how close it is the the ends of the day chart dataset
            // if within 4 of ends, adjust how you select
            // always try to make the selected element the 4th of 7
            /**
             * @type {{[key: string]: Number}}
             */
            const dataLength = this.activationChartData.datasets[0].data.length -1;
            let balanceIndex = dataLength - selectedIndex;
            let startIndex = (balanceIndex - 3);
            let endIndex = (balanceIndex + 3);
            console.log(startIndex)
            if(this.activationChartData.datasets[0].data.length <= 7){
              console.log("Length less than 7")
              startIndex = 0;
              endIndex = dataLength;
            }else {
              if(selectedIndex < 3){
                balanceIndex = 3;
              } else if(balanceIndex < 3){
                balanceIndex -= (3 - balanceIndex)
              }
              startIndex = (balanceIndex - 3);
              endIndex = (balanceIndex + 3);
            }
            
            console.log("StartIndex: ", startIndex, "End Index: ", endIndex, "Balance Index: ", balanceIndex)
            const startDate = this.activationChartData.datasets[0].data[startIndex].x;
            const endDate = this.activationChartData.datasets[0].data[endIndex].x;
            let summariesForSelectedDay = this.summary720.activationArray.filter((summaryCount)=>{
              const summaryDate = Object.entries(summaryCount)[0][0];
              return dayjs(summaryDate).isBetween(startDate, endDate, null, "[]")
            })
            const dayCounts = {};
            summariesForSelectedDay.forEach((summaryObj)=>{
              const datapoint = Object.entries(summaryObj)
              const dayForLog = dayjs(datapoint[0]).startOf("day").toISOString();
              if(dayCounts[dayForLog] == null){
                dayCounts[dayForLog] = datapoint[0][1];
              } else {
                dayCounts[dayForLog] += datapoint[0][1];
              }

            });
            const data = Object.entries(dayCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1]}
            })
            
            this.activationChartOptions.scales.xAxes[0].time.unit = zoomUnit
            this.activationChartData.datasets[0].data = data;
          }
          if(this.currentActivationZoom == "day30"){
            /**
             * @type {{[key: string]: Number}}
             */
            const dayCounts = {};
            this.summary720.activationArray.slice(-721).forEach((summaryObj)=>{
              const datapoint = Object.entries(summaryObj)
              const dayForLog = dayjs(datapoint[0]).startOf("day").toISOString();
              if(dayCounts[dayForLog] == null){
                dayCounts[dayForLog] = datapoint[0][1];
              } else {
                dayCounts[dayForLog] += datapoint[0][1];
              }

            });
            const data = Object.entries(dayCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1] / 1000}
            })
            
            this.activationChartOptions.scales.xAxes[0].time.unit = zoomUnit
            this.activationChartData.datasets[0].data = data;
          }
          console.log(selectedDate)
    },
    zoomOutRecalcRuntimeChart(){
      if(this.runtimeZoomIndex == 0) return;
      this.runtimeZoomIndex -= 1;
      if(this.currentRuntimeZoom == 'day7' /*&& this.runtimeChartData.datasets[0].data.length <= 7*/){
          this.runtimeZoomIndex -= 1;
      }
      const zoomUnit = this.zoomChartUnit[this.currentRuntimeZoom];
      this.runtimeChartOptions.title.text = "Runtimes Grouped By " + zoomUnit.replace(/^./, zoomUnit[0].toUpperCase())
      if(this.currentRuntimeZoom == "hour24"){
        
        let summariesForSelectedDay;
        if(this.runtimeZoom.hours24Zoom != null){
            const startDate = dayjs(this.runtimeZoom.hours24Zoom[0]);
            const endDate = dayjs(this.runtimeZoom.hours24Zoom[1]);
            summariesForSelectedDay = this.summary720.runtimeArray.filter((summaryCount)=>{
            const summaryDate = Object.entries(summaryCount)[0][0];
            return dayjs(summaryDate).isBetween(startDate, endDate, null, "[]")
          })
        } else {
          summariesForSelectedDay = this.summary720.runtimeArray.slice(-24)
        }
        
        console.log("Selected summaries: ", summariesForSelectedDay)

        const data = summariesForSelectedDay.map((val)=>{
          const dataPoint = Object.entries(val)[0];
          const x = new Date(dataPoint[0]);
          const y = dataPoint[1] / 1000;
          return {x, y}
        })
        
        this.runtimeChartOptions.scales.xAxes[0].time.unit = zoomUnit
        this.runtimeChartData.datasets[0].data = data;
        this.runtimeZoom.hours24Zoom = null;
      }

      if(this.currentRuntimeZoom == "day7"){
        let summariesForSelectedDay;
        const dayCounts = {};

        if(this.runtimeZoom.days7Zoom != null){
          console.log("DAYS 7 ZOOM: ", this.runtimeZoom.days7Zoom)
          const startDate = dayjs(this.runtimeZoom.days7Zoom[0]).startOf('day');
          const endDate = dayjs(this.runtimeZoom.days7Zoom[1]).endOf("day");
          
          summariesForSelectedDay = this.summary720.runtimeArray.filter((summaryCount)=>{
            const summaryDate = Object.entries(summaryCount)[0][0];
            return dayjs(summaryDate).isBetween(startDate, endDate, null, "[]")
          })
        } else {
          summariesForSelectedDay = this.summary720.runtimeArray;
        }
        
        console.log("Selected summaries: ", summariesForSelectedDay)

        summariesForSelectedDay.forEach((summaryObj)=>{
          const datapoint = Object.entries(summaryObj)
          const dayForLog = dayjs(datapoint[0][0]).startOf("day").toISOString();
          if(dayCounts[dayForLog] == null){
            dayCounts[dayForLog] = datapoint[0][1];
          } else {
            dayCounts[dayForLog] += datapoint[0][1];
          }
        })

        const data = Object.entries(dayCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1] / 1000}
        })
        
        this.runtimeChartOptions.scales.xAxes[0].time.unit = zoomUnit
        this.runtimeChartData.datasets[0].data = data;
        this.runtimeZoom.days7Zoom = null;
        if(this.runtimeChartData.datasets[0].data.length <= 7){
          this.runtimeZoomIndex = 0;
        }
      }
      if(this.currentRuntimeZoom == "day30"){
          const dayCounts = {};
          this.summary720.runtimeArray.slice(-721).forEach((summaryObj)=>{
          const datapoint = Object.entries(summaryObj)
          // console.log("datapoint: ", datapoint, datapoint[0][0])
          // console.log("Day string: ", datapoint[0][0])
          const day = dayjs(datapoint[0][0]).startOf("day")
          // console.log("Created day object", day)
          const dayForLog = day.toISOString();
          if(dayCounts[dayForLog] == null){
            dayCounts[dayForLog] = datapoint[0][1];
          } else {
            dayCounts[dayForLog] += datapoint[0][1];
          }

        });
        console.log("counts 2:", dayCounts)
        const runtimeBarData = Object.entries(dayCounts).map((val)=>{
          return {x: new Date(val[0]), y: val[1] / 1000}
        })
        this.runtimeChartOptions.scales.xAxes[0].time.unit = zoomUnit
        this.runtimeChartData.datasets[0].data = runtimeBarData;
        this.runtimeZoom.days7Zoom = null;
      }
      console.log(this.runtimeZoomIndex)
    },
    async zoomInRecalcRuntimeChart (chartPoint, chartKey) {
          
          if(this.runtimeZoomIndex == this.zoomLevels.length -1) return;
          if(chartPoint[0] == null) return;
          this.runtimeZoomIndex += 1;

          if(this.currentRuntimeZoom == 'day7' /*&& this.runtimeChartData.datasets[0].data.length <= 7*/){
              this.runtimeZoomIndex += 1;
          }

          const zoomUnit = this.zoomChartUnit[this.currentRuntimeZoom];
          this.runtimeChartOptions.title.text = "Runtime Grouped By " + zoomUnit.replace(/^./, zoomUnit[0].toUpperCase())
          console.log("Received: ", chartPoint, chartKey)
          console.log(chartPoint[0], chartPoint[0]._index)
          console.log(this.runtimeChartData.datasets[0].data[chartPoint[0]._index])
          console.log("Zooming to: ", this.currentRuntimeZoom)

          const selectedDatapoint = this.runtimeChartData.datasets[0].data[chartPoint[0]._index];
          const selectedDate = dayjs(selectedDatapoint.x)
          const selectedIndex = chartPoint[0]._index;
          console.log(selectedDate.toISOString())
          
          if(this.currentRuntimeZoom == "hour"){
            console.log("Zoom Unit: ", zoomUnit)
            const timeStart = this.timestampToKey(selectedDate.startOf("hour").toISOString())
            const timeEnd = this.timestampToKey(selectedDate.endOf("hour").toISOString());
            const logsForHour = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/devices/${this.$route.params.tileId}`).orderByKey().startAt(timeStart).endAt(timeEnd).once('value');
            console.log(logsForHour.val())

            const dataLength = this.runtimeChartData.datasets[0].data.length -1

            const startDate = this.runtimeChartData.datasets[0].data[0].x;
            const endDate = this.runtimeChartData.datasets[0].data[dataLength].x;
            this.runtimeZoom.hours24Zoom = [startDate, endDate];

            /**
             * @type {{[key: string]: Number}}
             */
            const minuteCounts = {};

            logsForHour.forEach((logSnap)=>{
              const logData = logSnap.val()
              if(logData.status === true){
                const minuteForLog = dayjs(logSnap.key).startOf("minute").toISOString();
                if(minuteCounts[minuteForLog] == null){
                  if(logData.activityStartTime && logData.activityEndTime) {
                    const runtime = Math.abs(logData.activityEndTime - logData.activityStartTime);
                    if(!isNaN(runtime)){
                      minuteCounts[minuteForLog] = runtime;
                    }
                  }
                  
                } else { 
                  if(logData.activityStartTime && logData.activityEndTime) {
                    const runtime = Math.abs(logData.activityEndTime - logData.activityStartTime);
                    if(!isNaN(runtime)){
                      minuteCounts[minuteForLog] += runtime;
                    }
                  }
                }
              }
            });
            const data = Object.entries(minuteCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1] / 1000}
            })
            console.log(data)
            this.runtimeChartOptions.scales.xAxes[0].time.unit = "minute"
            this.runtimeChartData.datasets[0].data = data;
          }

          if(this.currentRuntimeZoom == "hour24"){
            // for zoom out:
            // get current week index for range of dates to select
            // find first Index in present day7 chart data, search data for
            // first index where day matches
            // then find last index of last day.
            // for zoomin: get day selected and get all hour points that fall within that day
            const dataLength = this.runtimeChartData.datasets[0].data.length -1;
            let balanceIndex = dataLength - selectedIndex;
            let startIndex = (balanceIndex - 3);
            let endIndex = (balanceIndex + 3);
            console.log(startIndex)
            if(this.activationChartData.datasets[0].data.length <= 7){
              console.log("Length less than 7")
              startIndex = 0;
              endIndex = dataLength;
            }else {
              if(selectedIndex < 3){
                balanceIndex = 3;
              } else if(balanceIndex > dataLength-3){
                balanceIndex -= (3 - balanceIndex)
              }
              startIndex = (balanceIndex - 3);
              endIndex = (balanceIndex + 3);
            }
            if(startIndex < 0) startIndex = 0;
            if(endIndex > dataLength) endIndex = dataLength;
            
            
            const startDate = this.runtimeChartData.datasets[0].data[startIndex].x;
            const endDate = this.runtimeChartData.datasets[0].data[endIndex].x;
            this.runtimeZoom.days7Zoom = [startDate, endDate];
            console.log("Set zoom cache: ", this.runtimeZoom.days7Zoom)
            const summariesForSelectedDay = this.summary720.runtimeArray.filter((summaryCount)=>{
              const summaryDate = Object.entries(summaryCount)[0][0];
              return selectedDate.isSame(summaryDate, "day");
            })
            // console.log("Selected summaries: ", summariesForSelectedDay)

            const data = summariesForSelectedDay.map((val)=>{
              const dataPoint = Object.entries(val)[0];
              const x = new Date(dataPoint[0]);
              const y = dataPoint[1] / 1000;
              return {x, y}
            })
            console.log("Hour runtime data: ", data)
            this.runtimeChartOptions.scales.xAxes[0].time.unit = zoomUnit
            this.runtimeChartData.datasets[0].data = data;
          }
          if(this.currentRuntimeZoom == "day7"){
            // check index of selected bar
            // see how close it is the the ends of the day chart dataset
            // if within 4 of ends, adjust how you select
            // always try to make the selected element the 4th of 7
            /**
             * @type {{[key: string]: Number}}
             */
            const dataLength = this.runtimeChartData.datasets[0].data.length -1;
            let balanceIndex = dataLength - selectedIndex;
            let startIndex = (balanceIndex - 3);
            let endIndex = (balanceIndex + 3);
            console.log(startIndex)
            if(this.runtimeChartData.datasets[0].data.length <= 7){
              console.log("Length less than 7")
              startIndex = 0;
              endIndex = dataLength;
            }else {
              if(selectedIndex < 3){
                balanceIndex = 3;
              } else if(balanceIndex < 3){
                balanceIndex -= (3 - balanceIndex)
              }
              startIndex = (balanceIndex - 3);
              endIndex = (balanceIndex + 3);
            }
            
            
            const startDate = this.runtimeChartData.datasets[0].data[startIndex].x;
            const endDate = this.runtimeChartData.datasets[0].data[endIndex].x;
            let summariesForSelectedDay = this.summary720.runtimeChartData.filter((summaryCount)=>{
              const summaryDate = Object.entries(summaryCount)[0][0];
              return dayjs(summaryDate).isBetween(startDate, endDate, null, "[]")
            })
            const dayCounts = {};
            summariesForSelectedDay.forEach((summaryObj)=>{
              const datapoint = Object.entries(summaryObj)
              const dayForLog = dayjs(datapoint[0]).startOf("day").toISOString();
              if(dayCounts[dayForLog] == null){
                dayCounts[dayForLog] = datapoint[0][1];
              } else {
                dayCounts[dayForLog] += datapoint[0][1];
              }

            });
            const data = Object.entries(dayCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1] / 1000}
            })
            
            this.runtimeChartOptions.scales.xAxes[0].time.unit = zoomUnit
            this.runtimeChartData.datasets[0].data = data;
          }
          if(this.currentRuntimeZoom == "day30"){
            /**
             * @type {{[key: string]: Number}}
             */
            const dayCounts = {};
            this.summary720.runtimeArray.slice(-721).forEach((summaryObj)=>{
              const datapoint = Object.entries(summaryObj)
              const dayForLog = dayjs(datapoint[0]).startOf("day").toISOString();
              if(dayCounts[dayForLog] == null){
                dayCounts[dayForLog] = datapoint[0][1];
              } else {
                dayCounts[dayForLog] += datapoint[0][1];
              }

            });
            const data = Object.entries(dayCounts).map((val)=>{
              return {x: new Date(val[0]), y: val[1]}
            })
            
            this.runtimeChartOptions.scales.xAxes[0].time.unit = zoomUnit
            this.runtimeChartData.datasets[0].data = data;
          }
          console.log(selectedDate)
    },
    updateDataBinding(logPaths = {}) {
      this.watchPaths = {};
      // this.watchPaths['tile'] = `/vessels/${this.$route.params.vesselId}/tiles/${this.$route.params.tileId}`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;
      // this.watchPaths['alarms'] = `/vessels/${this.$route.params.vesselId}/alarms`;
      // this.watchPaths['aggregations'] = `/vessels_aggregation/${this.$route.params.vesselId}/tiles/${this.$route.params.tileId}/logTotals/perday`;

      for (let key in logPaths)
        this.watchPaths[key] = logPaths[key];
      this.rawLogData = {};

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        console.log("Path: ", path)
        console.log("Watched:",response.val())
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.lastKeyReceived = name;
        this.getData();
      });
    },
    /**
     * @param {Array} array
     * @param {Number} sliceSize
     * @returns {Number}
     */
    arraySliceSum(array, sliceSize){
      if(!array){
        return 0;
      }
      let sum = 0;
      const currentMoment = dayjs();
      /* eslint-disable no-unused-vars */
      const totalHoursBack = Math.abs(sliceSize)
      const sliceStartTime = currentMoment.subtract(totalHoursBack + 1, "hour");
      // console.log(`Checking if dates are between ${sliceStartTime.toDate()} and ${currentMoment.toDate()}; Slice size: ${sliceSize}`)
        array.slice(sliceSize).filter((val)=>{
          if(!val){
            return false;
          }
          const summaryDate = Object.keys(val)[0];
          // console.log("Summary Date", summaryDate);
          // console.log(`Checking if dates are between ${sliceStartTime.toDate()} and ${currentMoment.toDate()}`)
          if(dayjs(summaryDate).isBetween(sliceStartTime, currentMoment, null, "[]")){
            return true;
          } else {
            return false;
          }

      }).forEach((objVal)=>{
        const hourTotal = Object.values(objVal)[0];
        sum += hourTotal;
      })
      return sum;
    },
    selectedAlarms(item) {
      let map = item.alarms;
      console.log("ALARMS: ", map)
      let r = {};
      for (let i in map)
        if (map[i].tile === this.selectedAlarm.tile &&
          map[i].title === this.selectedAlarm.title)
          r[i] = map[i]
      return r;
    },
    paginate (array, page_size, page_number) {
      return array.slice(page_number * page_size, page_number * page_size + page_size);
    },
    nextPage(){
      this.page += 1;
    },
    previousPage(){
      this.page -= 1;
    },

    isPumpControl() {
      return this.tile.parentName.includes('BGWS-01')
    },
    isLoadControl() {
      return this.tile.parentName.includes('BGWS-04')
    },
    vesselIdWatcher() {
      console.log("Vessel id watcher")
    //   firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/meta`).once('value').then(res => {
    //       const data = res.val()
    //       console.log("Vessel Meta: ", data)
    //       this.ownerID = data.ownerID;
    // })
      this.updateDataBinding();
    },
    tileStatus(isActive) {
      if(this.selectedAlarm.alarmType === 7){
        if (isActive == null)
        return 'Back in Area';
        if (isActive)
          return 'Out of Area';
        return 'Back in Area';
      }
      if(this.selectedDeviceType === 'loadControl'){
        if (isActive == null)
          return this.selectedDeviceDisplayTitle +  ' turned OFF';
        if (isActive)
          return this.selectedDeviceDisplayTitle + ' turned ON';
        return this.selectedDeviceDisplayTitle +  ' turned OFF';
        }
      if (isActive == null)
        return this.selectedDeviceDisplayTitle +  ' OFF';
      if (isActive)
        return this.selectedDeviceDisplayTitle + ' ON';
      return this.selectedDeviceDisplayTitle +  ' OFF';
    },
    async handleProcessLog() {
      let vesselId = this.$route.params.vesselId;
      this.processButtonText = 'Processing...'

      let promises = [];
      try {
        this.alarms.forEach(alarm => {
          promises.push(axios.post('/processtilelog', {
            vesselId,
            alarmId: alarm.id,
            ownerId: this.meta.ownerID,
            alarmType: alarm.alarmType
          }));
        });

        await Promise.all(promises);
      } catch (err) {
        console.log(err);
      }

      this.processButtonText = 'Process Log';

      return null;
    },
    runTime(startTime, endTime, table = false) {
      if(startTime == null || endTime == null) return ""
      let diff = endTime - startTime;
      if(isNaN(diff)){ return ""}
      diff = Math.abs(diff);
      // console.log(diff)
      const diffDuration = dayjs.duration(diff)
      if(diff < 86400000 && !table){
        return diffDuration.format("HH:mm:ss")
      } else {
        if(table){
          return this.strTimeDelta(diff)
        } else{
          return diffDuration.humanize()
        }
        
      }
      // return this.$moment.duration(diff).asSeconds() + " Seconds"
    },
    timestampToKey(timestampString) {
      return timestampString.split('.')[0]+"Z"
    },
    async testLogCalc(){
    dayjs.extend(utc)
    dayjs.extend(timezone)
    let dayObject = dayjs();
    dayObject = dayObject.subtract(1, 'hour');
    const startMilliseconds = dayObject.startOf('hour').valueOf();
    const endMilliseconds = dayObject.endOf('hour').valueOf();
    const startTimestamp = this.timestampToKey(dayObject.startOf('hour').toISOString())
    // eslint-disable-next-line no-unused-vars
    const endTimestamp = this.timestampToKey(dayObject.endOf('hour').toISOString())

    const allVesselsSnap = await firebaseApp.database().ref('vessels').once('value')

    /**
     * @type {{ownerID,vesselID,deviceName}[]}
     */
    const vesselAlarmsAndDevices = [];
    allVesselsSnap.forEach((vesselSnap) =>{
        const vesselID = vesselSnap.key;
        const vesselData = vesselSnap.val();

        const ownerID = vesselData.meta.ownerID
        for(let deviceID in vesselData.tiles){
            const device = vesselData.tiles[deviceID];
            if(device != null && device.type === 'bilge' && (device.tileType === 1)){
                const deviceName = deviceID;
                const deviceLogPathObj = {
                    ownerID,
                    vesselID,
                    deviceName
                }
                vesselAlarmsAndDevices.push(deviceLogPathObj)
            }
        }
    })
    console.log("Alarms and devices: ",vesselAlarmsAndDevices)
    const devicePromises = vesselAlarmsAndDevices.map((logPathObj) => {
        const deviceRefPath = `logging/${logPathObj.ownerID}/vessels/${logPathObj.vesselID}/devices/${logPathObj.deviceName}`;
        return firebaseApp.database().ref(deviceRefPath).orderByChild("activityEndTime").startAt(startMilliseconds).endAt(endMilliseconds).once('value');
    })  

    // only bilges

    /**
    * @type {{ [vesselID: string]: {
    *           activationCount: Number,
    *           hourRuntimeTotal: Number
    *       }
    * }}
    */
    const vesselLogCounts = {};
    const allDeviceLogs = await Promise.all(devicePromises);
    const allDeviceHourlySnaps = allDeviceLogs.map((hourlyDeviceLogListSnap) =>{
        const deviceName = hourlyDeviceLogListSnap.ref.key;
        const vesselID = hourlyDeviceLogListSnap.ref.parent.parent.key;
        console.log("Device log parent name: ", deviceName)
        console.log("Device Log Data: ", JSON.stringify(hourlyDeviceLogListSnap.val()))
        console.log("Path: ", hourlyDeviceLogListSnap.ref.toString())
        let logCount = 0;
        let hourRuntimeTotal = 0;
        hourlyDeviceLogListSnap.forEach((logSnap)=>{
            const logData = logSnap.val();
            console.log ("LogData: ", JSON.stringify(logData))
            if(logData.status === true){
                console.log("PUMP WAS ON")
                logCount += 1;
                if(logData.activityStartTime && logData.activityEndTime) {
                    console.log("PUMP HAD START AND END TIMES")
                    const runtime = Math.abs(logData.activityEndTime - logData.activityStartTime);
                    if(!isNaN(runtime)){
                        hourRuntimeTotal += runtime;
                    }
                }
            }
        })

        // need to add 0 counts for if no logs are retrieved.
        if(logCount > 0){
          const o = {
            [deviceName]: {
              activationCount: logCount,
              hourRuntimeTotal
            }
            
        }
          console.log("Log count greater than 0! Setting this object: ", o)

        }
        if(vesselLogCounts[vesselID] == null){
          vesselLogCounts[vesselID] = {};
        }
        vesselLogCounts[vesselID][deviceName] = {
              activationCount: logCount,
              hourRuntimeTotal
        }
        console.log("Vessel Log Counts part 1: ", vesselLogCounts)
        console.log("Activation Count: ", logCount)
        // now pull the logSummary for this device
        // check if exists
        // if not, initialize it
        // it it does, check the hourly array
        // if length < 720, push the logCount and move on.
        // if length >= 720, push then shift().
        const hourlyLogSummaryRef = hourlyDeviceLogListSnap.ref.parent.parent.child("deviceLogSummaries").child(deviceName).child("720HourSummaries");
        return hourlyLogSummaryRef.once("value");
    });
    // eslint-disable-next-line no-unused-vars
    vesselAlarmsAndDevices.forEach((val)=>{
      if(vesselLogCounts[val.vesselID] == null || vesselLogCounts[val.vesselID][val.deviceName] == null){
        if(vesselLogCounts[val.vesselID] == null) {
          vesselLogCounts[val.vesselID] = {};
        }
        const zeroUpdate = {
              activationCount: 0,
              hourRuntimeTotal: 0
        }
        vesselLogCounts[val.vesselID][val.deviceName] = zeroUpdate;
      }
    })
    const hourlySnapList = await Promise.all(allDeviceHourlySnaps);

    let logSummaryUpdates = [];
    let dailySummaryUpdates = [];
    let allTimeUpdates = [];
    
    console.log("Vessel log counts: ", vesselLogCounts)
    hourlySnapList.forEach(async (logSummarySnap)=>{
        // console.log("Log summary snap:", JSON.stringify(logSummarySnap.val()))
        // console.log("Parent Parent Key: ", logSummarySnap.ref.parent.parent.key)
        // console.log("Parent Key: ", logSummarySnap.ref.parent.key)
        const deviceName = logSummarySnap.ref.parent.key;
        const vesselID = logSummarySnap.ref.parent.parent.parent.key;
        dayjs.extend(utc)
        dayjs.extend(timezone)
        if(logSummarySnap.exists()){
            /**
             * @type {{activationArray: Number[],runtimeArray: Number[]}}
             */
            const logSummary = logSummarySnap.val();
            console.log("Retrieved summary: ", logSummary)
            try{
              console.warn("getting last log")
              const lastActLog = logSummary.activationArray[logSummary.activationArray.length-1];
              console.warn("Retrieved")
              console.warn("Retrieving last log TS")

              if(lastActLog != null && typeof lastActLog === "object"){
                if(Object.keys(lastActLog)[0] === startTimestamp){
                  console.warn("Last hour duplicate issue.")
                  logSummary.activationArray.pop();
                } else {
                  console.warn("Hour schedule is fine")
                  allTimeUpdates.push(logSummarySnap.ref.parent.child("allTimeSummaries").child("activations").set(serverValue.increment(vesselLogCounts[vesselID][deviceName].activationCount)))
                }
              } else {
                console.log("Last log is okay: ", lastActLog, startTimestamp)
              }
            

              console.log("Pushing activations object", {[startTimestamp]: vesselLogCounts[vesselID][deviceName].activationCount})
              logSummary.activationArray.push({[startTimestamp]: vesselLogCounts[vesselID][deviceName].activationCount})
              if(logSummary.activationArray.length > 720) {
                  logSummary.activationArray.shift();
              }

              const lastRuntimeLog = logSummary.runtimeArray[logSummary.runtimeArray.length-1];
              if(lastRuntimeLog != null && typeof lastRuntimeLog === "object"){
                if(Object.keys(lastRuntimeLog)[0] === startTimestamp){
                  console.warn("Last hour duplicate issue.")
                  logSummary.runtimeArray.pop();
                } else {
                  console.log("Runtime schedule okay.")
                  allTimeUpdates.push(logSummarySnap.ref.parent.child("allTimeSummaries").child("runtime").set(serverValue.increment(vesselLogCounts[vesselID][deviceName].hourRuntimeTotal)))
                }
              }
              console.log("Pushing runtime object", {[startTimestamp]: vesselLogCounts[vesselID][deviceName].hourRuntimeTotal})
              logSummary.runtimeArray.push({[startTimestamp]: vesselLogCounts[vesselID][deviceName].hourRuntimeTotal})

              if(logSummary.runtimeArray.length > 720) {
                  logSummary.runtimeArray.shift();
              }
            } catch(error){
                console.log("Failed to retrieve data for device ", deviceName, "Vessel ", vesselID, "Error ",error)
            }
            logSummaryUpdates.push(logSummarySnap.ref.set(logSummary))

            // console.log("Summary update: ",logSummary)

            if(dayObject.tz("America/Los_Angeles").hour() === 0){
              const key = dayObject().tz("America/Los_Angeles").format("YYYY-MM-DD")
              let dailySummary = {
                dailyActivations: 0,
                dailyRuntime: 0
              }
              
              logSummary.activationArray.slice(-24).forEach((val) =>{
                const count = Object.values(val)[0].activationCount
                if(!isNaN(count)){
                  dailySummary.dailyActivations += count;
                }
              })

              logSummary.runtimeArray.slice(-24).forEach((val) =>{
                const runtimeCount = Object.values(val)[0].hourRuntimeTotal
                if(!isNaN(runtimeCount)){
                  dailySummary.dailyRuntime += runtimeCount;
                }
              })
              dailySummaryUpdates.push(logSummarySnap.ref.parent.child("dailySummaries").child(key).set(dailySummary))

            }
        } else {
            //
            // eslint-disable-next-line no-unused-vars
            try{
            const initialLogSummary = {
                activationArray: [{[startTimestamp]: vesselLogCounts[vesselID][deviceName].activationCount} ],
                runtimeArray: [{[startTimestamp]: vesselLogCounts[vesselID][deviceName].hourRuntimeTotal}]
            }
            logSummaryUpdates.push(logSummarySnap.ref.set(initialLogSummary))
            console.log("Initial update",initialLogSummary)

            allTimeUpdates.push(logSummarySnap.ref.parent.child("allTimeSummaries").child("activations").set(serverValue.increment(vesselLogCounts[vesselID][deviceName].activationCount)))
            allTimeUpdates.push(logSummarySnap.ref.parent.child("allTimeSummaries").child("runtime").set(serverValue.increment(vesselLogCounts[vesselID][deviceName].hourRuntimeTotal)))
            
            if(dayObject.tz("America/Los_Angeles").hour() === 0){
              const key = dayObject().tz("America/Los_Angeles").format("YYYY-MM-DD")
              let dailySummary = {
                dailyActivations: vesselLogCounts[vesselID][deviceName].activationCount,
                dailyRuntime: vesselLogCounts[vesselID][deviceName].hourRuntimeTotal
              }
              dailySummaryUpdates.push(logSummarySnap.ref.parent.child("dailySummaries").child(key).set(dailySummary))
            }

            }
            catch(error){
              console.log("Failed on ", deviceName)
            } 
        }
    })
    await Promise.all(logSummaryUpdates);
    await Promise.all(dailySummaryUpdates);
    await Promise.all(allTimeUpdates)
    },
    dateString(date) {
      let d = new Date(date);
      let dstr = d.toLocaleDateString(undefined);
      let tstr = d.toLocaleTimeString();
      return dstr + ' ' + tstr;
    },
    hex64(val) {
      var hex = val.toString(16).toUpperCase();
      return ("0000000000000000" + hex).slice(-16);
    },
    numberToArrayBuffer(value) {
      const view = new DataView(new ArrayBuffer(64))
      for (var index = 8; index >= 0; --index) {
        view.setUint8(index, value % 256)
        value = value >> 8;
      }
      return view.buffer
    },
    convertBytesToFloat32(data) {
      var buf = new ArrayBuffer(4);
      var view = new DataView(buf);
      data.forEach(function(b, i) {
        view.setUint8(i, b);
      });
      return view.getFloat32(0);
    },
    splitValueIntoGPS(value) {
      return this.hex64(value);
    },
    convertNMEAToCoordinate(lat, lon) {
      let latWholePart = Math.floor(lat)
      let latDegrees = Math.floor(lat / 100)
      let latMinutes = Math.floor((lat - (Number(latDegrees) * 100)))
      let latSeconds = (lat - Number(latWholePart)) * 60
      let latSign = lat >= 0 ? 1 : -1
      let lonWholePart = Math.floor(lon)
      let lonDegrees = Math.floor(lon / 100)
      let lonMinutes = Math.floor((lon - (Number(lonDegrees) * 100)))
      let lonSeconds = (lon - Number(lonWholePart)) * 60
      let lonSign = lat >= 0 ? 1 : -1

      let l = {
        degrees: latDegrees,
        minutes: latMinutes,
        seconds: latSeconds,
        direction: latSign
      };
      let g = {
        degrees: lonDegrees,
        minutes: lonMinutes,
        seconds: lonSeconds,
        direction: lonSign
      };

      return {
        lat: l,
        lon: g
      }
    },
    parseFloat(str, radix) {
      var parts = str.split(".");
      if (parts.length > 1) {
        return parseInt(parts[0], radix) + parseInt(parts[1], radix) / Math.pow(radix, parts[1].length);
      }
      return parseInt(parts[0], radix);
    },
    str(stringToBe) {
      if (stringToBe === undefined || stringToBe === null)
        return "";
      return stringToBe.toString();
    },
    strTimeDelta(timeInMs) {
      if (timeInMs === undefined || timeInMs === null)
        return "0s";
      let ms = Number(timeInMs);
      if (!ms)
        return "0s";

      ms = ms / 1000;
      let sec = Math.round(ms % 60);
      let min = Math.floor((ms % 3600) / 60);
      let hr = Math.floor(ms / 3600);
      let result = sec + 's';
      if (min || hr)
        result = min + 'm ' + result;
      if (hr)
        result = hr + 'h ' + result;
      return result;
    },
    getData() {
      this.meta = this.fireData['meta'];
      // this.tile = this.fireData['tile'];
      this.alarmsData = this.fireData['alarms'];
      this.aggregations = this.fireData['aggregations'];
      if (!this.meta || !this.tile || !this.aggregations || !this.alarmsData)
        return;
      console.log("DETAIL TILE: ", this.tile)
      if (this.tile.alarms)
        this.alarms = this.tile.alarms;
      else
        this.alarms = [];

      // if (this.aggregations.activations)
      //   this.activations = this.aggregations.sums;
      // else
      //   this.activations = {};


      if (this.alarms) {
        this.alarms.forEach(alarm => {
          if (alarm.id === this.lastKeyReceived) {
            let data = this.fireData[alarm.id];
            for (let key in data) {
              data[key].parentAlarmId = alarm.id;
              console.log("Alarm ID data", key, alarm.id, data[key])
              this.rawLogData[key] = data[key];
            }
          }
        });
        this.updateLogDisplayData();
      }

      let runTimeKey = null;
      let activationsKey = null;
      for (let key in this.tile.alarms) {
        let alarm = this.tile.alarms[key];
        if (alarm.alarmType === 5) {
          runTimeKey = alarm.id;
        }
        let actTypes = ["2", "3", "4", "6", "8"];
        if (actTypes.indexOf(alarm.alarmType.toString())) {
          activationsKey = alarm.id;
        }
      }


      this.runTimeSums = {};
      if (runTimeKey) {
        let totals = this.aggregations[runTimeKey];
        if (!totals) {
          totals = {
            allTime: 0
          }
        }
        let day30Test = new Date((new Date() - (1000 * 60 * 60 * 24 * 30))).toISOString().substr(0, 10);
        let day7Test = new Date((new Date() - (1000 * 60 * 60 * 24 * 7))).toISOString().substr(0, 10);
        let todayTest = new Date().toISOString().substr(0, 10);

        let allTime = totals.allTime;
        if (!allTime)
          allTime = 0;
        this.runTimeSums = {
          lasthour: 0,
          last24: 0,
          day7: 0,
          day30: 0,
          allTime
        }

        for (let t in totals) {
          if (t === todayTest)
            continue;
          if (t >= day7Test) {
            this.runTimeSums.day7 += totals[t];
          }
          if (t >= day30Test) {
            this.runTimeSums.day30 += totals[t];
          }
        }

        let last24Test = new Date((new Date() - (1000 * 60 * 60 * 24))).toISOString();
        let lastHourTest = new Date((new Date() - (1000 * 60 * 60 * 1))).toISOString();
        for (let dateKey in this.rawLogData) {

          let log = this.rawLogData[dateKey];
          if (log.runTime && log.parentAlarmId === runTimeKey) {
            if (dateKey >= last24Test)
              this.runTimeSums.last24 += log.runTime;
            if (dateKey >= lastHourTest)
              this.runTimeSums.lasthour += log.runTime;
          }
        }
      }

      this.activationSums = {};
      if (activationsKey) {
        let totals = this.aggregations[activationsKey];
        if (!totals) {
          totals = {
            allTime: 0
          }
        }

        let day30Test = new Date((new Date() - (1000 * 60 * 60 * 24 * 30))).toISOString().substr(0, 10);
        let day7Test = new Date((new Date() - (1000 * 60 * 60 * 24 * 7))).toISOString().substr(0, 10);
        let todayTest = new Date().toISOString().substr(0, 10);

        let allTime = totals.allTime;
        if (!allTime)
          allTime = 0;
        this.activationSums = {
          lasthour: 0,
          last24: 0,
          day7: 0,
          day30: 0,
          allTime
        }


        for (let t in totals) {
          if (t === todayTest)
            continue;
          if (t >= day7Test) {
            this.activationSums.day7 += totals[t];
          }
          if (t >= day30Test) {
            this.activationSums.day30 += totals[t];
          }
        }

        let last24Test = new Date((new Date() - (1000 * 60 * 60 * 24))).toISOString();
        let lastHourTest = new Date((new Date() - (1000 * 60 * 60 * 1))).toISOString();
        for (let dateKey in this.rawLogData) {
          let log = this.rawLogData[dateKey];
          if (log.isActive && log.parentAlarmId === activationsKey) {
            if (dateKey >= last24Test)
              this.activationSums.last24++;
            if (dateKey >= lastHourTest)
              this.activationSums.lasthour++;
          }
        }
      }
      console.log('tile data', this.tile);

      this.viewTitle = this.tile.name;
      this.tileId = this.fireDataIds['tile'];

      //add log queries
      if (!this.ownerID) {
        this.ownerID = this.meta.ownerID;
        this.parentName = this.tile.parentName;
        let paths = {};
        // this.alarms.forEach(alarm => {
        //   let path = `/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/alarms/${alarm.id}`;
        //   paths[alarm.id] = path;
        // });
        console.log("OWNER ID: ", this.meta.ownerID)
        this.updateDataBinding(paths);
      }
      console.log("OWNER ID: ", this.meta.ownerID)

    },
    async updateLogDisplayData(evt) {
      // Pull All time, 30 day, 7 day counts, 24 hour, and last hour counts
      // Add count of any logs from the start of the hour.

      // On every new log, add to all time count
      // Every hour, sum last hour count and "last 24 hour" counts
      // Every Day, sum 7 day and 30 day count
      // When device turned on, add to activation counts.
      // When turned off, add to total runtime counts

      // On the running 24 hour counts, when updating each hour, keep an array of 24 slots
      // Retrieve it, then shift() it and then push the last hour's count

      this.selectedDeviceType = ''
      const selectedAlarm = this.combinedAlarms[evt.target.value];
      console.log(this.combinedAlarms[evt.target.value])
      const timeOfAction = new Date();
      timeOfAction.setDate(timeOfAction.getDate() - 7);
      // let timeOfActionString = timeOfAction.toISOString().split('.')[0]+"Z";
      if(selectedAlarm.alarmType == -1){
        //get device logs
        this.loadingLogs = true;
        this.selectedLogType = 'devices'
        this.selectedDeviceLogID = this.$route.params.tileId
        console.log("Checking device path: ", `/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/devices/${this.$route.params.tileId}`)
        const deviceLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/devices/${this.$route.params.tileId}`).orderByKey().limitToLast(100).once('value')
        console.log("Device Logs Key: ", deviceLogsSnap.ref.key)
        console.log("Parent Path: ", deviceLogsSnap.ref.parent.parent.key.toString())
        const deviceLogs = deviceLogsSnap.val()
        console.log(deviceLogs)
        // console.log("Device logs: ", deviceLogs)
        this.rawLogData = deviceLogs;
        this.logRows = [];
        this.loggingData = [];

        deviceLogsSnap.forEach(logSnap => {
          const log = logSnap.val();
          this.selectedDeviceType = log.type;
          this.selectedDeviceDisplayTitle = log.title || log.name;
          log["logDate"] = logSnap.key;
          this.logRows.push(log);
          this.loggingData.push(log)
        })
        if(this.selectedDeviceType == 'bilge'){
          let chartData = this.loggingData.map((log) =>{
            let status;
            if(log.status == null){ status = 0}
            else if(log.status === false) { status = 0 }
            else if(log.status === true) { status = 1}
            const chartPoint = {x: new Date(log.logDate), y: status, label: `Duration: ${log.duration}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartData, 
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED"
              }
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "Pump Status vs Time"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: false
            },
            tooltips: {
            callbacks: {
                label: (item) => {
                  console.log("", item)
                  const log = this.loggingData[item.index]
                  return "Duration: " + this.runTime(log.activityStartTime, log.activityEndTime) + ` \nStatus: ${this.tileStatus(log.status || log.isActive)}`
                }
              }
            },
            elements: {
              line: {
                stepped: true,
                fill: false
              }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [{
                  scaleLabel: {labelString: "Pump Status", display: true},
                  ticks: {
                    stepSize: 1
                  }
                }]
            }
           }
        } else if(this.selectedDeviceType === 'battery'){
          // eslint-disable-next-line no-unused-vars
          let chartDataVoltage = this.loggingData.map((log) =>{
            let voltage;
            if(log.voltage == null || isNaN(log.voltage)){ voltage = 0}
            else {voltage = log.voltage}
            const chartPoint = {x: new Date(log.logDate), y: voltage.toFixed(1), label: `Voltage: ${voltage}`}
            return chartPoint;
          });
          let chartDataCurrent = this.loggingData.map((log) =>{
            let current;
            if(log.current == null){ current = 0}
            else {current = log.current}
            const chartPoint = {x: new Date(log.logDate), y: current, label: `Current: ${current}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartDataVoltage,
                lineTension: 0,
                fill: false, 
                backgroundColor: '#588AED', 
                borderColor: "#588AED", 
                label: "Voltage (Vdc)",
                yAxisID: 'A',
              },
              {
                data: chartDataCurrent, 
                lineTension: 0,
                fill: false, 
                backgroundColor: '#D85140', 
                borderColor: "#D85140", 
                label: "Current (A)",
                yAxisID: 'B',
              },
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "Battery Voltage and Battery Current"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: true
            },
            tooltips: {
              callbacks: {
 
              }
            },
            elements: {

            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Voltage and Current over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [
                  {
                    id: "A",
                    position: "left",
                    scaleLabel: {labelString: "Voltage (Vdc)", display: true},
                    ticks: {
                      // beginAtZero: false,
                      suggestedMin: 9,
                      suggestedMax: 14
                    },
                  },
                  {
                    id: "B",
                    position: "right",
                    title: {text: "On / Off"},
                    scaleLabel: {labelString: "Current (A)", display: true},
                    ticks: {
                      suggestedMin: -1,
                      suggestedMax: 1
                    },
                  },
                ]
            }
           }
        } else if(this.selectedDeviceType == 'loadControl'){
          let chartData = this.loggingData.map((log) =>{
            let status;
            if(log.status == null){ status = 0}
            else if(log.status === false) { status = 0 }
            else if(log.status === true) { status = 1}
            const chartPoint = {x: new Date(log.logDate), y: status, label: `Duration: ${log.duration}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartData, 
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED"
              }
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "Status vs Time"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: false
            },
            tooltips: {
            callbacks: {
                label: (item) => {
                  console.log("", item)
                  const log = this.loggingData[item.index]
                  return "Duration: " + this.runTime(log.activityStartTime, log.activityEndTime) + ` \nStatus: ${this.tileStatus(log.status || log.isActive)}`
                }
              }
            },
            elements: {
              line: {
                stepped: true,
                fill: false
              }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        // unit: 'minute',
                    }
                }],
                yAxes: [{
                  scaleLabel: {labelString: "Load Control Status", display: true},
                  ticks: {
                    stepSize: 1
                  }
                }]
            }
           }
        } 
        else if(this.logRows != null && this.logRows[0] != null 
        && this.logRows[0].extTempRaw != null 
        && this.logRows[0].humidityRaw != null 
        && this.logRows[0].intTempRaw != null){
          this.selectedDeviceType = "temperature";
          let chartDataInternalTemperature = this.loggingData.map((log) =>{
            let intTempRaw;
            if(log.intTempRaw == null){ intTempRaw = 0}
            else {intTempRaw = (log.intTempRaw).toFixed(2)}
            let isMetric = true;
            let unit = "C"
            if(this.meta.isMetric == null || this.meta.isMetric === false){
              isMetric = false;
              unit = "F";
              intTempRaw = this.convertedTemp(intTempRaw);
            }

            const chartPoint = {x: new Date(log.logDate), y: intTempRaw, label: `Internal Temp: ${intTempRaw} ${unit}`}
            return chartPoint;
          });
          let chartDataExternalTemperature = this.loggingData.filter((tempLog)=>{
            return tempLog.extTempRaw <= 130
          }).map((log) =>{
            let extTempRaw;
            if(log.extTempRaw == null){ extTempRaw = 0}
            else {extTempRaw = (log.extTempRaw).toFixed(2)}
            let isMetric = true;
            let unit = "C"
            if(this.meta.isMetric == null || this.meta.isMetric === false){
              isMetric = false;
              unit = "F";
              extTempRaw = this.convertedTemp(extTempRaw);
            }

            const chartPoint = {x: new Date(log.logDate), y: extTempRaw, label: `External Temp: ${extTempRaw} ${unit}`}
            return chartPoint;
          });
          let chartDataHumidity = this.loggingData.map((log) =>{
            let humidityRaw;
            if(log.humidityRaw == null){ humidityRaw = 0}
            else {humidityRaw = (log.humidityRaw)}
            const chartPoint = {x: new Date(log.logDate), y: humidityRaw, label: `Humidity: ${humidityRaw}%`}
            return chartPoint;
          });
          let unit = "C"
          if(this.meta.isMetric == null || this.meta.isMetric === false){
            unit = "F";
          }
          this.dataCollection = {
            datasets: [
              {
                data: chartDataInternalTemperature,
                lineTension: 0,
                fill: false, 
                backgroundColor: '#D85343', 
                borderColor: "#D85343", 
                label: `Internal Temperature (${unit})`, // Internal Temp
                yAxisID: 'A',
              },
              {
                data: chartDataExternalTemperature, 
                lineTension: 0,
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED", 
                label: `External Temperature (${unit})`,  // External Temp
                // yAxisID: 'B',
              },
              {
                data: chartDataHumidity, 
                lineTension: 0,
                fill: false, 
                backgroundColor: '#F1BE44', 
                borderColor: "#F1BE44", 
                label: "Humidity (%)",
                yAxisID: 'C',
              },
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "External, Internal, and Humidity"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
            legend: {
              display: true
            },
            tooltips: {
              callbacks: {
 
              }
            },
            elements: {

            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [
                  {
                    id: "A",
                    position: "left",
                    scaleLabel: {labelString: `Temperature (${unit})`, display: true},
                    ticks: {
                      // beginAtZero: false,
                      // suggestedMin: 12.1,
                      // suggestedMax: 12.3
                    },
                  },
                  // {
                  //   id: "B",
                  //   position: "left",
                  //   title: {text: "On / Off"},
                  // },
                  {
                    id: "C",
                    position: "right",
                    scaleLabel: {labelString: "Humidity (%)", display: true},
                    ticks: {
                      // beginAtZero: false,
                      suggestedMin: 0,
                      suggestedMax: 100
                    },
                  },
                ]
            }
           }
        }
        console.log("Device type: ", this.selectedDeviceType)
        this.logRows = this.logRows.reverse();
        this.loadingLogs = false;
        // console.log("", this.alarmFilter, this.loadingLogs)
      } else {
        // probably true for any alarm type?
        // use alarm title for chart title
        /* eslint-disable-next-line no-unused-vars */
        // set selected device type to activationAlarm
        // eslint-disable-next-line no-unused-vars
        let activationTypes = new Set([2, 3, 5, 4, 6, 8, 10]);
        this.selectedLogType = 'alarms';
        console.log(selectedAlarm)
        this.selectedAlarm = selectedAlarm;
        this.selectedDeviceDisplayTitle = this.selectedAlarm.title;
        this.selectedDeviceLogID = selectedAlarm.id;
        // eslint-disable-next-line no-constant-condition
        if(true/*activationTypes.has(selectedAlarm.alarmType)*/){
          this.selectedDeviceType = 'activationAlarm'

          this.loadingLogs = true;

          const alarmLogsSnap = await firebaseApp.database().ref(`/logging/${this.ownerID}/vessels/${this.$route.params.vesselId}/alarms/${selectedAlarm.id}`).orderByKey().limitToLast(100).once("value")
          const alarmLogs = alarmLogsSnap.val()
          // console.log("Alarm logs: ", alarmLogs)
          this.rawLogData = alarmLogs;
          this.logRows = [];
          this.loggingData = [];

          alarmLogsSnap.forEach(logSnap => {
            const log = logSnap.val();
            log["logDate"] = logSnap.key;
            this.logRows.push(log);
            this.loggingData.push(log)
          })

          let chartData = this.loggingData.map((log) =>{
            let status;
            if(log.isActive == null){ status = 0}
            else if(log.isActive === false) { status = 0 }
            else if(log.isActive === true) { status = 1}
            const durationString = this.runTime(log.activityStartTime, log.activityEndTime)
            const chartPoint = {x: new Date(log.logDate), y: status, label: `Duration: ${durationString}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartData, 
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED"
              }
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: `${selectedAlarm.name} Status vs Time`
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: false
            },
            tooltips: {
            callbacks: {
                label: (item) => {
                  // console.log("", item)
                  const log = this.loggingData[item.index]
                  return "Duration: " + this.runTime(log.activityStartTime, log.activityEndTime) + ` \nStatus: ${this.tileStatus(log.status || log.isActive)}`
                }
              }
            },
            elements: {
              line: {
                stepped: true,
                fill: false
              }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: selectedAlarm.name},
                    time: {
                        // unit: 'minute',
                    }
                }],
                yAxes: [{
                  scaleLabel: {labelString: "Alarm On/Off", display: true},
                  ticks: {
                    stepSize: 1
                  }
                }]
            }
           }

          this.logRows = this.logRows.reverse();
          this.loadingLogs = false;
          }
      }


    },
    refreshLineCharts(){
        if(this.selectedDeviceType == 'bilge'){
          let chartData = this.loggingData.map((log) =>{
            let status;
            if(this.selectedLogType === 'devices'){
              if(log.status == null){ status = 0}
              else if(log.status === false) { status = 0 }
              else if(log.status === true) { status = 1}
            } else if(this.selectedLogType === 'alarms'){
              if(log.isActive == null){ status = 0}
              else if(log.isActive === false) { status = 0 }
              else if(log.isActive === true) { status = 1}
            }
            

            const chartPoint = {x: new Date(log.logDate), y: status, label: `Duration: ${log.duration}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartData, 
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED"
              }
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "Pump Status vs Time"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: false
            },
            tooltips: {
            callbacks: {
                label: (item) => {
                  // console.log("", item)
                  const log = this.loggingData[item.index]
                  return "Duration: " + this.runTime(log.activityStartTime, log.activityEndTime) + ` \nStatus: ${this.tileStatus(log.status || log.isActive)}`
                }
              }
            },
            elements: {
              line: {
                stepped: true,
                fill: false
              }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [{
                  scaleLabel: {labelString: "Pump Status", display: true},
                  ticks: {
                    stepSize: 1
                  }
                }]
            }
          }
        } else if(this.selectedDeviceType === 'loadControl'){
          let chartData = this.loggingData.map((log) =>{
            let status;
            if(this.selectedLogType === 'devices'){
              if(log.status == null){ status = 0}
              else if(log.status === false) { status = 0 }
              else if(log.status === true) { status = 1}
            } else if(this.selectedLogType === 'alarms'){
              if(log.isActive == null){ status = 0}
              else if(log.isActive === false) { status = 0 }
              else if(log.isActive === true) { status = 1}
            }
            const chartPoint = {x: new Date(log.logDate), y: status, label: `Duration: ${log.duration}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartData, 
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED"
              }
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "Status vs Time"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: false
            },
            tooltips: {
            callbacks: {
                label: (item) => {
                  // console.log("", item)
                  const log = this.loggingData[item.index]
                  return "Duration: " + this.runTime(log.activityStartTime, log.activityEndTime) + ` \nStatus: ${this.tileStatus(log.status || log.isActive)}`
                }
              }
            },
            elements: {
              line: {
                stepped: true,
                fill: false
              }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [{
                  scaleLabel: {labelString: "Control Status", display: true},
                  ticks: {
                    stepSize: 1
                  }
                }]
            }
          }
        }
         else if(this.selectedDeviceType === 'battery'){
          // eslint-disable-next-line no-unused-vars
          let chartDataVoltage = this.loggingData.map((log) =>{
            let voltage;
            if(log.voltage == null || isNaN(log.voltage)){ voltage = 0}
            else {voltage = log.voltage}
            const chartPoint = {x: new Date(log.logDate), y: voltage.toFixed(1), label: `Voltage: ${voltage}`}
            return chartPoint;
          });
          let chartDataCurrent = this.loggingData.map((log) =>{
            let current;
            if(log.current == null){ current = 0}
            else {current = log.current}
            const chartPoint = {x: new Date(log.logDate), y: current, label: `Current: ${current}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartDataVoltage,
                lineTension: 0,
                fill: false, 
                backgroundColor: '#588AED', 
                borderColor: "#588AED", 
                label: "Voltage (Vdc)",
                yAxisID: 'A',
              },
              {
                data: chartDataCurrent, 
                lineTension: 0,
                fill: false, 
                backgroundColor: '#D85140', 
                borderColor: "#D85140", 
                label: "Current (A)",
                yAxisID: 'B',
              },
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "Battery Voltage and Battery Current"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: true
            },
            tooltips: {
              callbacks: {
 
              }
            },
            elements: {

            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Voltage and Current over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [
                  {
                    id: "A",
                    position: "left",
                    scaleLabel: {labelString: "Voltage (Vdc)", display: true},
                    ticks: {
                      // beginAtZero: false,
                      suggestedMin: 9,
                      suggestedMax: 14
                    },
                  },
                  {
                    id: "B",
                    position: "right",
                    title: {text: "On / Off"},
                    scaleLabel: {labelString: "Current (A)", display: true},
                    ticks: {
                      // beginAtZero: false,
                      suggestedMin: -1,
                      suggestedMax: 1
                    },
                  },
                ]
            }
           }
        } 
        else if(this.selectedDeviceType === 'temperature'){
          let chartDataInternalTemperature = this.loggingData.map((log) =>{
            let intTempRaw;
            if(log.intTempRaw == null){ intTempRaw = 0}
            else {intTempRaw = (log.intTempRaw).toFixed(2)}
            let isMetric = true;
            let unit = "C"
            if(this.meta.isMetric == null || this.meta.isMetric === false){
              isMetric = false;
              unit = "F";
              intTempRaw = this.convertedTemp(intTempRaw);
            }
            const chartPoint = {x: new Date(log.logDate), y: intTempRaw, label: `Internal Temp: ${intTempRaw} ${unit}`}
            return chartPoint;
          });
          let chartDataExternalTemperature = this.loggingData.filter((tempLog)=>{
            return tempLog.extTempRaw <= 130
          }).map((log) =>{
            let extTempRaw;
            if(log.extTempRaw == null){ extTempRaw = 0}
            else {extTempRaw = (log.extTempRaw).toFixed(2)}
            let isMetric = true;
            let unit = "C"
            if(this.meta.isMetric == null || this.meta.isMetric === false){
              isMetric = false;
              unit = "F";
              extTempRaw = this.convertedTemp(extTempRaw);
            }
            const chartPoint = {x: new Date(log.logDate), y: extTempRaw, label: `External Temp: ${extTempRaw} ${unit}`}
            return chartPoint;
          });
          let chartDataHumidity = this.loggingData.map((log) =>{
            let humidityRaw;
            if(log.humidityRaw == null){ humidityRaw = 0}
            else {humidityRaw = (log.humidityRaw)}
            const chartPoint = {x: new Date(log.logDate), y: humidityRaw, label: `Humidity: ${humidityRaw}%`}
            return chartPoint;
          });
          // console.log(chartData)
          let isMetric = true;
            let unit = "C"
            if(this.meta.isMetric == null || this.meta.isMetric === false){
              unit = "F";
            }
          this.dataCollection = {
            datasets: [
              {
                data: chartDataInternalTemperature,
                lineTension: 0,
                fill: false, 
                backgroundColor: '#D85343', 
                borderColor: "#D85343", 
                label: `Internal Temperature (${unit})`,  // Internal Temp
                yAxisID: 'A',
              },
              {
                data: chartDataExternalTemperature, 
                lineTension: 0,
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED", 
                label: `External Temperature (${unit})`,  // External Temp
                // yAxisID: 'B',
              },
              {
                data: chartDataHumidity, 
                lineTension: 0,
                fill: false, 
                backgroundColor: '#F1BE44', 
                borderColor: "#F1BE44", 
                label: "Humidity (%)",
                yAxisID: 'C',
              },
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: "External, Internal, and Humidity"
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: true
            },
            tooltips: {
              callbacks: {
 
              }
            },
            elements: {

            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [
                  {
                    id: "A",
                    position: "left",
                    scaleLabel: {labelString: `Temperature (${unit})`, display: true},
                    ticks: {
    
                    },
                  },
    
                  {
                    id: "C",
                    position: "right",
                    scaleLabel: {labelString: "Humidity (%)", display: true},
                    ticks: {
                      // beginAtZero: false,
                      suggestedMin: 0,
                      suggestedMax: 100
                    },
                  },
                ]
            }
           }
        } 
        else if(this.selectedDeviceType == 'activationAlarm'){
          let chartData = this.loggingData.map((log) =>{
            let status;
            if(this.selectedLogType === 'devices'){
              if(log.status == null){ status = 0}
              else if(log.status === false) { status = 0 }
              else if(log.status === true) { status = 1}
            } else if(this.selectedLogType === 'alarms'){
              if(log.isActive == null){ status = 0}
              else if(log.isActive === false) { status = 0 }
              else if(log.isActive === true) { status = 1}
            }
            const chartPoint = {x: new Date(log.logDate), y: status, label: `Duration: ${log.duration}`}
            return chartPoint;
          });
          // console.log(chartData)
          this.dataCollection = {
            datasets: [
              {
                data: chartData, 
                fill: false, 
                backgroundColor: '#5286ED', 
                borderColor: "#5286ED"
              }
            ],
            
          }
          this.chartOptions = {
            title: {
              display: true,
              text: `${this.selectedAlarm.name} Status vs Time`
            },
            responsive: true,
            maintainAspectRatio: false,
            fill: false,
            legend: {
              display: false
            },
            tooltips: {
            callbacks: {
                label: (item) => {
                  // console.log("", item)
                  const log = this.loggingData[item.index]
                  return "Duration: " + this.runTime(log.activityStartTime, log.activityEndTime) + ` \nStatus: ${this.tileStatus(log.status || log.isActive)}`
                }
              }
            },
            elements: {
              line: {
                stepped: true,
                fill: false
              }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'linear',
                    title: {text: "Activations over time"},
                    time: {
                        unit: 'minute',
                    }
                }],
                yAxes: [{
                  scaleLabel: {labelString: "Pump Status", display: true},
                  ticks: {
                    stepSize: 1
                  }
                }]
            }
          }
        }
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
    '$route.params.tileId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
    ownerID: (newVal, oldVal) => {
      console.log("Owner ID: ", newVal, oldVal)
    }
  }
}
</script>

<style lang="scss" scoped>
tr:nth-child(even) {background: #CCC}
table {
  max-width: 800px;
}
td {
  width: 100px;
}
.geofence-panel{
  margin-left: 95px;
}

.small {
    max-width: 600px;
    flex: 1;
}

.clickable {
  cursor: pointer;
}

@import '@/assets/styles/index.scss';
.geofence-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;
    
}
.geofence-viewer {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}
.geofence-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}
.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}

.log_value_display {
    padding: 0.25em;
    text-align: center;
}

.alarm_display_list {}

.date_column {
    text-align: center;
}
.alarm_detail_column div {
    border: solid 2px silver;
    padding: 15px 5px;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
}
.alarm_detail_column {}

span.raw_value {
    font-size: 0.75em;
}

td div.sensor_alarm_item {
    display: inline-block;
    padding: 4px 8px;
    margin: 4px;
    width: auto;
}
.currentActivity {
  color: green;
}
</style>
