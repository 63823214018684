<template>
<el-card class="tile" :body-style="getBodyStyle" :style="getCardStyle">
  <!-- <el-button icon="el-icon-edit-outline" type="text" class="edit-tile" /> -->
  <el-dialog :close-on-click-modal="false" :title="'Set Time'" :visible.sync="openSetTimeDlg" width="40%">
    <div v-if="isLoadControl">
      <el-switch v-model="timeContinuous" @input="calcLoadControlTime" active-text="Continuous" />
      <br>
      <div v-if="!timeContinuous" v-bind:style="{textAlign: 'center'}">
        <v-select :options="hourOptions" v-model="loadHours" v-bind:style="{ display: 'inline-block', width: '100px'}" @input="setLoadHours"></v-select> hr
        &nbsp;
        <v-select :options="minuteOptions" v-model="loadMinutes" v-bind:style="{ display: 'inline-block', width: '100px'}" @input="setLoadMinutes"></v-select> min
        &nbsp;
        <v-select :options="minuteOptions" v-model="loadSeconds" v-bind:style="{ display: 'inline-block', width: '100px'}" @input="setLoadSeconds"></v-select> s
        <br>

        <span v-if="isLoadControl">{{textDisplayTime}}</span>
      </div>
    </div>
    <div v-if="!isLoadControl">
      <el-slider ref="popupSlider" v-model="time" :max="max" :min="min" @input="calcPumpControlTime" />
      <br>
      <div v-bind:style="{ textAlign:  'center' }">
        <span v-bind:style="{ float:  'left' }">{{min}}</span>
        <el-input v-model="time" v-bind:style="{ width:  '100px', display: 'inline-block' }" @input="updatePumpSlider" />
        &nbsp;
        seconds
        <span v-bind:style="{ float:  'right' }">{{max}}</span>
      </div>
    </div>
    <div slot="footer" class="dlg-footer">
      <el-button type="secondary" @click="closeDialog">Cancel</el-button>
      <el-button type="primary" @click="saveTime">Save</el-button>
    </div>
  </el-dialog>
  <div class="tile-content"  @click="handleTileClick">
    <div class="tile-content-body">
      <button v-if="showEditButton" @click.stop="handleEditClick" style="border-radius:20px;background:rgb(89,199,250);position:absolute;top:5px;right:5px;z-index:100;border:none;padding-top:5px;padding-bottom:5px;">
        <span class="material-icons" style="color:white">edit</span>
      </button>
      <img :src="`/images/${image}.png`" height="25%">
      <h4 v-if="isConnected" style="margin:8px;">{{ tile.name }}</h4>
      <h4 v-else style="margin:8px; color: #808080">{{ tile.name }}</h4>
      
      <template v-if="tile.configured">
        <div v-if="isConnected">
        <p  v-for="(line, index) in stringsWithColor" :key="index" :style="{ color : `${foreColor(line.color)}`, whiteSpace: 'pre', padding: '0 4px' }" v-html="line.value" />
        
        </div>
        <template v-if="isConnected">
          <el-button v-if="tile.buttonState === 'off' && !cmdPending" round type="success" @click.stop="openTimeDialog" size="medium">Turn On</el-button>
          <el-button v-if="tile.buttonState === 'reset' && !cmdPending" round type="danger" @click.stop="stopControl" size="medium">Reset</el-button>
          <el-button v-if="tile.buttonState === 'on' && !cmdPending" round type="danger" @click.stop="stopControl" size="medium">Turn Off</el-button>
          <el-button v-if="tile.buttonState === 'disabled' && !cmdPending" round type="info" disabled size="medium">Turn Off</el-button>
          <el-button v-if="tile.buttonState === 'pending' && !cmdPending" round type="secondary" size="medium">Pending</el-button>
          <el-button v-if="cmdPending" round type="secondary" size="medium">Command Sent</el-button>
        </template>
        <template v-else>
          <p v-for="(line, index) in stringsWithColor" :key="index" :style="{ color : `#808080`, whiteSpace: 'pre', padding: '0 4px' }" v-html="line.value" />
          <el-button round type="info" disabled size="medium">Disconnected</el-button>
        </template>
      </template>
      <template v-else>
        <p :style="{ color: `${foreColor(stringsWithColor.line1.color)}`, whiteSpace: 'pre' }" v-html="stringsWithColor.line1.value" />
        <p>Not Configured</p>
      </template>
    </div>
    <div v-if="lastAlarm !== ''" class="tile-content-footer">
      {{ `Last Alarm: ${lastAlarm}` }}
    </div>
  </div>
</el-card>
</template>

<script>
import firebaseApp from '@/firebase/app';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Vue from "vue";
Vue.component("v-select", vSelect);


export default {
  props: {
    tile: {
      require: true,
      default: () => null
    },
    isConnected: {
      require: true,
      default: ()=> false
    }
  },
  data() {
    return {
      show: false,
      defaultColor: '#ebeef5',
      borderColor: '#34C759',
      image: 'bellIcon',
      lastAlarm: '',
      openSetTimeDlg: false,
      time: 1,
      min: 0,
      max: 15,
      loadHours: 0,
      loadMinutes: 0,
      loadSeconds: 0,
      hoursMin: 0,
      hoursMax: 24,
      totalSeconds: 1,
      textDisplayTime: 0,
      timeContinuous: false,
      cmdPending: false
    }
  },
  computed: {
    getBodyStyle() {
      return {
        border: `2px solid ${this.borderColor}`,
        paddingTop: '90%',
        borderRadius: '8px',
        position: 'relative'
      }
    },
    getCardStyle() {
      return {
        border: 'none',
        borderRadius: '8px',
        position: 'relative'
      }
    },
    stringsWithColor() {
      let r = {};
      if (this.tile.stringsWithColor.line1)
        r.line1 = this.tile.stringsWithColor.line1;
      if (this.tile.stringsWithColor.line2)
        r.line2 = this.tile.stringsWithColor.line2;
      if (this.tile.stringsWithColor.line3)
        r.line3 = this.tile.stringsWithColor.line3;

      return r;
    },
    isPumpControl() {
      return this.tile.parentName.includes('BGWS-01')
    },
    isLoadControl() {
      return this.tile.parentName.includes('BGWS-04')
    },
    hourOptions() {
      let r = [];
      for (; r.length < 25;)
        r.push(r.length);
      return r;
    },
    showEditButton() {
      if (this.tile.name === 'Geofence')
        return true;
      return false;
    },
    minuteOptions() {
      let r = [];
      for (; r.length < 61;)
        r.push(r.length);
      return r;
    }
  },
  methods: {
    closeDialog() {
      this.openSetTimeDlg = false;
    },
    foreColor(inColor) {
      if (!inColor)
        inColor = '';
      inColor = inColor.toLowerCase().split(' ').join('');
      if (inColor.indexOf('#ffffff') !== -1 || inColor.indexOf("255,255,255") !== -1)
        return 'rgb(0,0,0)';
      return inColor;
    },
    handleEditClick() {
      if (this.tile.name === "Geofence") {
        this.$router.push(`/app/${this.$route.params.vesselId}/geofence`);
        return;
      }

      return alert('No edit ability found for this tile');
    },
    handleTileClick() {
      this.$router.push(`/app/${this.$route.params.vesselId}/tiles/${this.tile.cachedId}/view`);
      return;
    },
    openTimeDialog() {
      this.openSetTimeDlg = true
    },
    async saveTime() {
      this.openSetTimeDlg = false

      if (this.isPumpControl)
        return await this._sendCommand(this.tile.cachedId, {
          state: 'on',
          time: this.time,
          type: 1
        });
      if (this.isLoadControl)
        return await this._sendCommand(this.tile.cachedId, {
          state: 'on',
          time: this.outputTime,
          type: 6
        });

      return null;
    },
    async stopControl() {
      if (this.isPumpControl) {
        return await this._sendCommand(this.tile.cachedId, {
          state: 'off',
          type: 1
        });
      }

      if (this.isLoadControl) {
        return await this._sendCommand(this.tile.cachedId, {
          state: 'off',
          type: 6
        });
      }

      return null;
    },
    calcPumpControlTime(value) {
      let newValue = Number(value);
      this.time = newValue;
      this.calcLoadControlTime();
    },
    updatePumpSlider(value) {
      let newValue = Number(value);
      value = newValue;
      if (!newValue)
        newValue = 0;

      if (newValue < this.min)
        newValue = this.min;
      if (newValue > this.max)
        newValue = this.max;
      if (newValue === value) {
        this.time = newValue;
        this.$refs.popupSlider.firstValue = this.time;
      }
      this.calcLoadControlTime();
    },

    calcLoadControlTime() {
      if (this.timeContinuous) {
        this.outputTime = 8191;
        this.textDisplayTime = 'Continuous';
        return;
      }
      this.totalSeconds = this.loadSeconds + this.loadHours * 3600 + this.loadMinutes * 60;

      if (this.totalSeconds <= 599) {
        this.outputTime = this.totalSeconds;
      } else {
        let minutes = Math.floor(this.totalSeconds / 60);
        this.outputTime = 590 + minutes;
        this.totalSeconds = minutes * 60;
        this.loadSeconds = 0;
      }

      if (this.outputTime > 8190)
        this.outputTime = 8190;

      let d = this.$moment.duration(this.totalSeconds * 1000);
      this.textDisplayTime = d.hours() + "h " + d.minutes() + "min " + d.seconds() + "s";
    },
    setLoadMinutes(value) {
      let newValue = Number(value);
      this.loadMinutes = newValue;
      this.calcLoadControlTime();
    },
    setLoadSeconds(value) {
      let newValue = Number(value);
      this.loadSeconds = newValue;
      this.calcLoadControlTime();
    },
    setLoadHours(value) {
      let newValue = Number(value);
      this.loadHours = newValue;
      this.calcLoadControlTime();
    },
    processTileData(val) {
      if (val !== null) {
        const tile = val
        this.image = (!!tile.image === false) ? 'bellIcon' : tile.image
        this.borderColor = tile.borderColor
        if (tile.alarms !== undefined && tile.alarms.length > 0) {
          const lastAlarm = tile.alarms[tile.alarms.length - 1];

          if (lastAlarm.lastTriggered) {
            let d = new Date(lastAlarm.lastTriggered);
            if (new Date() - d > 60 * 60 * 23 * 1000) {
              this.lastAlarm = new Date(lastAlarm.lastTriggered).toLocaleDateString(undefined);
            } else {
              this.lastAlarm = new Date(lastAlarm.lastTriggered).toLocaleTimeString();
            }
          } else {
            this.lastAlarm = ''
          }
        } else {
          this.borderColor = this.defaultColor
          this.lastAlarm = ''
        }
      }
      this.show = (val !== null)
    },
    async _sendCommand(cmd, packet) {
      if (!cmd) {
        console.log('error sending packet', this);
        alert('No command found, please contact support');
        return null;
      }

      let vId = this.$route.params.vesselId;
      console.log('command', vId, cmd, packet);
      let vesselCmdRef = firebaseApp.database().ref(`/vessels/${vId}/commands`);
      this.cmdPending = true;

      await vesselCmdRef.update({
        [cmd]: packet
      });
      let me = this;
      setTimeout(() => {
        me.cmdPending = false;
      }, 800);

      return null;
    }
  },
  mounted() {
    if (this.isLoadControl) {
      this.min = 0;
      this.max = 3600;
    }
    if (this.isPumpControl) {
      this.min = 15;
      this.max = 60;
    }
    this.time = Math.floor((this.max - this.min) / 2) + this.min;

    //console.log('tile mounted >>>>', this.tile.name, this.tile.buttonState, this.tile.image);
    this.processTileData(this.tile);
    this.calcLoadControlTime();
  },
  watch: {
    tile: {
      handler(val) {
        this.processTileData(val);
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.tile {
    width: 100%;
    margin-bottom: 10px;
    .edit-tile {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        font-size: 16px;
        z-index: 10;
    }
    &-content {
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            margin: 0;
            font-size: 14px;
            text-align: center;
        }
        &-body {
            width: 100%;
            height: calc(100% - 20px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .el-button {
                margin-top: 5px;
            }
        }
        &-footer {
            width: 100%;
            height: 20px;
            text-align: center;
            font-size: 14px;
        }
    }
    &-content:hover {
      background: rgb(235,235,235);
      border-radius: 2%;
      cursor: pointer;
    }
}
.tile-content-body button {
  border-radius: 8%;
}
</style>
