import axios from '@/plugins/axios'
import firebaseApp from '@/firebase/app'

export default {
  async SendInvite(context, params) {
    const { from, to, vesselId, invitedId, vesselName } = params
    let token = await firebaseApp.auth().currentUser.getIdToken(true);

    return new Promise((resolve, reject) => {
      axios.post('https://us-central1-bg-link-v2.cloudfunctions.net/app/invite', {
        from,
        to,
        vesselId,
        invitedId,
        vesselName,
        token
      }).then(res => {
        if (res.data && res.data.response) resolve(res.data.data)
        if (res.data && !res.data.response) reject(res.data.error)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
