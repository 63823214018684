<template>
  <div class="page-not-found">
    Page Not Found
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.page-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $clr-primary;
  font-size: 32px;
}
</style>