<template>
  <div class="bgi-invite"></div>
</template>

<script>
export default {
  data() {
    return {
      invite: null,
      handlingInvite: true,
      isReady: false,
      vesselId: '',
      inviteId: '',
      inviteEmail: ''
    }
  },
  created() {
    this.vesselId = this.$route.query.vesselId
    this.inviteId = this.$route.params.inviteId
    this.inviteEmail = this.$route.query.to
    console.log(this.$route.query.vesselId)
    console.log(this.$route.params.inviteId)
    console.log(this.$route.query.to)
    if (!this.vesselId || !this.inviteId || !this.inviteEmail) {
      this.$router.push({ name: 'pageNotFound' })
    } else {
      this.$router.push({
        path: '/signin',
        query: {
          vesselId: this.vesselId,
          inviteId: this.inviteId,
          email: this.inviteEmail
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.bgi-invite {
  width: 100%;
  min-height: 100vh;
  background-color: $clr-primary;  
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>