<template>
  <div class="home-view">
    <img src="/images/BGI-Smart-Skipper-Logo-White.png" width="622" alt="logo" />
    <el-button v-if="!isLoggedIn" class="login-btn" @click="goToLoginPage">Sign In</el-button>
  <div class="signedInButtons">
    <el-button v-if="isLoggedIn" class="login-btn" @click="goToAppPage">Go to Application</el-button>
    <el-button v-if="isLoggedIn" class="login-btn" @click="logout">Sign Out</el-button>
  </div>
    
  </div>
</template>

<script>
import firebaseApp from '@/firebase/app'
export default {
  name: 'home',
  data() {
    return {
      isLoggedIn: false,
      user: null
    }
  },
  mounted() {
    firebaseApp.auth().onAuthStateChanged(user => {
      console.log("User: ", user)
      if (user) {
        this.isLoggedIn = true;
        this.user = user;
        this.goToAppPage();
      } else {
        this.isLoggedIn = false
        this.user = null
      }
    });
  },
  methods: {
    logout() {
      firebaseApp.auth().signOut().then(() => {
        // this.$router.push('/')
      });
    },
    goToLoginPage() {
      this.$router.push('/signin');
    },
    goToAppPage() {
      const userId = this.user.uid
      const userRef = firebaseApp.database().ref(`/users/${userId}`)
      
      userRef.once('value', (res) => {
        const data = res.val()
        this.$store.commit('setUser', this.user)
        if(data.vessels && Object.keys(data.vessels)[0].length > 0){
          this.$router.push(`/app/${Object.keys(data.vessels)[0]}`)
        }
        
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.signedInButtons{
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: center;
}
.home-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 15px;
  background-color: $clr-primary;

  .login-btn {
    color: $clr-primary;
    font-family: $font-primary;
    font-weight: 300;
  }

  p {
    color: white;
    text-align: center;
  }
}
</style>

