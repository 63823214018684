<template>
<el-container class="users-view" v-loading="vesselUsers.length === 0 || sendingInvite" element-loading-background="rgba(0, 0, 0, 0.1)">
  <el-dialog :title="openEditDlg ? `${selectedUser.meta.firstName} ${selectedUser.meta.lastName} <${selectedUser.meta.email}>` : ''" :visible.sync="openEditDlg" width="40%" class="user-dlg">
    <div class="user-dlg-form" v-if="openEditDlg">
      <el-form label-width="150px" label-position="top">
        <el-form-item v-if="user.uid !== selectedUser.userId" label="Role:">
          <template v-if="role === 2 || role === 1">
            <el-select v-model="selectedUser.role.role">
              <el-option label="Guest" :value="0" />
              <el-option label="Admin" :value="1" />
            </el-select>
          </template>
          <template v-else>
            <el-select v-model="selectedUser.role.role">
              <el-option label="Guest" :value="0" />
            </el-select>
          </template>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dlg-footer">
      <el-button type="primary" @click="handleSave">Save</el-button>
    </div>
  </el-dialog>
  <!-- Invite Dialog -->
  <el-dialog :title="'Send an invite'" :visible.sync="openInviteDlg" width="40%">
    <div class="invite-dlg">
      <div class="invite-dlg-form">
        <el-form ref="inviteForm" label-position="top" :model="inviteForm">
          <el-form-item prop="email">
            <el-input v-model="inviteForm.email" placeholder="Email" />
          </el-form-item>
          <el-form-item prop="role">
            <template v-if="role === 2 || role === 1">
              <el-select v-model="inviteForm.role" placeholder="Select a role">
                <el-option label="Guest" value="0" />
                <el-option label="Admin" value="1" />
              </el-select>
            </template>
            <template v-else>
              <el-select v-model="inviteForm.role" placeholder="Select a role">
                <el-option label="Guest" value="0" />
              </el-select>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div slot="footer" class="dlg-footer">
      <el-button type="primary" @click="handleSendInvite">Send</el-button>
    </div>
  </el-dialog>
  <el-aside class="users-aside">
    <bgi-aside />
  </el-aside>
  <el-main style="flex-direction:column;display:flex;overflow:hidden;padding:0;"  class="users">
    <!-- Edit Dialog -->
    <el-header class="vessel-name">{{ meta.name }}</el-header>
    <div class="send-invite" style="padding:10px;">
     <span class="errorMessage">{{errorMessage}}</span> <el-button round @click="handleOpenInviteDlg()" :disabled="role === 0">Send Invite</el-button>
    </div>
    <div v-if="vesselUsers.length !== 0" class="users-container" style="flex:1;overflow:hidden auto;padding:10px;">
      <el-row :gutter="20" style="font-size:.6em;word-break: break-all">
        <el-col v-for="(vesselUser, index) in vesselUsers" :key="index" :xl="6" :lg="8" :md="8" :sm="12" :xs="24">
          <div class="user">
            <div class="user-photo">
              <el-avatar v-if="vesselUser.role.role === 2" :size="150" shape="square" fit="fit">
                <img src="/images/ownerIcon.png" alt="owner">
              </el-avatar>
              <el-avatar v-else-if="vesselUser.role.role === 1" :size="150" shape="square" fit="fit">
                <img src="/images/adminIcon.png" alt="admin">
              </el-avatar>
              <el-avatar v-else :size="150" shape="square" fit="fit">
                <img src="/images/guestIcon.png" alt="guest">
              </el-avatar>
            </div>
            <div class="user-info">
              <h3 v-if="vesselUser.meta">{{ `${vesselUser.meta.firstName} ${vesselUser.meta.lastName}` }}</h3>
              <p v-if="vesselUser.meta">{{ vesselUser.meta.email }}</p>
              <p v-if="vesselUser.meta">{{ vesselUser.meta.phone }}</p>
              <div class="user-actions">
                <el-button size="mini" type="text" @click="handleOpenEditDlg(vesselUser)" v-if="editShown(vesselUser)" :disabled="editDisabled(vesselUser)">Edit</el-button>
                <el-divider direction="vertical" type="primary" />
                <el-button size="mini" type="text" @click="removeUser(vesselUser)"
                v-if="!removeDisabled(vesselUser)" :disabled="removeDisabled(vesselUser)">Remove</el-button>
              </div>
            </div>
          </div>
        </el-col>
        <el-col v-for="invite in invites" :key="invite.inviteId" :xl="6" :lg="8" :md="8" :sm="12" :xs="24">
          <div class="user">
            <div class="user-photo">
              <el-avatar v-if="Number(invite.role) === 2" :size="150" shape="square" fit="fit">
                <img src="/images/ownerIcon.png" alt="owner">
              </el-avatar>
              <el-avatar v-else-if="Number(invite.role) === 1" :size="150" shape="square" fit="fit">
                <img src="/images/adminIcon.png" alt="Admin">
              </el-avatar>
              <el-avatar v-else :size="150" shape="square" fit="fit">
                <img src="/images/guestIcon.png" alt="guest">
              </el-avatar>
            </div>
            <div class="user-info">
              <h3>{{ `${invite.to.email}` }}</h3>
              <p>{{ `Invite: ${ $moment(new Date()).diff(invite.inviteDate, 'hours') > 24 ? 'Expired' : 'Pending' }` }}</p>
              <p v-if="$moment(new Date()).diff(invite.inviteDate, 'hours') <= 24">{{ `Expiring in ${ $moment(new Date()).diff(invite.inviteDate, 'hours') > 24 ? '' : 24 - $moment(new Date()).diff(invite.inviteDate, 'hours') } hours.` }}</p>
              <p>{{ `From: ${invite.from.email}` }}</p>
              <div class="user-actions">
                <el-button size="mini" type="text" @click="resendInvite(invite)">Resend Invite</el-button>
                <el-divider direction="vertical" type="primary" />
                <el-button size="mini" type="text" @click="removeInvite(invite.inviteId)">Cancel Invite</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-main>
</el-container>
</template>

<script>
import {
  mapState,
} from 'vuex'
import axios from '../plugins/axios'

import firebaseApp from '../firebase/app'
import BgiAside from '@/components/alarms/aside.vue'

export default {
  components: {
    BgiAside
  },
  data() {
    return {
      role: 0,
      errorMessage: "",
      users: null,
      vesselUsers: [],
      subscriptions: [],
      invites: [],
      fireData: {},
      fireDataIds: {},
      roles: null,
      openEditDlg: false,
      openInviteDlg: false,
      selectedUser: {},
      sendingInvite: false,
      usersCache: {},
      inviteForm: {
        email: '',
        role: ''
      },
      meta: {
        name: 'XXX'
      }
    }
  },

  computed: {
    ...mapState(['user'])
  },
  async mounted() {
    const invitesSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/invites`).once("value");
    console.log("Invites: ", invitesSnap.val())
    // this.invites = invitesSnap.val();
    invitesSnap.forEach((valSnap)=>{
      const invite = valSnap.val();
      invite.inviteId = valSnap.key;
      this.invites.push(invite)
    })
    const usersRef = firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/roles`);

    this.subscriptions.push(usersRef.on("value", this.retrieveVesselUsers)
    )

    this.updateDataBinding();
  },
  methods: {
    retrieveVesselUsers: async function (snap) {
      console.log("Roles retrieved", snap.val())
      const vesselRoles = snap.val();
      this.roles = vesselRoles;
      this.vesselUsers = [];
      // this.invites = [];
      this.role = Number(this.roles[firebaseApp.auth().currentUser.uid].role);

      // this.vesselUsers.push({
      //   userId: firebaseApp.auth().currentUser.uid,
      //   role: this.roles[firebaseApp.auth().currentUser.uid],
      //   meta: this.currentUser
      // });

      const vesselUserIds = Object.keys(vesselRoles);
      console.log("VesselUserIds:", vesselUserIds, this.vesselUsers)
      this.vesselUsers = [];
      for(const id of vesselUserIds){
        const role = this.roles[id]
        // if (this.role > Number(role.role)) {

          if (!this.usersCache[id]) {
            await this.fetchUser(id);
          }
          this.vesselUsers.push({
            userId: id,
            role: {
              role: Number(role.role)
            },
            meta: this.usersCache[id]
          })
        // }
      }
      console.log("Final vessel users:",this.vesselUsers);
    },
    async sendInvite(params) {
    const { from, to, vesselId, invitedId, vesselName, role } = params
    let token = await firebaseApp.auth().currentUser.getIdToken(true);

    return new Promise((resolve, reject) => {
      return axios.post('https://us-central1-bg-link-v2.cloudfunctions.net/app/invite', {
        from,
        to,
        vesselId,
        invitedId,
        vesselName,
        role,
        token
      }).then(res => {
        this.sendingInvite = false;
        console.log("Invite resp data: ", res)
        if (res.data && res.data.response){ 
          console.log("Response success")
          this.errorMessage = "";
          resolve(res.data.data)
        }
        if (res.data && !res.data.response){
          console.log("Response failure")
          // this.errorMessage = res.data.data.error;
          this.$notify.error({
            message: res.data.data.error
          })
          reject(res.data.error)
        } 
      }).catch(err => {
        this.sendingInvite = false;
        reject(err)
      })
    })
  },

    async vesselIdWatcher(newVal, oldVal) {
      console.log("Old vessel:", oldVal)
      console.log("New vessel:", newVal)
      const oldVesselRef = firebaseApp.database().ref(`/vessels/${oldVal}/roles`);
      oldVesselRef.off("value");

      const invitesSnap = await firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/invites`).once("value");
      console.log("Invites: ", invitesSnap.val())

      this.invites = [];
      invitesSnap.forEach((valSnap)=>{
        const invite = valSnap.val();
        invite.inviteId = valSnap.key;
        this.invites.push(invite)
      })
      this.updateDataBinding();
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.ownerID = null;
      // this.watchPaths['invites'] = `/vessels/${this.$route.params.vesselId}/invites`;
      // this.watchPaths['roles'] = `/vessels/${this.$route.params.vesselId}/roles`;
      this.watchPaths['invites'] = `/vessels/${this.$route.params.vesselId}/invites`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;
      this.watchPaths['currentUser'] = `/users/${this.user.uid}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        console.log("Updating watched routed.")
        this.getData();
      });
    },
    handleOpenInviteDlg() {
      this.openInviteDlg = true
      this.inviteForm = {
        selectedUser: '',
        email: '',
        role: ''
      }
    },
    handleOpenEditDlg(user) {
      this.selectedUser = user
      this.openEditDlg = true
    },
    handleSave() {
      const {
        role
      } = this.selectedUser.role
      firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/roles/${this.selectedUser.userId}`).set({
        role: Number(role)
      }).then(() => {
        this.openEditDlg = false
        this.getData()
      })
    },
    handleSendInvite() {
      const {
        email,
        role
      } = this.inviteForm
      const inviteParams = {}
      inviteParams.from = `${this.currentUser.firstName} ${this.currentUser.lastName} <${firebaseApp.auth().currentUser.email}>`
      inviteParams.vesselId = this.$route.params.vesselId
      inviteParams.vesselName = this.meta.name
      inviteParams.to = email
      inviteParams.role = role
      inviteParams.invitedId = null
      this.sendingInvite = true
      let isValidInvite = true
      console.log(this.invites)
      if (this.invites) {
        this.invites.forEach(invite => {
          console.log("Individual invite: ", invite)
          if (invite.to.email === email) {
            isValidInvite = false
            return
          }
        })
      }
      this.vesselUsers.forEach(user => {
        if (user.meta.email === email) {
          isValidInvite = false
          return
        }
      })

      if (isValidInvite) {
        // eslint-disable-next-line no-unused-vars
        console.log(inviteParams)
        this.sendInvite(inviteParams).then(async res => {
          this.sendingInvite = false
          // inviteParams.inviteId = res.inviteId;
          console.log("Invite params: ", inviteParams)
          // this.invites.push(inviteParams)
          const newInviteSnap = await firebaseApp.database().ref(`vessels/${this.$route.params.vesselId}`).child(`invites/${res.inviteId}`).once("value")
          const invite = newInviteSnap.val();
          invite.inviteId = res.inviteId;
          this.invites.push(invite);
          //   from: {
          //     email: this.currentUser.email,
          //     uid: this.user.uid
          //   },
          //   to: {
          //     email: inviteParams.to
          //   },
          //   role,
          //   inviteDate: (new Date()).toISOString()
          // })
          // .then(() => {
          //   this.sendingInvite = false
          //   this.getData()
          // })
        }).catch((rejectionData)=>{
          console.log("Rejection: ", rejectionData)
        })
      } else {
        this.sendingInvite = false
        this.$notify.error({
          message: `You already sent an invite to ${inviteParams.to}`
        })
      }
      this.openInviteDlg = false
    },
    removeUser(user) {
      firebaseApp.database().ref(`/users/${user.userId}/vessels/${this.$route.params.vesselId}`).remove().then(() => {
        firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/roles/${user.userId}`).remove().then(() => {
          this.getData()
        })
      })
    },
    editShown(user){
      // console.log(user)
      if(user.userId != firebaseApp.auth().currentUser.uid && this.role > 0 && user.role.role != 2){
        return true;
      } else { 
        return false
      }
    },
    editDisabled(user){
      if(user.userId != firebaseApp.auth().currentUser.uid && this.role > 0 && user.role.role != 2){
        return false;
      } else { 
        return true
      }
    },
    removeDisabled(user){
      if(user.userId != firebaseApp.auth().currentUser.uid && this.role > 0 && user.role.role != 2){
        return false;
      } else { 
        return true
      }
    },

    removeInvite(inviteId) {
      // console.log(this.invites)
      console.log("Removing invite ID: ", inviteId)
      return firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/invites/${inviteId}`).remove().then(() => {
        console.log("Removed")
        const filteredInvites = this.invites.filter((invite)=>{
          return invite.inviteId != inviteId
        });
        console.log(filteredInvites)
        this.invites = filteredInvites
        // this.getData()
      })
    },
    resendInvite(invite) {
      console.log(invite)
      const inviteParams = {
        from: `${this.currentUser.firstName} ${this.currentUser.lastName} <${firebaseApp.auth().currentUser.email}>`,
        to: invite.to.email,
        role: invite.role,
        vesselId: this.$route.params.vesselId,
        vesselName: this.meta.name,
        invitedId: invite.inviteId
      }
      console.log(inviteParams)
      this.sendingInvite = true
      // eslint-disable-next-line no-unused-vars
      this.sendInvite(inviteParams).then(res => {
        this.getData();
        // inviteParams.inviteId = res.inviteId;
        // this.invites.push(inviteParams)
        // const vesselRef = firebaseApp.database().ref(`vessels/${this.$route.params.vesselId}`)
        this.sendingInvite = false;
      }).catch(error => {
        this.sendingInvite = false
        this.$notify.error({
          title: `Failed resending an invite to ${inviteParams.to}`,
          message: error.message
        })
      })
    },
    async fetchUser(uid) {
      console.log("Fetching user...")
      await firebaseApp.database().ref(`users/${uid}/meta`).once('value').then(res => {
        this.usersCache[uid] = res.val();
        // this.getData();
      })
    },
    getData() {

      this.meta = this.fireData['meta'];

      this.sentInvites = this.fireData['invites'];
      this.currentUser = this.fireData['currentUser'];
      if (!this.meta)
        return;
      if (!this.sentInvites)
        return;
      if (!this.meta)
        return;
      if (!this.currentUser)
        return;

      this.vesselUsers.sort((a, b) => {
        if (this.roles[a.userId].role < this.roles[b.userId].role) return 1
        else if (this.roles[a.userId].role > this.roles[b.userId].role) return -1
        else {
          if (a.meta.firstName > b.meta.firstName) return 1
          if (a.meta.firstName < b.meta.firstName) return -1
          return 0
        }
      });

      this.invites.sort((a, b) => {
        if (Number(a.role) < Number(b.role)) return 1
        else if (Number(a.role) > Number(b.role)) return -1
        else {
          if (this.$moment(a.inviteDate).isAfter(b.inviteDate)) return 1
          else if (this.$moment(a.inviteDate).isBefore(b.inviteDate)) return -1
          else {
            if (a.to.eamil > b.to.email) return 1
            if (a.to.email < b.to.email) return -1
            return 0
          }
        }
      });
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>

<style lang="scss">
.errorMessage{
  width: 100%;
  text-align: center;
  color: red;
}
.users-view {
    
    .users {
      margin-left: 95px;
        .user-dlg {
            .el-dialog__body {
                padding: 10px 20px;
            }
        }
        .user-dlg-form {
            .el-form {
                width: 100%;
                &-item {
                    margin: 0 0 15px;
                    &__label {
                        line-height: 30px;
                        padding: 0;
                    }
                    &__content {
                        .el-select {
                            width: 100%;
                        }
                    }
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.vessel-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}
.users-container {
    .user {
        display: flex;
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
        .user-photo {
            width: 150px;
            margin-right: 10px;
            .el-avatar {
                background-color: $clr-primary;
                font-size: 150px;
            }
        }
        .user-info {
            h3,
            h4,
            p {
                margin: 4px 0;
            }
        }
    }
}
.users-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}

.invite-dlg {
    &-form {
        width: 100%;
        .el-form {
            &-item {
                margin: 0 0 5px;
                width: 100%;
                .el-select {
                    width: 100% !important;
                }
            }
        }
    }
}
.dlg-footer {
    display: flex;
    justify-content: center;
}
.send-invite {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: solid silver 1px;
}

.users-view {
  height: inherit;
}
</style>
