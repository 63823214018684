<template>
  <el-container class="alarms">
    <el-aside class="alarms-aside">
      <bgi-aside />
    </el-aside>
    <el-main class="alarms-main">
      <bgi-tiles />
    </el-main>
  </el-container>
</template>

<script>
import BgiAside from '@/components/alarms/aside.vue'
import BgiTiles from '@/components/alarms/tiles.vue'
export default {
  components: {
    BgiAside,
    BgiTiles
  }
}
</script>

<style lang="scss" scoped>
.alarms {
  padding: 0;
  margin: 0;
  &-main {
    padding: 0;
    margin: 0;
  }
  &-aside {
    padding: 0;
    margin: 0;
    width: auto !important;
  }
}
</style>