<template class="charts-mainview">
<el-container class="charts-container" style="padding:0">
  <el-aside class="charts-aside">
    <bgi-aside />
  </el-aside>
  <el-main class="charts-viewer" style="flex-direction:column;display:flex;overflow:hidden;padding:0;">
    <el-header class="selected-name">
      {{ !!meta ? meta.name : '' }}
    </el-header>
    <div style="padding:10px;border-bottom: solid silver 1px;">
      {{viewTitle}}
    </div>
    <div class="chart_list_wrapper" style="flex:1;overflow:hidden auto;">
      <table class="chart_list_table">
        <thead>
          <tr>
            <th>Chart Name</th>
            <th>Date Created</th>
            <th>Created By</th>
            <th>Start</th>
            <th>End</th>
            <th>Sensors</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(chart, index) in charts" :key="index">
            <td>{{chart.chart.name}}</td>
            <td>{{formatDateTime(chart.chart.created)}}</td>
            <td>{{chart.chart.userDescription}}</td>
            <td>{{formatDateTime(chart.chart.startDate)}}</td>
            <td>{{formatDateTime(chart.chart.endDate)}}</td>
            <td>{{chart.chart.sensorsList}}</td>
            <td style="white-space:nowrap;">
              <button @click="$router.push(`/app/${vesselId}/charts/${chart.key}/edit`)"><i class="material-icons">edit</i></button>
              <button @click="handleChartDelete(chart)"><i class="material-icons">delete</i></button>
              <button @click="$router.push(`/app/${vesselId}/charts/${chart.key}/view`)"><i class="material-icons">insert_chart_outlined</i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-main>
</el-container>
</template>

<script>
import {
  mapState
} from 'vuex';
import firebaseApp from '@/firebase/app';
import BgiAside from '@/components/alarms/aside.vue'

export default {
  components: {
    BgiAside
  },
  data() {
    return {
      dataLoading: true,
      meta: null,
      charts: [],
      fireData: {},
      fireDataIds: {},
      chartMap: {},
      viewTitle: 'My Charts',
      vesselId: this.$route.params.vesselId
    }
  },
  computed: {
    ...mapState(['collapse', 'user'])
  },
  mounted() {
    window.vueAside.commandCallback = (item) => this.headerCommand(item);
    this.updateDataBinding();
  },
  methods: {
    vesselIdWatcher(newVal, oldVal) {
      newVal;
      oldVal;
      this.updateDataBinding();
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.watchPaths['charts'] = `/vessels/${this.$route.params.vesselId}/charts`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;
      this.watchPaths['userData'] = `/users/${this.user.uid}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    formatDateTime(inDate) {
      let d = new Date(inDate);
      if (isNaN(d.getTime()))
        return '';
      return d.toLocaleDateString(undefined);
    },
    async handleChartDelete(chart) {
      if (confirm('Delete chart ' + chart.chart.name + '?')) {
        window.firebase.database()
          .ref(`/vessels/${this.$route.params.vesselId}/charts/${chart.key}`).remove();
      }
    },
    getData() {
      this.chartMap = this.fireData['charts'];
      this.meta = this.fireData['meta'];
      this.userData = this.fireData['userData'];

      if (!this.meta)
        return;

      if (!this.chartMap)
        return;
      if (!this.userData)
        return;

      this.charts = [];
      let order = 0;
      for (let key in this.chartMap) {
          this.charts.push({
            order: order++,
            chart: this.chartMap[key],
            key
          });
      }

    },
    headerCommand(item) {
      if (item.name === 'Create'){
        return this.createChart();
      }
    },
    async createChart() {
      const vesselRef = firebaseApp.database().ref(`vessels/${this.$route.params.vesselId}/charts`);
      let newChartRef = vesselRef.push();
      let newId = newChartRef.key;
      let created = new Date().toISOString();
      let updated = new Date().toISOString();
      let userDescription = this.user.email;
      if (this.userData.firstName && this.userData.lastName) {
        userDescription = this.userData.firstName + ' ' + this.userData.lastName;
      }
      let newChart = {
        name: 'Chart ' + Math.floor(100 + Math.random() * 900).toString(),
        created,
        uid: this.user.uid,
        userDescription,
        updated
      };
      await newChartRef.set(newChart);
      this.$router.push(`/app/${this.$route.params.vesselId}/charts/${newId}/edit`);
      return;
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.charts-mainview {
    width: 100%;
    min-height: calc(100vh - 120px);
    padding: 0;
    margin: 0;

}
.charts-viewer {
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 0;
    margin: 0;
}
.charts-aside {
    width: auto!important;
    padding: 0;
    margin: 0;
}

.selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
}

.chart_list_table {
  width: 100%;
  border-collapse: collapse;
}

.chart_list_table th {
  position: sticky;
  top: 0px;
  background: white;
  background: rgb(240,240,240);
  padding: 4px;
}

.chart_list_table td {
  border-right: solid 1px silver;
  padding: 2px 6px;
}

.chart_list_table td:nth-last-child(0) {
  border-right: none;
}

.chart_list_table button {
  margin: 2px;
}
</style>
