<template>
<div class="accept-invite-view">
  <h1>{{ status }}</h1>
</div>
</template>

<script>
import firebaseApp from '@/firebase/app'
import {
  mapState
} from 'vuex'
import axios from '@/plugins/axios'

export default {
  data() {
    return {
      vesselId: '',
      inviteId: '',
      inviteEmail: '',
      status: ''
    }
  },
  computed: {
    ...mapState(['user'])
  },
  async created() {
    let vesselId = this.$route.query.vesselId
    let invitedId = this.$route.query.inviteId
    //let inviteEmail = this.$route.query.email
    this.status = 'Processing Invite ...'

    let token = await firebaseApp.auth().currentUser.getIdToken(true);

    let res = null;
    let error = null;
    try {
      res = await axios.post('/accept', {
        vesselId,
        invitedId,
        token
      });
    } catch (err) {
      error = err;
    }

    if (error) {
      console.log('accept invite error', error);
      this.status = 'Accept Invite Error';
      return;
    }

    let data = res.data;
    if (data.success) {
      this.$router.push(`/app/${this.vesselId}`);
    } else {
      if (data.name === 'pageNotFound') {
        console.log('accept invite error', res.data);
        this.$router.push({ name: 'pageNotFound' });
      } else if (data.name === 'notify') {
        this.status = data.errorMessage;
        this.$notify.error({
          message: data.errorMessage
        });
      }

      console.log('accept error', data);
    }

    return;
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.accept-invite-view {
    width: 100%;
    min-height: 100vh;
    background-color: $clr-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        color: white;
    }
}
</style>
