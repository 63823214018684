export default {
  setUser(state, user) {
    state.user = user
  },
  setCollapse(state, status) {
    state.collapse = status
  },
  setSendingInvite(state, status) {
    state.sendingInvite = status
  },
  setUserName(state, name) {
    state.userName = name
  }
}