var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-view"},[(!_vm.isLoggedIn)?_c('el-card',{staticClass:"signup-form"},[_c('h1',[_vm._v("Sign Up")]),_c('el-form',{ref:"signupForm",attrs:{"label-position":"top","model":_vm.signupForm}},[_c('el-form-item',{attrs:{"prop":"firstName","label":"First Name","rules":[
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ]}},[_c('el-input',{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('signupForm')}},model:{value:(_vm.signupForm.firstName),callback:function ($$v) {_vm.$set(_vm.signupForm, "firstName", $$v)},expression:"signupForm.firstName"}})],1),_c('el-form-item',{attrs:{"prop":"lastName","label":"Last Name","rules":[
          { required: true, message: 'Please input last name', trigger: 'blur' }
        ]}},[_c('el-input',{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('signupForm')}},model:{value:(_vm.signupForm.lastName),callback:function ($$v) {_vm.$set(_vm.signupForm, "lastName", $$v)},expression:"signupForm.lastName"}})],1),_c('el-form-item',{attrs:{"prop":"email","label":"Email","rules":[
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ]}},[_c('el-input',{attrs:{"disabled":_vm.processingInvite},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('signupForm')}},model:{value:(_vm.signupForm.email),callback:function ($$v) {_vm.$set(_vm.signupForm, "email", $$v)},expression:"signupForm.email"}})],1),_c('el-form-item',{attrs:{"prop":"phone","label":"Phone"}},[_c('el-input',{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('signupForm')}},model:{value:(_vm.signupForm.phone),callback:function ($$v) {_vm.$set(_vm.signupForm, "phone", $$v)},expression:"signupForm.phone"}})],1),_c('el-form-item',{attrs:{"prop":"password","label":"Password","rules":[
          { required: true, message: 'Please input password', trigger: 'blur' },
          { min: 8, max: 32, message: 'Lenght should be 8 to 32', trigger: ['blur', 'change'] }
        ]}},[_c('el-input',{attrs:{"type":"password"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit('signupForm')}},model:{value:(_vm.signupForm.password),callback:function ($$v) {_vm.$set(_vm.signupForm, "password", $$v)},expression:"signupForm.password"}})],1),_c('el-divider'),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit('signupForm')}}},[_vm._v(" Sign Up ")])],1),_c('div',{style:({ textAlign: 'center' })},[_vm._v("Already have an account? "),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.gotoSignInPage}},[_vm._v("Sign In")])],1)],1)],1):_c('div',[_c('el-button',{on:{"click":_vm.goToAppPage}},[_vm._v("Go to Application")]),_c('el-button',{on:{"click":_vm.logout}},[_vm._v("Sign out")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }