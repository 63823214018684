<template>
  <div class="tiles-view">
    <template v-if="tiles.length > 0">
      <el-container>
        <el-header class="selected-name">
          {{ !!meta ? meta.name : '' }}
        </el-header>
        <el-main class="tiles-viewer">
          <div class="on-board" >
            <el-switch v-if="meta.isConnected" v-model="isOnBoard" @input="updateOnboard" v-bind:active-text="onBoardText" />
            <p v-else>Vessel Offline</p>
            <el-tooltip placement="left">
              <div slot='content'>
                <h4>Alert Details</h4>
                <p v-for="(line, index) in alarmSummary.dashboardAlarms" :key="index" :style="{ color : `${line.color}` }">
                {{line.name}} - {{line.title}}
                </p>
              </div>
            <img v-if="dashboardAlarmsCount > 0" src="/images/bgi-alert-red.svg" @click="handleAlertImgClick" style="cursor:pointer" alt="alert" height="30px" />
            </el-tooltip>
          </div>

          <el-row :gutter="10">
            <el-col v-for="(tile, index) in tiles" :key="index" :xl="4" :lg="6" :md="8" :sm="12" :xs="24">
              <bgi-tile :isConnected="meta.isConnected" :tile="tile.tile" />
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </template>
    <template v-else>
      <el-container>
        <el-header class="selected-name">
          {{ !!meta ? meta.name : 'XXX' }}
        </el-header>
        <el-main
          style="background:rgba(0, 0, 0, 0.3)"
          class="tiles-viewer"
        >
          <br><br><br><br>
          <div style="text-align:center;font-size:2em">
            No Alarms
          </div>
        </el-main>
      </el-container>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import firebaseApp from '@/firebase/app'
import BgiTile from './tile'

export default {
  components: {
    BgiTile
  },
  data() {
    return {
      isOnBoard: false,
      dataLoading: true,
      tiles: [],
      fireData: {},
      fireDataIds: {},
      meta: null,
      listener: null,
      userRef: null,
      dashboardAlarmsCount: 0
    }
  },
  mounted() {
    this.updateDataBinding();
  },
  computed: {
    ...mapState(['collapse']),
    onBoardText() {
      return this.isOnBoard ? 'On Board' : 'Off Board';
    }
  },
  beforeDestroy() {
    if (this.listener) {
      this.userRef.off('value', this.listener)
    }
  },
  
  methods: {
    vesselIdWatcher (newVal, oldVal) {
      newVal;
      oldVal;
      this.updateDataBinding();
    },
    updateDataBinding() {
      this.watchPaths = {};
      this.watchPaths['alarmSummary'] = `/vessels/${this.$route.params.vesselId}/alarmSummary`;
      this.watchPaths['tiles'] = `/vessels/${this.$route.params.vesselId}/tiles`;
      this.watchPaths['meta'] = `/vessels/${this.$route.params.vesselId}/meta`;

      window.watchFirebaseRoutes(this.watchPaths, (name, path, response) => {
        this.fireData[name] = response.val();
        if (!this.fireData[name])
          this.fireData[name] = {};
        this.fireDataIds[name] = response.key;
        this.getData();
      });
    },
    async updateOnboard(value) {
      let vesselCmdRef = firebaseApp.database().ref(`/vessels/${this.$route.params.vesselId}/commands`);
      let state = value ? 'on': 'off';
      await vesselCmdRef.update({
        onboard: state
      });
    },
    handleAlertImgClick() {
      window.showAlertDialogHomeScreen();
    },
    getData() {
      this.alarmSummary = this.fireData['alarmSummary'];
      this.meta = this.fireData['meta'];
      this.tileMap = this.fireData['tiles'];

      if (!this.meta)
        return;

      this.isOnBoard = this.meta.onboard;

      if (!this.tileMap)
        return;
      this.tiles = [];
      let maxLen = Object.keys(this.tileMap).length
      for(const key in this.tileMap) {
        const tile = this.tileMap[key]
        if (tile.isDisplayed) {
          const order = tile.order !== undefined ? tile.order : ++maxLen
          tile.cachedId = key;
          this.tiles.push({ order, tile })
        }
      }
      this.tiles.sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
        return 0
      })

      if (this.alarmSummary && this.alarmSummary.dashboardAlarmsCount) {
        this.dashboardAlarmsCount = Number(this.alarmSummary.dashboardAlarmsCount)
      } else {
        this.dashboardAlarmsCount = 0
      }
    }
  },
  watch: {
    '$route.params.vesselId': {
      handler: 'vesselIdWatcher',
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.tiles-view {
  width: 100%;
  min-height: calc(100vh - 120px);
  .selected-name {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $clr-bg-primary;
    color: $clr-text-primary;
    text-transform: uppercase;
    box-shadow: 0 0 5px rgba(#000, 0.5);
    font-size: 24px;
    z-index: 99;
  }
  .tiles-viewer {
    margin-left: 95px;
    padding: 20px;
    height: calc(100vh - 180px);
    overflow-y: auto;
    .on-board {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
    }
  }
}
</style>
