<template>
<div class="bgi-aside" :class="{'collapsed': isCollapse}">
  <div class="collapse-trigger">
    <span style="position: absolute; left: 0px; width: 100%; text-align: center; color: white;" v-if="!isCollapse">Remote Vessels</span>
    <el-button :icon="isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" type="primary" @click="isCollapse = !isCollapse" />
  </div>
  <el-menu v-if="vesselsLoaded" class="vessel-list" :collapse="isCollapse" :collapse-transition="false">
    <el-menu-item v-for="(item, index) in headerCommands" class="menu_command" :key="index" @click="headerCommandClicked(item)">
      <span v-if="!isCollapse">{{item.name}}</span>
        <i class="material-icons header_command_icon">{{item.icon}}</i>
      <div style="clear:both"></div>
    </el-menu-item>
    <el-menu-item v-for="(item, index) in this.sortedVessels(watchedVessels)" :key="index" @click="vesselItemClicked(item[0])">
      <vessel :vesselId="item[0]" :meta="item[1]" :isCollapse="isCollapse" />
    </el-menu-item>
  </el-menu>
</div>
</template>

<script>
import {
  mapState
} from 'vuex'
import firebaseApp from '../../firebase/app'
import Vessel from './vessel'

export default {
  components: {
    Vessel
  },
  data() {
    return {
      isCollapse: true,
      vesselsLoaded: false,
      headerCommands: [],
      vessels: [],
      watchedVessels: {},
      commandCallback: null
    }
  },
  computed: {
    ...mapState(['user']),
    // sortedVessels(){
    //   return Object.entries(this.watchedVessels).sort((metaA, metaB) =>{
    //     // console.log(metaA, metaB)
    //     var textA = metaA[1].name.toUpperCase();
    //     var textB = metaB[1].name.toUpperCase();
    //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    //   })
    // }
  },
  async mounted() {
    window.vueAside = this;
    this.routeName = this.$route.name;
    if (this.routeName === 'charts') {
      this.headerCommands = [{
          name: 'My Charts',
          icon: 'insert_chart_outlined'
        },
        {
          name: 'Create',
          icon: 'addchart'
        }, {
          name: 'Upload',
          icon: 'upload'
        }
      ];
    }

    if (['chartedit', 'chartview'].indexOf(this.routeName) !== -1) {
      this.headerCommands = [{
          name: 'My Charts',
          icon: 'insert_chart_outlined'
        }, {
          name: 'Export',
          icon: 'cloud_download'
        }, {
          name: 'Share',
          icon: 'share'
        }
      ];
    }

    if (this.routeName === 'log') {
      this.headerCommands = [{
          name: 'Logs',
          icon: 'list_alt'
        },
        {
          name: 'Create',
          icon: 'add_task'
        }
      ];
    }

    window.showAlertDialogHomeScreen = () => this.handleHeaderAlarmClick();

    const userId = this.user.uid
    const userRef = firebaseApp.database().ref(`/users/${userId}`)
    await userRef.once('value').then(async (res) => {
      const userInfo = res.val();
      this.vessels = userInfo.vessels ? userInfo.vessels : null
      for (const vesselId of Object.keys(this.vessels)){
        
        await firebaseApp.database().ref(`/vessels/${vesselId}/meta`).once("value", (snapshot) => {
          // this.vesselsLoaded = false;
          this.watchedVessels[vesselId] = snapshot.val();
          // console.log(this.watchedVessels)
          // console.log(this.sortedVessels)
          // console.log(Object.entries(this.watchedVessels))
          // this.vesselsLoaded = true;
          this.$forceUpdate();
        });
        firebaseApp.database().ref(`/vessels/${vesselId}/meta`).on("value", (snapshot) => {
          // this.vesselsLoaded = false;
          this.watchedVessels[vesselId] = snapshot.val();
          // console.log(this.watchedVessels)
          // console.log(this.sortedVessels)
          // console.log(Object.entries(this.watchedVessels))
          // this.vesselsLoaded = true;
          this.$forceUpdate();
        });
      }
      this.vesselsLoaded = true;
    })
    
  },
  created() {
    
  },
  watch: {
    isCollapse(val) {
      window.asideCollapsed = val;
    },
    watchedVessels: {
      deep: true,
    }
  },
  methods: {
    sortedVessels(vesselsObject){
      return Object.entries(vesselsObject).sort((metaA, metaB) =>{
        // console.log(metaA, metaB)
        var textA = metaA[1].name.toUpperCase();
        var textB = metaB[1].name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    vesselItemClicked(vesselId) {
      window.BGNavigateRoute(this.$route, this.$router, vesselId);
    },
    handleHeaderAlarmClick() {
      window.asideVessels[this.$route.params.vesselId].handleAlarmClick();
    },
    headerCommandClicked(item) {
      if (this.commandCallback)
        this.commandCallback(item);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.collapsed {
  box-shadow: none !important;
  border-right: $clr-primary solid 1px;
}
.bgi-aside {
  z-index: 1000;
    height: calc(100vh - 120px);
    position: fixed;
    box-shadow: 10px 10px 5px #aaaaaa;
    background-color: rgba(249, 248, 253, 0.8);
    .collapse-trigger {
        display: flex;
        flex-direction: row-reverse;
        padding: 5px 0;
        background-color: $clr-primary;
        .el-button {
            border-radius: 5px 0 0 5px;
            padding: 0;
            width: 40px;
            height: 40px;
        }
    }
    .vessel-list {
        display: grid;
        gap: 10px;
        background-color: rgba(249, 248, 253, 0.8);
        height: calc(100% - 50px);
        padding-top: 10px;
        border: none;
        overflow-y: auto;
        align-content: start;
        &:not(.el-menu--collapse) {
            /* width: 320px; */
        }
        .el-menu-item {
            max-height: 90px;
            margin-bottom: 35px;
            padding: 0 !important;
            background-color: transparent;
            border: none;
            line-height: 1;
            &:hover {
                background-color: transparent;
                border: none;
            }
        }
        .el-menu-item.menu_command {
            height: auto;
            color: black;
            font-weight: bold;
            font-size: 1.15em;
            margin: 8px;
        }
    }
    .el-menu--collapse {
        width: 95px;
    }
    .header_command_icon {
      float:right;
      color:black;
      font-size: 1.25em;
      padding: .05em;
    }

    .el-menu-item.menu_command:hover {
      background-color: rgba(white, 0.1);
    }
}
</style>
