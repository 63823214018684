/* eslint-disable no-unused-vars */
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options', 'checkedAxes'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    console.log("Mounting with options: ", this.options)
    this.renderChart(this.chartData, this.options)
    // console.log(this.checkedAxes)
    if(this.checkedAxes){
      // console.log(this.checkedAxes)

      this.checkedAxes.forEach((visible, index) => {
        console.log(visible, index)
        if(!visible){
          // console.log("Hiding axis: ", index)
          this.hideDataset(index)
        }
      })
    }
  },
  methods: {
    hideDataset (index) {
      // console.log(this.$data._chart)
      // this.$data._chart.hide(index)
      // this.$data._chart.setDatasetVisibility(index, false);
      // this.$data._chart.clear();
      const meta = this.$data._chart.getDatasetMeta(index);
      meta.hidden = meta.hidden = !meta.hidden;
      this.$data._chart.update();
    },
    getDatasetVisibility(index){
      return this.$data._chart.getDatasetMeta(index).hidden;
    }
  
  }
  

}