import Vue from 'vue'
import Router from 'vue-router'
import firebaseApp from './firebase/app'
import store from './store'

import SignIn from './views/signin.vue'
import SignUp from './views/signup.vue'
import ResetPassword from './views/reset-password'
import Welcome from './views/welcome.vue'
import Invite from './views/invite.vue'
import AcceptInvite from './views/acceptInvite.vue'
import Home from './views/home.vue'
import Application from './views/application.vue'
// import Dashboard from './views/dashboard.vue'
import Alarms from './views/alarms.vue'
import Sensors from './views/sensors.vue'
import Users from './views/users.vue'
import Charts from './views/charts.vue'
import ChartEdit from './views/chartedit.vue'
import ChartView from './views/chartview.vue'
import UserProfileView from './views/profile.vue'
import VesselInfo from './views/vessel-info.vue'

import Geofence from './views/geofence.vue'
import TileDetail from './views/tiledetail.vue'
import SensorDetail from './views/sensordetail.vue'
import PageNotFound from './views/404.vue'
import { auth } from 'firebase'

Vue.use(Router)

const isAuthenticated = (to, from, next) => {
  if(auth().currentUser != null){
    next();
    return;
  }
  else {
    store.commit('setUser', null)
    next('/signin');
  }
  // firebaseApp.auth().onAuthStateChanged(function(user) {
  //   //console.log('router middleware >>>>', user)
  //   if (user) {
  //     //console.log('user loggedin >>>>')
  //     store.commit('setUser', user)
  //     firebaseApp.database().ref(`/users/${user.uid}/meta`).once('value').then(meta => {
  //       const { firstName, lastName } = meta.val()
  //       store.commit('setUserName', `${firstName} ${lastName}`)
        
  //       return
  //     })
  //   } else {
  //     console.log('no user loggedin >>>>', to, from)
  //     store.commit('setUser', null)
  //     next('/signin')
  //   }
  // });
}

const signOut = (to, from, next) => {
  firebaseApp.auth().signOut().then(() => {
    next()
  });
}

export default new Router({
  mode: 'history',
  duplicateNavigationPolicy: 'ignore',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // beforeEnter: signOut,
      component: Home
    },
    {
      path: '/signin',
      name: 'signin',
      component: SignIn
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword
    },
    {
      path: '/invite/:inviteId',
      name: 'invite',
      beforeEnter: signOut,
      component: Invite
    },
    {
      path: '/acceptInvite',
      name: 'acceptInvite',
      component: AcceptInvite,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/app',
      name: 'welcome',
      beforeEnter: isAuthenticated,
      component: Welcome
    },
    {
      path: '/app/userprofile',
      name: 'userprofilebase',
      beforeEnter: isAuthenticated,
      component: Application,
      children: [
        {
          path: '',
          name: 'userprofile',
          component: UserProfileView
        }
      ]
    },
    {
      path: '/app/:vesselId',
      name: 'app',
      component: Application,
      beforeEnter: isAuthenticated,
      children: [
        {
          path: '',
          name: 'alarms',
          component: Alarms
        },
        {
          path: 'sensors',
          name: 'sensors',
          component: Sensors,
        },
        {
          path: 'sensors/:sensorId/view',
          name: 'sensor',
          component: SensorDetail,
        },
        {
          path: 'users',
          name: 'users',
          component: Users,
        },
        {
          path: 'info',
          name: 'info',
          component: VesselInfo,
        },
        {
          path: 'charts',
          name: 'charts',
          component: Charts,
        },
        {
          path: 'charts/:chartId/edit',
          name: 'chartedit',
          component: ChartEdit,
        },
        {
          path: 'charts/:chartId/view',
          name: 'chartview',
          component: ChartView,
        },
        {
          path: 'tiles/:tileId/view',
          name: 'tile',
          component: TileDetail,
        },
        {
          path: 'geofence',
          name: 'geofence',
          component: Geofence,
        }
      ]
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: PageNotFound
    }
  ]
})
